import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { memo, useRef, useState } from 'react';
import configuration from '../../../../../../configurationFile';

interface DatosJson {
    folioDocumento: File;
    folioDocumentoNombre: string;
    documentos: Array<any>;
    view: boolean;
}

interface DatosProps {
    data: DatosJson;
    docsIDs: Array<any>;
    setFolioDocumento: (file: File) => void;
    setFolioDocumentoNombre: (value: string) => void;
    uploadDocumento: () => void;
    deleteDocumento: (documento: { ptrm_id: string; ptrmd_id: string; }) => void;
}

interface ArchivoSeleccionado {
    ptrm_id: string;
    ptrmd_desc: string;
    ptrmd_id: string;
    ptrmd_status: number;
    ptrmd_update: string
    ptrmd_url: string
}

const Documentos = (props: DatosProps) => {
    const toast = useRef(null);
    const refFileDocument = useRef(null)
    const refModalBorrar = useRef(null);

    const [modalBorrar, setModalBorrar] = useState(false);
    const [archivoSeleccionado, setArchivoSeleccionado] = useState<ArchivoSeleccionado>()

    function esMayorQue5MB(bytes: number) {
        const megabytes = bytes / (1024 * 1024);
        return megabytes > 5;
    }

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            if (esMayorQue5MB(e.target.files[0].size)) {
                fileToBig()
            } else {
                props.setFolioDocumento(e.target.files[0]);
            }
        }
    }

    const handleFileClick = () => {
        refFileDocument.current.click()
    }

    const fileToBig = () => {
        toast.current.show({ severity: 'error', summary: 'Archivo muy grande', detail: 'El archivo excede los 5MB', life: 3000 });
    }

    const eliminarTemplate = (rowData: any) => (
        <div ref={refModalBorrar}>
            <a href={`${configuration.url}${rowData.ptrmd_url}`} target="_blank">
                <Button
                    tooltip="Ver archivo subido"
                    className="p-button-text"
                    icon="pi pi-eye"
                />
            </a>
            <Button
                tooltip="Eliminar archivo subido"
                className="p-button-text p-button-danger"
                icon="pi pi-trash"
                onClick={() => {
                    setModalBorrar(true)
                    setArchivoSeleccionado(rowData)
                }}
            />
        </div>
    );

    const iconoDocumentoTemplate = () => (
        <div className='text-center'>
            <i className='pi pi-file documentos-file-icon'></i>
        </div>
    );

    const accept = () => {
        if (archivoSeleccionado) {
            props.deleteDocumento(archivoSeleccionado)
        }
    };

    return (
        <section className=''>
            <Toast ref={toast} />

            <Divider align="left">
                <div className="inline-flex align-items-center">
                    <b>Documentación</b>
                </div>
            </Divider>
            <div className='grid'>
                {!props.data.view &&
                    <div className='col-12 md:col-4'>
                        <div className='pt-2 text-center'>
                            {props.data.folioDocumento ?
                                <div className='pt-2 document-ok'>
                                    <p><i className='pi pi-file' style={{ 'fontSize': '3em' }} /></p>
                                    <p className=''>{props.data.folioDocumento.name}</p>
                                </div>
                                :
                                <div>
                                    <input
                                        ref={refFileDocument}
                                        type="file"
                                        hidden
                                        onChange={readUploadFile}
                                    />
                                    <p className='document' onClick={handleFileClick}>
                                        <i className='pi pi-cloud-upload' style={{ 'fontSize': '3em' }} />
                                        Selecciona para subir documento
                                    </p>
                                </div>
                            }
                        </div>
                        <div className='col-12 md:col-12 py-5'>
                            <span className="p-float-label">
                                <InputText
                                    className="p-inputtext-lg"
                                    value={props.data.folioDocumentoNombre}
                                    onChange={(e) => { props.setFolioDocumentoNombre(e.target.value) }}
                                />
                                <label htmlFor="username">Nombre del documento</label>
                            </span>
                        </div>
                        <Button label="Agregar" className='p-button-outlined' onClick={props.uploadDocumento} />
                    </div>
                }
                <div className={props.data.view ? 'col-12 md:col-12' : 'col-12 md:col-8'}>
                    <ConfirmPopup
                        target={refModalBorrar.current}
                        visible={modalBorrar}
                        onHide={() => setModalBorrar(false)}
                        message="Borrar Documento ?"
                        icon="pi pi-exclamation-triangle"
                        accept={accept}
                        reject={() => setModalBorrar(false)}
                        acceptLabel='Eliminar'
                        rejectLabel='Cancelar'
                    />
                    <DataTable
                        value={props.data.documentos}
                        dataKey={props.docsIDs[0]}
                        className="datatable-responsive"
                        scrollable
                        scrollHeight="400px"
                        emptyMessage="No hay documentos guardados"
                    >
                        <Column body={iconoDocumentoTemplate} ></Column>
                        <Column field={props.docsIDs[1]} header="Nombre del documento"></Column>
                        <Column body={eliminarTemplate} ></Column>
                    </DataTable>
                </div>
            </div>
        </section>
    );

}

export default memo(Documentos);
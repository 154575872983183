import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import React, { useEffect, useState } from 'react';
import ApiServices from '../../service/apiServices';
import { InputNumber } from 'primereact/inputnumber';
import EncryptService from '../../service/encrypt.service';
import authGuardService from '../../service/authGuardService';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Tag } from 'primereact/tag';


const I1Acto = (props: any) => {
    const user = authGuardService.getUser()

    const prop_id = props.preTramiteDetail ? props.preTramiteDetail.tramite.prop_id :
        props.responseData ? props.responseData.prop_id :
            props.previousFolio ? props.previousFolio[0].prop_id :
                props.generalData ? props.generalData.prop_id :
                    props.propiedadDetail ? props.propiedadDetail.prop_id :
                        0

    const columns = [
        props?.information?.act_clave == 'I1' && { field: 'cmpdt_concepto', header: 'Concepto' },
        { field: 'ptit_nom', header: 'Nombre' },
        { field: 'ptit_pat', header: 'Apellido Paterno / Razón Social' },
        { field: 'ptit_mat', header: 'Apellido Materno' },
        { field: 'ptit_rfc', header: 'RFC' },
        { field: 'cmpdt_curp', header: 'CURP' },
        { field: 'ptit_nuda', header: 'Nuda Actual %' },
        { field: 'nuda_enaje', header: 'Nuda Enaje %' },
        { field: 'nuda_adquiere', header: 'Nuda Adquiere %' },
        { field: 'ptit_usufructo', header: 'Usofructo Actual %' },
        { field: 'usufructo_enaje', header: 'Usofructo Enaje %' },
        { field: 'usufructo_adquiere', header: 'Usofructo Adquiere %' },
        { field: 'cmptd_representacion', header: 'Representación' }
    ];

    const columns3 = [
        props?.information?.act_clave == 'I1' && { field: 'cmpdt_concepto', header: 'Concepto' },
        { field: 'ptit_nom', header: 'Nombre' },
        { field: 'ptit_pat', header: 'Apellido Paterno / Razón Social' },
        { field: 'ptit_mat', header: 'Apellido Materno' },
        { field: 'ptit_rfc', header: 'RFC' },
        { field: 'cmpdt_curp', header: 'CURP' },
        { field: 'lote', header: 'Lote' },
        { field: 'manzana', header: 'Manzana' },
        { field: 'zona', header: 'Zona' },
        { field: 'ptit_nuda', header: 'Nuda Actual %' },
        { field: 'nuda_enaje', header: 'Nuda Enaje %' },
        { field: 'nuda_adquiere', header: 'Nuda Adquiere %' },
        { field: 'ptit_usufructo', header: 'Usofructo Actual %' },
        { field: 'usufructo_enaje', header: 'Usofructo Enaje %' },
        { field: 'usufructo_adquiere', header: 'Usofructo Adquiere %' },
        { field: 'cmptd_representacion', header: 'Representación' }
    ];

    const columns4 = [
        props?.information?.act_clave == 'I1' && { field: 'cmpdt_concepto', header: 'Concepto' },
        { field: 'ptit_nom', header: 'Nombre' },
        { field: 'ptit_pat', header: 'Apellido Paterno / Razón Social' },
        { field: 'ptit_mat', header: 'Apellido Materno' },
        { field: 'ptit_rfc', header: 'RFC' },
        { field: 'cmpdt_curp', header: 'CURP' },
        { field: 'ptit_nuda', header: 'Nuda Actual %' },
        { field: 'ptit_usufructo', header: 'Usofructo Actual %' },
        { field: 'cmptd_representacion', header: 'Cargo' }
    ];

    const columns2 = [
        props?.information?.act_clave == 'I1' && { field: 'cmpdt_concepto', header: 'Concepto' },
        { field: 'ptit_nom', header: 'Nombre' },
        { field: 'ptit_pat', header: 'Apellido Paterno / Razón Social' },
        { field: 'ptit_mat', header: 'Apellido Materno' },
        { field: 'ptit_rfc', header: 'RFC' },
        { field: 'cmpdt_curp', header: 'CURP' },
        { field: 'ptit_nuda', header: 'Nuda Actual %' },
        { field: 'ptit_usufructo', header: 'Usofructo Actual %' },
        { field: 'cmptd_representacion', header: 'Representación' }
    ];

    const [titulares, setTitulares] = useState([])
    const [titularesSeleccionados, setTitularesSeleccionados] = useState([])

    const [data, setData]: any = useState({
        concepto: { value: '', valid: false },
        paterno: { value: '', valid: false },
        materno: { value: '', valid: false },
        nombre: { value: '', valid: false },
        rfc: { value: '', valid: false },
        curp: { value: '', valid: false },
        nuda: { value: 0, valid: false },
        usufructo: { value: 0, valid: false },
        representacion: { value: '', valid: false },
    })

    const setValues = (jsonValue: any, value: any) => {
        const cloneJson = JSON.parse(JSON.stringify(data))
        cloneJson[jsonValue].value = value
        cloneJson[jsonValue].valid = value?.toString().length > 0
        setData(cloneJson)
    }

    function searchByExactName(text) {
        const types = [
            { name: 'Aplicacion por gananciales', code: '1' },
            { name: 'Herederos', code: '2' },
            { name: 'Legatarios', code: '3' }
        ]
        const lowerCaseText = text.toLowerCase();

        return types.filter(item => item.name.toLowerCase() === lowerCaseText);
    }

    const findSavedFolio = (folio) => {
        if (folio && folio != 0 && props && props.tramite && props.tramite.hijos && props.tramite.hijos.length > 0) {
            return [...props.tramite.hijos, props.tramite.tramite].find(element => element.prop_folio == folio) || 0;
        } else {
            return 0;
        }
    };


    const formattTitulares = (titulares) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))

        if (cloneTitulares && cloneTitulares.length > 0) {
            const newTitulares = cloneTitulares.map((singleTitular) => {
                const type = singleTitular && singleTitular.cmpdt_concepto ? searchByExactName(singleTitular.cmpdt_concepto) : ''
                return {
                    cmptdt_id: singleTitular.cmptdt_id || 0,
                    cmpdt_concepto: type && type[0] || '',
                    ptit_nom: singleTitular.cmpdt_nombre,
                    ptit_pat: singleTitular.cmpdt_paterno_rs,
                    ptit_mat: singleTitular.cmpdt_materno,
                    ptit_rfc: singleTitular.cmpdt_rfc,
                    ptit_nuda: singleTitular.cmpdt_nuda,
                    nuda_adquiere: singleTitular.cmpdt_nuda_adquiere,
                    cmpdt_curp: singleTitular.cmpdt_curp,
                    nuda_enaje: singleTitular.cmptd_nuda_enaje,
                    ptit_usufructo: singleTitular.cmpdt_usufructo,
                    usufructo_enaje: singleTitular.cmptd_usufructo_enaje,
                    usufructo_adquiere: singleTitular.cmptd_usufructo_adquiere,
                    cmptd_representacion: singleTitular.cmptd_representacion,
                    ptit_status: singleTitular.cmpdt_movimiento == 3 ? 0 : 1,
                    folio: findSavedFolio(singleTitular.cmptd_valor)
                }
            })
            setTitulares(newTitulares)
            props.handleValueChange(newTitulares)
        }
    }

    const getTitularesSingle = () => {
        let datos = {
            cmp_id: props.cmpId,
            frmu_id: props.responseData ? props.responseData.ptrm_id : (props.information?.frmu_id || props.preTramiteFolio?.ptrm_id),
        }
        ApiServices.apiPost('getTitularesActualiza', datos).then(async res => {
            let dataEx = res.data.addenda
            if (dataEx && dataEx.length > 0) {
                formattTitulares(res.data.addenda)
            }
        }).catch(error => {

        })
    }


    const getTitulares = () => {
        let datos = {
            cmp_id: props.cmpId,
            frmu_id: props.responseData ? props.responseData.ptrm_id : (props.information?.frmu_id || props.preTramiteFolio?.ptrm_id),
        }
        ApiServices.apiPost('getTitularesActualiza', datos).then(async res => {
            let dataEx = res.data.addenda
            if (dataEx && dataEx.length > 0) {
                formattTitulares(res.data.addenda)
            } else {
                getTitularesFolio()
            }
        }).catch(error => {

        })
    }

    const getTitularesFolio = () => {
        let datos = {
            prop_id: props.preTramiteDetail ? props.preTramiteDetail.tramite.prop_id :
                props.responseData ? props.responseData.prop_id :
                    props.previousFolio ? props.previousFolio[0].prop_id :
                        props.generalData ? props.generalData.prop_id :
                            props.propiedadDetail ? props.propiedadDetail.prop_id :
                                0
        }
        ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
            setTitulares(res.data.addenda)
            props.handleValueChange(res.data.addenda, props.indexes[0], props.indexes[1])
        }).catch(error => {
            console.log(error)
        })
    }

    const addNewTitular = () => {
        if (titulares.length > 0) {

            const lastObject = titulares[titulares.length - 1];
            const emptyObject: any = Object.keys(lastObject).reduce((acc, key) => {
                acc[key] = '';
                return acc;
            }, {});

            emptyObject.prop_id = prop_id
            emptyObject.ptit_id = '0';
            emptyObject.ptit_nom = data.nombre.value;
            emptyObject.ptit_pat = data.paterno.value;
            emptyObject.ptit_mat = data.materno.value;
            emptyObject.ptit_rfc = data.rfc.value;
            emptyObject.ptit_fecnac = '1980-03-23'
            emptyObject.ptit_nuda = ''
            emptyObject.ptit_usufructo = ''
            emptyObject.usu_id = user.usu_id.value
            emptyObject.ptit_status = '1'

            setData({
                cmpdt_concepto: { value: '', valid: false },
                paterno: { value: '', valid: false },
                materno: { value: '', valid: false },
                nombre: { value: '', valid: false },
                rfc: { value: '', valid: false },
                curp: { value: '', valid: false },
                nuda: { value: 0, valid: false },
                usufructo: { value: 0, valid: false },
                representacion: { value: '', valid: false },
            })

            setTitulares([...titulares, emptyObject]);
            props.handleValueChange([...titulares, emptyObject], props.indexes[0], props.indexes[1])
        } else {
            const newTitular = {
                prop_id: prop_id,
                ptit_id: 0,
                ptit_fecnac: '1980-03-23',
                cmpdt_concepto: "",
                cmptdt_id: "",
                ptit_nom: "",
                ptit_pat: "",
                ptit_mat: "",
                ptit_rfc: "",
                ptit_nuda: '',
                cmpdt_curp: "",
                nuda_enaje: '',
                ptit_usufructo: '',
                usufructo_enaje: '',
                cmptd_representacion: "",
                ptit_status: 1,
                usu_id: user.usu_id.value
            }
            setTitulares([...titulares, newTitular]);
            props.handleValueChange([...titulares, newTitular], props.indexes[0], props.indexes[1])
        }
    }

    const isDeletedTemplate = (rowData, index) => {
        if (rowData.ptit_status == 0)
            return <Tag severity="danger" value="Se Eliminará"></Tag>
        else return ''
    };

    const textEditor = (options: ColumnEditorOptions) => {
        if (
            options.field == 'ptit_nuda' ||
            options.field == "ptit_usufructo" ||
            options.field == 'nuda_enaje' ||
            options.field == 'usufructo_enaje' ||
            options.field == 'nuda_adquiere' ||
            options.field == 'usufructo_adquiere'
        ) {
            return (
                <InputNumber value={options.value}
                    onValueChange={(e) => options.editorCallback(e.value)}
                    maxFractionDigits={4}
                    minFractionDigits={4}
                    min={0}
                    max={100}
                />
            )
        } else
            return <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
            />;
    };

    const handleChangeTitulares = (index: string | number, field: any, value: any) => {
        const newTitulares = [...titulares];
        if (field == 'nuda_enaje') {
            newTitulares[index] = {
                ...newTitulares[index],
                'ptit_nuda': (newTitulares[index].ptit_nuda - value) < 0 ? 0 : newTitulares[index].ptit_nuda - value
            };
        }
        if (field == 'usufructo_enaje') {
            newTitulares[index] = {
                ...newTitulares[index],
                'ptit_usufructo': (newTitulares[index].ptit_nuda - value) < 0 ? 0 : newTitulares[index].ptit_nuda - value
            };
        }
        newTitulares[index] = {
            ...newTitulares[index],
            [field]: value
        };
        props.handleDeleteTitularString(`${newTitulares[index].ptit_nom || ''} ${newTitulares[index].ptit_pat || ''} ${newTitulares[index].ptit_mat || ''}`)
        setTitulares(newTitulares);
        props.handleValueChange(newTitulares, props.indexes[0], props.indexes[1])
    };

    const onCellEditComplete = (e: { rowIndex: any; field: any; newValue: any; }) => {
        handleChangeTitulares(e.rowIndex, e.field, e.newValue)
    };

    const deleteTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.ptit_id == 0) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].nuda_enaje = cloneTitulares[index].ptit_nuda
            cloneTitulares[index].usufructo_enaje = cloneTitulares[index].ptit_usufructo
            cloneTitulares[index].ptit_nuda = 0
            cloneTitulares[index].ptit_usufructo = 0
            cloneTitulares[index].ptit_status = 0
            props.handleDeleteTitularString(`${cloneTitulares[index].ptit_nom || ''} ${cloneTitulares[index].ptit_pat || ''} ${cloneTitulares[index].ptit_mat || ''}`)

        }
        setTitulares(cloneTitulares)
        props.handleValueChange(cloneTitulares, props.indexes[0], props.indexes[1])
    }

    const recoverTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.ptit_id == 0) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].ptit_nuda = cloneTitulares[index].nuda_enaje
            cloneTitulares[index].ptit_usufructo = cloneTitulares[index].usufructo_enaje
            cloneTitulares[index].nuda_enaje = 0
            cloneTitulares[index].usufructo_enaje = 0
            cloneTitulares[index].ptit_status = 1
        }
        setTitulares(cloneTitulares)
        props.handleValueChange(cloneTitulares, props.indexes[0], props.indexes[1])
    }

    const actionBodyTemplate = (rowData, index) => {
        if (rowData.ptit_status == 0)
            return <Button type="button" icon="pi pi-history" rounded onClick={() => { recoverTitular(rowData, index.rowIndex) }}></Button>;
        else
            return <Button type="button" severity="danger" icon="pi pi-trash" rounded onClick={() => { deleteTitular(rowData, index.rowIndex) }}></Button>;
    };

    const headerTemplate = (options) => {
        const className = `${options.className} justify-content-space-between`;

        return (
            <div className={className}>
                <div className="flex align-items-center gap-2">
                    <span className="font-bold">Adjudica en favor de:</span>
                </div>
                <div>
                    <Button onClick={addNewTitular}>
                        Agregar
                    </Button>
                </div>
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold"></span>
            <Button icon="pi pi-plus" rounded raised onClick={addNewTitular} />
        </div>
    );

    useEffect(() => {
        if (props && props.frm && props.frm.cmp_funcion && props.frm.cmp_funcion == 'titulares')
            getTitulares()
        else {
            getTitularesSingle()
        }
    }, [])

    return (
        <section className='dynamicStyle'>
            <Panel header={props && props.frm && props.frm.cmp_titulo ? props.frm.cmp_titulo : props && props.frm && props.frm.cmp_valores ? props.frm.cmp_valores : ''}>
                <div className='p-datatable p-component p-datatable-responsive-scroll datatable-responsive'>
                    <DataTable
                        value={titulares}
                        dataKey="ptit_id"
                        paginator
                        editMode="cell"
                        rows={100}
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        emptyMessage="No hay titulares."
                        showGridlines
                        header={header}
                    >

                        {props && props.tramite && props.tramite.hijos && props.tramite.hijos.length > 0 &&
                            <Column
                            style={{ width: '10%' }}
                                key={'folio'}
                                field={'folio'}
                                header={'Folio'}
                                body={(rowData, { rowIndex }) => {
                                    if (rowData?.ptit_status != 0)
                                        return <Dropdown value={titulares[rowIndex].folio} onChange={(e) => handleChangeTitulares(rowIndex, 'folio', e.value)}
                                            options={[...props.tramite.hijos, props.tramite.tramite]} optionLabel="prop_folio"
                                            placeholder="Seleccionar Tramite" className="w-full md:w-14rem" />
                                    else return <p></p>
                                }}
                                onCellEditComplete={onCellEditComplete}
                            />
                        }


                        <Column
                            headerStyle={{ textAlign: 'center' }}
                            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                            body={isDeletedTemplate} />
                        {props && props.frm && props.frm.cmp_funcion && props.frm.cmp_funcion == 'titulares' ?
                            columns.map(({ field, header }, index) => {
                                if (field == 'cmpdt_concepto')
                                    return <Column
                                        key={field}
                                        field={field}
                                        header={header}
                                        body={(rowData, { rowIndex }) => (
                                            <Dropdown value={titulares[rowIndex].cmpdt_concepto}
                                                onChange={(e) => handleChangeTitulares(rowIndex, 'cmpdt_concepto', e.value)}
                                                options={[
                                                    { name: 'Aplicacion por gananciales', code: '1' },
                                                    { name: 'Herederos', code: '2' },
                                                    { name: 'Legatarios', code: '3' }
                                                ]} optionLabel="name"
                                                placeholder="Seleccionar" className="w-full md:w-14rem" />
                                        )
                                        }
                                    />
                                else
                                    return <Column
                                        key={field}
                                        field={field}
                                        header={header}
                                        editor={(options) => textEditor(options)}
                                        onCellEditComplete={onCellEditComplete}
                                    />;
                            })
                            : props && props.frm && props.frm.cmp_tipo && props.frm.cmp_tipo == 'DT2' ?
                                columns3.map(({ field, header }) => {
                                    if (field == 'cmpdt_concepto')
                                        return <Column
                                            key={field}
                                            field={field}
                                            header={header}
                                            body={(rowData, { rowIndex }) => (
                                                <Dropdown value={titulares[rowIndex].cmpdt_concepto}
                                                    onChange={(e) => handleChangeTitulares(rowIndex, 'cmpdt_concepto', e.value)}
                                                    options={[
                                                        { name: 'Aplicacion por gananciales', code: '1' },
                                                        { name: 'Herederos', code: '2' },
                                                        { name: 'Legatarios', code: '3' }
                                                    ]} optionLabel="name"
                                                    placeholder="Seleccionar" className="w-full md:w-14rem" />
                                            )
                                            }
                                        />
                                    else
                                        return <Column
                                            key={field}
                                            field={field}
                                            header={header}
                                            editor={(options) => textEditor(options)}
                                            onCellEditComplete={onCellEditComplete}
                                        />;
                                })
                                : props && props.frm && props.frm.cmp_tipo && props.frm.cmp_tipo == 'DT3' ?
                                    columns4.map(({ field, header }) => {
                                        if (field == 'cmpdt_concepto')
                                            return <Column
                                                key={field}
                                                field={field}
                                                header={header}
                                                body={(rowData, { rowIndex }) => (
                                                    <Dropdown value={titulares[rowIndex].cmpdt_concepto}
                                                        onChange={(e) => handleChangeTitulares(rowIndex, 'cmpdt_concepto', e.value)}
                                                        options={[
                                                            { name: 'Aplicacion por gananciales', code: '1' },
                                                            { name: 'Herederos', code: '2' },
                                                            { name: 'Legatarios', code: '3' }
                                                        ]} optionLabel="name"
                                                        placeholder="Seleccionar" className="w-full md:w-14rem" />
                                                )
                                                }
                                            />
                                        else
                                            return <Column
                                                key={field}
                                                field={field}
                                                header={header}
                                                editor={(options) => textEditor(options)}
                                                onCellEditComplete={onCellEditComplete}
                                            />;
                                    })
                                    :
                                    columns2.map(({ field, header }) => {
                                        if (field == 'cmpdt_concepto')
                                            return <Column
                                                key={field}
                                                field={field}
                                                header={header}
                                                body={(rowData, { rowIndex }) => (
                                                    <Dropdown value={titulares[rowIndex].cmpdt_concepto}
                                                        onChange={(e) => handleChangeTitulares(rowIndex, 'cmpdt_concepto', e.value)}
                                                        options={[
                                                            { name: 'Aplicacion por gananciales', code: '1' },
                                                            { name: 'Herederos', code: '2' },
                                                            { name: 'Legatarios', code: '3' }
                                                        ]} optionLabel="name"
                                                        placeholder="Seleccionar" className="w-full md:w-14rem" />
                                                )
                                                }
                                            />
                                        else
                                            return <Column
                                                key={field}
                                                field={field}
                                                header={header}
                                                editor={(options) => textEditor(options)}
                                                onCellEditComplete={onCellEditComplete}
                                            />;
                                    })
                        }
                        <Column
                            headerStyle={{ textAlign: 'center' }}
                            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                            body={actionBodyTemplate} />
                    </DataTable>
                </div>
            </Panel>
        </section >
    );

}

export default React.memo(I1Acto);
import { Button } from 'primereact/button';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../../../service/apiServices';
import authGuardService from '../../../../service/authGuardService';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { InputNumber } from 'primereact/inputnumber';

interface DatosJson {
    id: string;
    folio: string;
    claveCatastral: string | null;
    antecedentes: string | null;
    domicilioRegistral: string | null;
    codigoPostal: string | null;
    localidad: string | null;
    municipio: string | null;
    estado: string | null;
    cruzamiento: string | null;
    colonia: string | null;
    numeroInterior: string | null;
    numeroExterior: string | null;
    calle: string | null;
    medidasColindancias: string | null;
    construida: string | null;
    superficieTotal: string | null;
    titulares: Array<any> | null;
}

interface DatosProps {
    data: DatosJson;
    activarModal: () => void;
    desactivarModal: () => void;
}

interface caratula {
    id: string;
    folio: string;
    claveCatastral: string;
    antecedentes: string;
    domicilioRegistral: string;
    codigoPostal: string;
    localidad: string;
    municipio: string;
    estado: string;
    cruzamiento: string;
    colonia: string;
    numeroInterior: string;
    numeroExterior: string;
    calle: string;
    medidasColindancias: string;
    construida: string;
    superficieTotal: string;
}

const EditCaratula = (props: DatosProps) => {
    const toast = useRef(null);
    const user = authGuardService.getUser()

    const columns = [
        { field: 'ptit_nom', header: 'Nombre' },
        { field: 'ptit_pat', header: 'Primer apellido' },
        { field: 'ptit_mat', header: 'Segundo apellido' },
        { field: 'ptit_rfc', header: 'RFC' },
        { field: 'ptit_nuda', header: 'Nuda %' },
        { field: 'ptit_usufructo', header: 'Uso Fructo %' }
    ];

    const [data, setData] = useState<caratula>({
        id: props && props.data && props.data.id || '',
        folio: props && props.data && props.data.folio || '',
        claveCatastral: props && props.data && props.data.claveCatastral || '',
        antecedentes: props && props.data && props.data.antecedentes || '',
        domicilioRegistral: props && props.data && props.data.domicilioRegistral || '',
        codigoPostal: props && props.data && props.data.codigoPostal || '',
        localidad: props && props.data && props.data.localidad || '',
        municipio: props && props.data && props.data.municipio || '',
        estado: props && props.data && props.data.estado || '',
        cruzamiento: props && props.data && props.data.cruzamiento || '',
        colonia: props && props.data && props.data.colonia || '',
        numeroInterior: props && props.data && props.data.numeroInterior || '',
        numeroExterior: props && props.data && props.data.numeroExterior || '',
        calle: props && props.data && props.data.calle || '',
        medidasColindancias: props && props.data && props.data.medidasColindancias || '',
        construida: props && props.data && props.data.construida || '',
        superficieTotal: props && props.data && props.data.superficieTotal || ''
    })

    const [titulares, setTitulares] = useState(props.data.titulares)
    const [selectedCP, setSelectedCP] = useState<any>(null)
    const [foundedColony, setFoundedColony] = useState([])
    const [firstLoad, setFirstLoad] = useState(false)
    const [dialogSuccess, setDialogSuccess] = useState(false)
    const [selectedTitulares, setSelectedTitulares] = useState(null);
    const [loading, setLoading] = useState(false)

    const handleChangeData = (input: any) => {
        setData({
            ...data,
            [input.target.id]: input.target.value
        });
    };

    const handleChangeTitulares = (index: string | number, field: any, value: any) => {
        const newTitulares = [...titulares];
        newTitulares[index] = {
            ...newTitulares[index],
            [field]: value
        };
        setTitulares(newTitulares);
    };

    const addNewTitular = () => {
        const lastObject = titulares[titulares.length - 1];
        const emptyObject: any = Object.keys(lastObject).reduce((acc, key) => {
            acc[key] = '';
            return acc;
        }, {});

        emptyObject.prop_id = data.id
        emptyObject.ptit_fecnac = '1980-03-23'
        emptyObject.ptit_status = '1'
        emptyObject.ptit_id = '0';
        emptyObject.usu_id = user.usu_id
        emptyObject.ptit_nuda = 0.0
        emptyObject.ptit_usufructo = 0.0

        setTitulares([...titulares, emptyObject]);
    }

    const textEditor = (options: ColumnEditorOptions) => {
        if (options.field == 'ptit_nuda' || options.field == "ptit_usufructo") {
            return (
                <InputNumber value={options.value}
                    onValueChange={(e) => options.editorCallback(e.value)} maxFractionDigits={2} minFractionDigits={2} min={0} max={100} />
            )
        } else
            return <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
            />;
    };

    const onCellEditComplete = (e: { rowIndex: any; field: any; newValue: any; }) => {
        handleChangeTitulares(e.rowIndex, e.field, e.newValue)
    };

    const accept = () => {
        updatePropiedad()
    }

    const reject = () => {
        toast.current?.show({ severity: 'warn', summary: 'No Guardado', detail: 'Los Datos no han sido guardados', life: 3000 });
    }

    const confirmarGuardado = () => {
        confirmDialog({
            message: '¿Desea guardar este registro?',
            header: 'Confirmación de Guardado',
            icon: 'pi pi-info-circle',
            acceptLabel: 'Guardar',
            rejectLabel: 'Cancelar',
            accept,
            reject
        });
    };

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold"></span>
            <Button icon="pi pi-plus" rounded raised onClick={addNewTitular} />
        </div>
    );

    const ColonyTemplate = (option: any) => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '0px',
                background: '#FFFFFF',
                width: '100%',
                height: '100%',
                fontFamily: 'Cabin',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '24px',
                color: '#313131'
            }
            }> {option.cp_col}</div >
        );
    };


    const updatePropiedad = async () => {
        let amountNuda = 0
        let amountUso = 0
        titulares.forEach((singleTitular) => {
            amountNuda = singleTitular.ptit_nuda + amountNuda
            amountUso = singleTitular.ptit_usufructo + amountUso
        })

        if (amountNuda > 100 || amountUso > 100 || amountNuda < 100 || amountUso < 100) {
            if (amountNuda > 100) {
                toast.current?.show({ severity: 'warn', summary: 'Nuda Mayor', detail: 'La suma de las nudas es mayor a 100%', life: 3000 });
            } else if (amountNuda < 100) {
                toast.current?.show({ severity: 'warn', summary: 'Nuda Menor', detail: 'La suma de las nudas es menor a 100%', life: 3000 });
            }
            if (amountUso > 100) {
                toast.current?.show({ severity: 'warn', summary: 'Uso Mayor', detail: 'La suma de los Uso Fructo es mayor a 100%', life: 3000 });
            } else if (amountUso < 100) {
                toast.current?.show({ severity: 'warn', summary: 'Uso Menor', detail: 'La suma de los Uso Fructo es menor a 100%', life: 3000 });
            }
        } else {
            try {
                setLoading(true)
                await updateTitulares()
                let datos = {
                    prop_clave: data.claveCatastral,
                    cp_id: selectedCP.cp_id,
                    prop_calle: data.calle,
                    prop_ext: data.numeroExterior,
                    prop_int: data.numeroInterior,
                    prop_medidas: data.medidasColindancias,
                    prop_constr: data.construida,
                    prop_suptotal: data.superficieTotal,
                    prop_zona: '',
                    prop_condo: '',
                    prop_lote_cond: '',
                    prop_lt: '',
                    prop_edificio: '',
                    prop_libro: '',
                    prop_vol: '',
                    prop_secc: '',
                    prop_serie: '',
                    prop_reg: '',
                    prop_foja: '',
                    prop_mz: '',
                    cp_edo: data.estado,
                    cp_mun: data.municipio,
                    prop_direccion: data.domicilioRegistral,
                    usu_id: user.usu_id,
                    prop_id: data.id,
                    prop_antecedente: data.antecedentes,
                }
                ApiServices.apiPost('updatePropiedad', datos).then(async res => {
                    if (res.data.valido == 1) {
                        setDialogSuccess(true)
                        setTimeout(() => {
                            window.location.reload();
                        }, 4000);
                        setLoading(false)
                        toast.current?.show({ severity: 'info', summary: 'Guardado', detail: 'Los Datos han sido guardados', life: 3000 });
                    } else {
                        reject()
                        setLoading(false)
                        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error al Guardar los datos', life: 3000 });
                    }
                }).catch(error => {
                    setLoading(false)
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error al Guardar los datos', life: 3000 });
                })
            } catch (error) {
                setLoading(false)
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error al Guardar los datos', life: 3000 });
            }
        }
    }

    const updateTitulares = async () => {
        const promises = titulares.map(titular => {
            const cloneTitular = { ...titular };
            delete cloneTitular.ptit_update;
            delete cloneTitular.ptit_regfed;

            const dateOnly = cloneTitular.ptit_fecnac ? cloneTitular.ptit_fecnac.split('T')[0] : ''
            cloneTitular.ptit_fecnac = dateOnly

            let datos = cloneTitular;
            return ApiServices.apiPost('updateTitularPropiedad', datos);
        });

        try {
            await Promise.all(promises);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!firstLoad && data && data.codigoPostal && data.colonia) {
            handlePreviousColonia()
        } else if (!firstLoad && data && data.codigoPostal) {
            handlePreviousCodigoPostal()
        }
    }, [data])

    const handlePreviousCodigoPostal = () => {
        getColonyWithPostalCode(data.codigoPostal)
    }

    const handlePreviousColonia = () => {
        getColonyWithPostalCode(data.codigoPostal)
    }

    const handleCPChange = (cp: any) => {
        if (cp.length === 5) {
            getColonyWithPostalCode(cp)
        }
    }

    const findObjectByColonia = (data, colonia) => {
        return data.find(item => item.cp_col === colonia);
    };

    const getColonyWithPostalCode = (value: any) => {
        let datos
        if (typeof value == 'object')
            datos = { "cp_cp": value[1] }
        else
            datos = { "cp_cp": value }
        setFirstLoad(true)
        ApiServices.apiPost('buscarCP', datos).then(async res => {
            if (res.data.valido === 1) {
                setFoundedColony(res.data.addenda)
                if (data.colonia) {
                    const founded = findObjectByColonia(res.data.addenda, data.colonia)
                    if (founded) {
                        setSelectedCP(founded)
                        setData({
                            ...data,
                            ['codigoPostal']: founded.cp_cp,
                            ['localidad']: founded.cp_loc,
                            ['municipio']: founded.cp_mun,
                            ['estado']: founded.cp_edo
                        });
                    }
                } else {
                    setSelectedCP(res.data.addenda[0])
                    setData({
                        ...data,
                        ['codigoPostal']: res.data.addenda[0].cp_cp,
                        ['localidad']: res.data.addenda[0].cp_loc,
                        ['municipio']: res.data.addenda[0].cp_mun,
                        ['estado']: res.data.addenda[0].cp_edo
                    });
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const handleNewColonia = (e: any) => {
        setSelectedCP(e.value)
        setData({
            ...data,
            ['localidad']: e.value.cp_loc,
            ['municipio']: e.value.cp_mun,
            ['estado']: e.value.cp_edo
        });
    }

    const actionBodyTemplate = (rowData, index) => {
        if (rowData.ptit_status == 0)
            return <Button type="button" icon="pi pi-history" rounded onClick={() => { recoverTitular(rowData, index.rowIndex) }}></Button>;
        else
            return <Button type="button" severity="danger" icon="pi pi-trash" rounded onClick={() => { deleteTitular(rowData, index.rowIndex) }}></Button>;
    };

    const isDeletedTemplate = (rowData, index) => {
        if (rowData.ptit_status == 0)
            return <Tag severity="danger" value="Se Eliminará"></Tag>
        else return ''
    };

    const deleteTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.ptit_id == 0) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].ptit_status = 0
        }
        setTitulares(cloneTitulares)
    }

    const recoverTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.ptit_id == 0) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].ptit_status = 1
        }
        setTitulares(cloneTitulares)
    }

    return (
        <section>
            <Toast ref={toast} />
            <ConfirmDialog />

            <Divider align="top">
                <h5>Datos generales</h5>
            </Divider>
            <div className='grid'>
                <div className='p-col-12 md:col-4'>
                    <span className="p-float-label">
                        <InputText
                            id='claveCatastral'
                            className="p-inputtext-lg"
                            value={data.claveCatastral}
                            onChange={handleChangeData}
                        />
                        <label htmlFor="username">Clave catastral</label>
                    </span>
                </div>
                <div className='p-col-12 md:col-8'>
                    <span className="p-float-label">
                        <InputText
                            id='antecedentes'
                            className="p-inputtext-lg"
                            value={data.antecedentes}
                            onChange={handleChangeData}
                        />
                        <label htmlFor="username">Antecedentes</label>
                    </span>
                </div>
                <div className='p-col-12 md:col-12'>
                    <span className="p-float-label">
                        <InputText
                            id='domicilioRegistral'
                            className="p-inputtext-lg"
                            value={data.domicilioRegistral}
                            onChange={handleChangeData}
                        />
                        <label htmlFor="username">Domicilio registral</label>
                    </span>
                </div>
            </div>

            <h6>Domicilio convencional</h6>
            <br />
            <div className='grid'>
                <div className='col-12 md:col-6'>
                    <span className="p-float-label">
                        <InputText
                            maxLength={5}
                            id="codigoPostal"
                            className="p-inputtext-lg"
                            keyfilter="int"
                            value={data.codigoPostal}
                            onChange={(e) => {
                                handleChangeData(e)
                                handleCPChange(e.target.value)
                            }}
                        />
                        <label htmlFor="codigoPostal">Codigo postal</label>
                    </span>
                </div>

                <div className='col-12 md:col-6'>
                    <span className="p-float-label">
                        <Dropdown
                            virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            id="colonia"
                            value={selectedCP}
                            onChange={handleNewColonia}
                            options={foundedColony}
                            optionLabel="cp_col"
                            placeholder="Colonia"
                            itemTemplate={ColonyTemplate}
                            panelStyle={{ background: '#FFF' }}
                            className="p-inputtext-lg"
                            emptyMessage='Sin Resultados'
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="colony">Colonia</label>
                    </span>
                </div>
                <div className='col-12 md:col-6'>
                    <span className="p-float-label">
                        <InputText
                            id="municipio"
                            className="p-inputtext-lg"
                            value={data.municipio}
                            onChange={handleChangeData}
                            disabled
                        />
                        <label htmlFor="municipality">Municipio</label>
                    </span>
                </div>
                <div className='col-12 md:col-6'>
                    <span className="p-float-label">
                        <InputText
                            id="estado"
                            className="p-inputtext-lg"
                            value={data.estado}
                            onChange={handleChangeData}
                            disabled
                        />
                        <label htmlFor="estado">Estado</label>
                    </span>
                </div>
                <div className='p-col-12 md:col-4'>
                    <span className="p-float-label">
                        <InputText
                            id='calle'
                            className="p-inputtext-lg"
                            value={data.calle}
                            onChange={handleChangeData}
                        />
                        <label htmlFor="username">Calle</label>
                    </span>
                </div>
                <div className='p-col-12 md:col-4'>
                    <span className="p-float-label">
                        <InputText
                            id='numeroInterior'
                            className="p-inputtext-lg"
                            value={data.numeroInterior}
                            onChange={handleChangeData}
                        />
                        <label htmlFor="username">N° int</label>
                    </span>
                </div>
                <div className='p-col-12 md:col-4'>
                    <span className="p-float-label">
                        <InputText
                            id='numeroExterior'
                            className="p-inputtext-lg"
                            value={data.numeroExterior}
                            onChange={handleChangeData}
                        />
                        <label htmlFor="username">N° ext</label>
                    </span>
                </div>
            </div>

            <h6>Superficie del predio</h6>
            <br />
            <div className='grid'>
                <div className='p-col-12 md:col-4'>
                    <span className="p-float-label">
                        <InputText
                            id='medidasColindancias'
                            className="p-inputtext-lg"
                            value={data.medidasColindancias}
                            onChange={handleChangeData}
                        />
                        <label htmlFor="username">Medidas y Colindancias</label>
                    </span>
                </div>
                <div className='p-col-12 md:col-4'>
                    <span className="p-float-label">
                        <InputText
                            id='construida'
                            className="p-inputtext-lg"
                            value={data.construida}
                            onChange={handleChangeData}
                        />
                        <label htmlFor="username">Construida</label>
                    </span>
                </div>
                <div className='p-col-12 md:col-4'>
                    <span className="p-float-label">
                        <InputText
                            id='superficieTotal'
                            className="p-inputtext-lg"
                            value={data.superficieTotal}
                            onChange={handleChangeData}
                        />
                        <label htmlFor="username">Superficie total</label>
                    </span>
                </div>
            </div>

            <Divider align="top">
                <h5>Titulares</h5>
            </Divider>

            <div className='pt-4'>
                <DataTable
                    value={titulares}
                    dataKey="ptit_id"
                    paginator
                    editMode="cell"
                    rows={5}
                    className="datatable-responsive"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    emptyMessage="No hay titulares."
                    header={header}
                    showGridlines
                >
                    <Column
                        headerStyle={{ textAlign: 'center' }}
                        bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                        body={isDeletedTemplate} />
                    {columns.map(({ field, header }) => {
                        return <Column
                            key={field}
                            field={field}
                            header={header}
                            editor={(options) => textEditor(options)}
                            onCellEditComplete={onCellEditComplete}
                        />;
                    })}
                    <Column
                        headerStyle={{ textAlign: 'center' }}
                        bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                        body={actionBodyTemplate} />
                </DataTable>
            </div>

            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: 20 }}>
                <Button label="Cancelar" style={{ width: '20%', marginRight: 15 }} onClick={props.desactivarModal} />
                <Button label="Guardar" style={{ width: '20%', marginLeft: 15 }} onClick={confirmarGuardado} />
            </div>

            <Dialog
                visible={dialogSuccess}
                onHide={() => { setDialogSuccess(false); }}
                closable={false}
            >
                <div style={{
                    borderRadius: '12px', justifyContent: 'center', display: 'flex', flexDirection: 'column',
                    margin: 20
                }}>
                    <div style={{ alignSelf: 'center' }}>
                        {loading ?
                            <i className="pi pi-spin pi-spinner" style={{ fontSize: '4rem' }}></i>
                            :
                            <i className="pi pi-thumbs-up" style={{ fontSize: '4rem' }}></i>
                        }
                    </div>
                    {loading ?
                        <h4 style={{ marginTop: 20, alignSelf: 'center' }}>Guardando datos .....</h4> :
                        <>
                            <h4 style={{ marginTop: 20, alignSelf: 'center' }}>Cambios Guardados con Exito</h4>
                            <h4 style={{ marginTop: 20, alignSelf: 'center' }}>Redirigiendo a la pagina actualizada...</h4>
                        </>
                    }
                </div>
            </Dialog>

        </section>
    );

}

export default React.memo(EditCaratula);
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Timeline } from 'primereact/timeline';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import configuration from '../../../../../configurationFile';
import ApiServices from '../../../../../service/apiServices';
import authGuardService from '../../../../../service/authGuardService';
import EncryptService from '../../../../../service/encrypt.service';
import formatDate from '../../../../../utilities/formatDate';
import DynamicForm from '../../../../actos/DynamicForm';
import Historial from '../../../../../refactor/PagoFolio/Historial/Historial';

interface City {
    name: string;
}

const TabHistorialActo = (props: any) => {
    return (
        <section>
            <Historial
                historial={props.actoHistory}
            />
        </section>
    );

}

export default React.memo(TabHistorialActo);
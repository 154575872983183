import { Button } from 'primereact/button';
import { memo } from 'react';

interface DatosJson {
    buttons: Array<any>;
}

interface DatosProps {
    data: DatosJson;
}

const MainFooter = (props: DatosProps) => {

    return (
        <section>
            <div className='footer-folio flex justify-content-end'>
                <div className='flex col-6'>
                    {
                        props.data.buttons && props.data.buttons.map((singleButton) => {
                            return (
                                <Button
                                    label={singleButton.label}
                                    className={`${singleButton.outlined ? 'p-button-outlined mr-4' : ''}`}
                                    onClick={singleButton.function}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </section >
    );
}

export default memo(MainFooter);



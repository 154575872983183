import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../serviceExample/ProductService';
import { useNavigate } from 'react-router-dom';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

const TraRevision = (props: any) => {

    const navigate = useNavigate();

    const [globalFilter, setGlobalFilter] = useState<any>(null);

    const navigateToScreen = (data: any) => {
        const navigateOptions = {
            state: {
                preTramite: data
            }
        };

        switch (data.ptrm_status) {
            case 1:
                navigate('/notarios/pago-folio', navigateOptions)
                break;
            case 2:
                navigate('/notarios/pago-folio-view', navigateOptions)
                break;
            case 3:
                navigate('/notarios/pago-folio', navigateOptions)
                break;
            case 4:
                navigate('/notarios/pago-folio', navigateOptions)
                break;
            case 5:
                navigate('/notarios/pago-folio-view', navigateOptions)
                break;
            default:
                break;
        }
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => navigateToScreen(rowData)} />
            </div>
        );
    }

    const header = (
        <div className='flex justify-content-end'>
            <div className='col-12 md:col-4'>
                <span className="p-float-label p-input-icon-left w-100">
                    <i className="pi pi-search" />
                    <InputText className=' p-inputtext-lg' type="search" onChange={(e) => { searchActos(e.target.value) }} />
                    <label htmlFor="username">Buscador general</label>
                </span>
            </div>
        </div>
    );

    const statusBodyTemplate = (tramite: any) => {
        const statusType = getStatusType(tramite.ptrm_status)
        return <Tag value={statusType} severity={getSeverity(tramite.ptrm_status)}></Tag>;
    };

    const getStatusType = (ptrm_status: any) => {
        switch (ptrm_status) {
            case 0:
                return 'Eliminado';
            case 1:
                return 'Borrador';
            case 2:
                return 'En Revision';
            case 3:
                return 'Rechazado';
            case 4:
                return 'Subsanado';
            case 5:
                return 'Aprobado';
            default:
                return null;
        }
    };

    const getSeverity = (ptrm_status: any) => {
        switch (ptrm_status) {
            case 0:
                return 'danger'
            case 1:
                return 'info'
            case 2:
                return 'warning';
            case 3:
                return 'danger';
            case 4:
                return 'warning';
            case 5:
                return 'success';
            default:
                return null;
        }
    };

    function buscarCoincidencias(texto: any, array: any) {
        const textoMinusculas = texto.toLowerCase();
        const coincidencias = array.filter((objeto: any) => {
            for (const propiedad in objeto) {
                if (objeto[propiedad] && objeto[propiedad].toString().toLowerCase().includes(textoMinusculas)) {
                    return true;
                }
            }
            return false;
        });

        return coincidencias;
    }

    const [tramites, setTramites] = useState(props.preTramites)

    const searchActos = (e: any) => {
        const clone = JSON.parse(JSON.stringify(props.preTramites))
        setTramites(buscarCoincidencias(e, clone))
    }

    const controlInternoBodyTemplate = (rowData: any) => {
        const mesesEnEspanol = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        // Parsear las fechas y horas del data
        const fechaActual = new Date(rowData.ptrm_fecha);
        const horaActual = new Date(rowData.ptrm_hora);

        if (fechaActual || horaActual) {
            const dia = fechaActual.getDate();
            const mes = fechaActual.getMonth() + 1;
            const anio = fechaActual.getFullYear();
            let hora = horaActual.getUTCHours();
            const minutos = horaActual.getUTCMinutes();
            const segundos = horaActual.getUTCSeconds();
            let periodo = "am";

            if (hora >= 12) {
                periodo = "pm";
                if (hora > 12) {
                    hora -= 12;
                }
            }
            if (hora === 0) {
                hora = 12;
            }

            const minutosFormateados = minutos < 10 ? "0" + minutos : minutos;
            const segundosFormateados = segundos < 10 ? "0" + segundos : segundos;

            const fechaYHoraFormateada = `${dia + 1}/${mes}/${anio} - ${hora}:${minutosFormateados}:${segundosFormateados}`;
            return fechaYHoraFormateada;
        } else {
            return ''
        }

    }

    return (
        <section>
            <DataTable
                value={tramites}
                loading={props.preTramitesLoading}
                dataKey="ptrm_id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                globalFilter={globalFilter} emptyMessage="Sin Datos" header={header} responsiveLayout="scroll">
                <Column sortable field="ptrm_consecutivo" header="Control Interno Notaria"></Column>
                <Column sortable field="ptrm_fecha" header="Fecha" body={controlInternoBodyTemplate}></Column>
                <Column sortable field="prop_folio" header="Folio electronico"></Column>
                <Column sortable field="prop_clave" header="Clave catastral"></Column>
                <Column sortable field="actos" header="Actos"></Column>
                <Column sortable field="ptrm_status" header="Estatus" body={statusBodyTemplate}></Column>
                <Column body={actionBodyTemplate} style={{ width: '3rem' }}></Column>
            </DataTable>
        </section>
    );

}

export default React.memo(TraRevision);
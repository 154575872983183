import { addLocale } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Sidebar } from 'primereact/sidebar';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import configuration from '../../../configurationFile';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import { Checkbox } from 'primereact/checkbox';

addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    today: 'Hoy',
    clear: 'Limpiar'
});

const AnaInicio = () => {
    const estatus: any = [
        { name: 'Capturado', code: 1 },
        { name: 'Asignado', code: 2 },
        { name: 'Analizado', code: 3 },
        { name: 'Rechazado', code: 4 },
        { name: 'Por Subsanar', code: 5 },
        { name: 'Subsanado', code: 6 },
        { name: 'Por Firmar Aceptado', code: 7 },
        { name: 'Por Firmar Rechazado', code: 8 }
    ];

    const user = authGuardService.getUser()
    const navigate = useNavigate();
    const toast = useRef<Toast>(null);

    const [certificationsList, setCertificationsList] = useState([])
    const [certificationsOriginalList, setCertificationsOriginalList] = useState([])
    const [listAnalistas, setListAnalistas] = useState()
    const [listFirmadores, setListFirmadores] = useState()
    const [selectedDate, setSelectedDate] = useState<Date | Date[] | undefined | null>(undefined);
    const [selectedIngreso, setSelectedIngreso] = useState<any>()
    const [selectedCelda, setSelectedCelda] = useState<any>()
    const [selectedStatus, setSelectedStatus] = useState<any>()
    const [analistasFirmadores, setAnalistasFirmadores] = useState<any>()
    const [selectedFirmador, setSelectedFirmador] = useState<any>()
    const [newStatus, setNewStatus] = useState(true)
    const [searchText, setSearchText] = useState('')
    const [filter, setFilter] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [EFirmavisible, EFirmasetVisible] = useState<boolean>(false);
    const [buttons, setButtons] = useState('DISTRIBUIR')
    const [buttonsDisabled, setButtonsDisabled] = useState(true)
    const [selectedTramitesCount, setSelectedTramitesCount] = useState(0)


    function filtrarPorTexto(texto: any) {
        if (texto.trim() === '') {
            return false;
        }
        const textoBuscado = texto.toLowerCase();
        const cloneArray = JSON.parse(JSON.stringify(certificationsOriginalList))
        const resultados = cloneArray.filter((item: any) => {
            const valores = Object.values(item).map(value => String(value).toLowerCase());
            return valores.some(valor => valor.includes(textoBuscado));
        });
        return resultados;
    }

    function buscarUsuarioPorId(usuarios: any, id: any) {
        for (let i = 0; i < analistasFirmadores.length; i++) {
            if (analistasFirmadores[i].usu_id === id) {
                return analistasFirmadores[i];
            }
        }
        return null;
    }

    function filtrarPorFecha(array: any, fecha: any) {
        const fechaFiltro = new Date(fecha);
        const resultados = array.filter((item: any) => {
            const fechaItem = new Date(item.trm_fecha.slice(0, 19));
            return fechaItem.getTime() === fechaFiltro.getTime();
        });

        return resultados;
    }

    function filtrarPorAsignacion(array: any, asignacion: any) {
        const resultados = array.filter((item: any) => {
            return item.trm_analista == asignacion.usu_id
        });

        return resultados;
    }

    function buscarPorId(array: any, trmId: any) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].trm_id === trmId) {
                return i;
            }
        }
        return null;
    }

    const joinFirmadoresAnalistas = () => {
        const cloneAnalistas = JSON.parse(JSON.stringify(listAnalistas))
        const cloneFirmadores = JSON.parse(JSON.stringify(listFirmadores))
        const mergedArray: any = [...cloneAnalistas, ...cloneFirmadores];
        setAnalistasFirmadores(mergedArray)
    }

    const getAnalistas = () => {
        let datos = {
            area_id: user.area_id,
        }
        ApiServices.apiPost('getAnalistasArea', datos).then(async res => {
            setListAnalistas(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getFirmadores = () => {
        let datos = {
            area_id: user.area_id,
        }
        ApiServices.apiPost('getFirmadores', datos).then(async res => {
            setListFirmadores(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getCertificationsList = (trm_status = '2,3,4', returnFalse = newStatus, userId = user.usu_id) => {
        let datos = {
            area_id: user.area_id,
            trm_status,
            usu_id: userId
        }
        ApiServices.apiPost('getCertificaciones', datos).then(async res => {
            formatListCertifications(res.data.addenda, returnFalse)
        }).catch(error => {
            console.log(error)
        })
    }

    const formatListCertifications = (data: any, returnFalse: any) => {
        if (data && data.length > 0) {
            const newItems = data.map((singleItem: any) => {
                let idToSearch = singleItem.trm_analista

                if (singleItem.trm_status == 7 || singleItem.trm_status == 8 || singleItem.trm_status == 9 || singleItem.trm_status == 10) {
                    idToSearch = singleItem.trm_firmante
                }
                const obtainItem = buscarUsuarioPorId(listAnalistas, idToSearch)
                const cloneSingleItem = JSON.parse(JSON.stringify(singleItem))
                cloneSingleItem.analistaSelect = obtainItem
                return cloneSingleItem
            })
            setCertificationsList(newItems)
            setCertificationsOriginalList(newItems)
            applyFilters(newItems, returnFalse)
        } else {
            setCertificationsList([])
        }
    }

    const controlBodyTemplate = (rowData: any) => {
        const fechaUTC = new Date(rowData.trm_fecha);
        const horaUTC = new Date(rowData.trm_hora || rowData.trm_fecha);

        const day = fechaUTC.getUTCDate().toString().padStart(2, '0');
        const month = (fechaUTC.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = fechaUTC.getUTCFullYear();
        const hours = horaUTC.getUTCHours().toString().padStart(2, '0');
        const minutes = horaUTC.getUTCMinutes().toString().padStart(2, '0');
        const seconds = horaUTC.getUTCSeconds().toString().padStart(2, '0');


        const fechaFormateada = `${day}/${month}/${year} - ${rowData.trm_hora}`;

        return (
            <p>{fechaFormateada ? `${fechaFormateada}` : ''}</p>
        );
    };

    const statusBodyTemplate = (rowData: any) => {
        const statusType = getStatusType(rowData.trm_status)
        return <Tag value={statusType} severity={getSeverity(rowData.trm_status)}></Tag>;
    };

    const getStatusType = (trm_status: any) => {
        switch (trm_status) {
            case 0:
                return 'Borrador';
            case 1:
                return 'Capturado';
            case 2:
                return 'Asignado';
            case 3:
                return 'Analizado';
            case 4:
                return 'Rechazado';
            case 5:
                return 'Por Subsanar';
            case 6:
                return 'Subsanado';
            case 7:
                return 'Por Firmar Aceptado';
            case 8:
                return 'Por Firmar Rechazado';
            case 9:
                return 'Firmado Aceptado';
            case 10:
                return 'Firmado Rechazado';
            default:
                return null;
        }
    };

    const getSeverity = (trm_status: any) => {
        switch (trm_status) {
            case 0:
                return 'danger'
            case 1:
                return 'info'
            case 2:
                return 'warning';
            case 3:
                return 'danger';
            case 4:
                return 'warning';
            case 5:
                return 'success';
            case 6:
                return 'info'
            case 7:
                return 'warning';
            case 8:
                return 'danger';
            case 9:
                return 'success';
            case 10:
                return 'success';
            default:
                return null;
        }
    };

    const applyFilters = (newArrayFunction = certificationsList, isFalse = newStatus) => {
        if (selectedStatus && selectedStatus.length > 0 && newStatus) {
            const newElements = selectedStatus && selectedStatus.map((singleElement: any) => {
                return singleElement.code
            })
            if (isFalse) {
                getCertificationsList(`${newElements.toString()}`, false)
                setNewStatus(false)
            }
        }

        if (selectedDate) {
            const newArray = filtrarPorFecha(newArrayFunction, selectedDate)
            setCertificationsList(newArray)
        }

        if (selectedCelda) {
            const newArray = filtrarPorAsignacion(newArrayFunction, selectedCelda)
            setCertificationsList(newArray)
        }

    }

    const clearFilters = () => {
        setSelectedDate(null)
        setSelectedStatus(null)
        setSelectedCelda(null)
        setSelectedIngreso(null)

        getCertificationsList()
    }

    const sentSignRequest = () => {
        const certificationsToSent = certificationsList.map((singleCertification: any) => {
            if (singleCertification.selected && (singleCertification.trm_analista || singleCertification.analistaSelect)) {
                return (singleCertification.trm_id)
            }
        })
        const certificationsToSentFiltered = certificationsToSent.filter((element) => element !== null && element !== undefined);

        let datos
        datos = { "code": 505, trm_id: certificationsToSentFiltered, usu_id: user.usu_id, usu_id_firma: selectedFirmador.usu_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                toast.current?.show({ severity: 'success', summary: 'Tramites Actualizados', detail: res.data.mensaje, life: 3000 });
                getCertificationsList()
                setVisible(false)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const signTramite = () => {
        const certificationsToSent = certificationsList.map((singleCertification: any) => {
            if (singleCertification.selected && (singleCertification.trm_analista || singleCertification.analistaSelect)) {
                return (singleCertification.analistaSelect.usu_id)
            }
        })
        const certificationsToSentFiltered = certificationsToSent.filter((element) => element !== null && element !== undefined);

        let datos
        datos = { "code": 506, trm_id: certificationsToSentFiltered, usu_id: user.usu_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                toast.current?.show({ severity: 'success', summary: 'Tramites Actualizados', detail: res.data.mensaje, life: 3000 });
                getCertificationsList()
                setVisible(false)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const SavePDFcert = async (trm: any) => {
        let datos
        datos = { "code": 701, area_id: user.area_id, trm_id: trm.trm_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.url[0].valido == 0) {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error', life: 3000 });
                } else {
                    if (typeof res.data.url === 'object') {
                        if (res.data.url && res.data.url.length > 0) {
                            res.data.url.forEach((single: any) => {
                                window.open(`${configuration.url}${single.url}`, '_blank');
                            });
                        }
                    } else {
                        window.open(`${configuration.url}${res.data.url}`, '_blank')
                    }
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions flex">
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => {
                    navigate('/interno/view-folio', {
                        state: {
                            folio: rowData
                        }
                    })
                }} />
                {rowData && rowData.trm_status == 7 || rowData.trm_status == 8 || rowData.trm_status == 9 || rowData.trm_status == 10 ?
                    <Button icon="pi pi-file" onClick={() => { SavePDFcert(rowData) }} rounded text severity="secondary" /> : ''}
            </div>
        );
    }

    const analistaTemplate = (option: any) => (
        <p>{`${option.usu_trab_id || ''} - ${option.usu_nom || ''} ${option.usu_pat || ''}`}</p>
    );

    const analistaSelectedTemplate = (option: any) => {
        if (option)
            return (
                <p>{`${option.usu_trab_id || ''} - ${option.usu_nom || ''} ${option.usu_pat || ''}`}</p>
            )
        else return (<p>Seleccionar</p>)
    };

    const footerContent = (
        <div className=''>
            <Button label="Cancelar" onClick={() => setVisible(false)} severity="secondary" outlined />
            <Button label="Enviar" onClick={sentSignRequest} autoFocus disabled={!selectedFirmador} />
        </div>
    );

    const EFirmafooterContent = (
        <div className=''>
            <Button label="Cancelar" onClick={() => EFirmasetVisible(false)} severity="secondary" outlined />
            <Button label="Firmar" onClick={signTramite} autoFocus />
        </div>
    );

    const filterSearch = (value: any) => {
        setSearchText(value)
        const newArray = filtrarPorTexto(value)
        if (newArray)
            setCertificationsList(newArray)
        else
            setCertificationsList(certificationsOriginalList)
    }

    useEffect(() => {
        getAnalistas()
        getFirmadores()
    }, [])

    useEffect(() => {
        if (analistasFirmadores)
            getCertificationsList()
    }, [analistasFirmadores])

    useEffect(() => {
        if (listFirmadores && listAnalistas) {
            joinFirmadoresAnalistas()
        }
    }, [listFirmadores, listAnalistas])

    const calendarRef = useRef(null);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;

        // Convertir el formato MMDDYY a MM/DD/YYYY
        const formattedDate = formatDateString(inputValue);

        if (formattedDate) {
            setSelectedDate(new Date(formattedDate));
        } else {
            setSelectedDate(null);
        }

        // Actualizar el valor del input del Calendar manualmente si el input existe
        if (calendarRef.current && calendarRef.current.inputElement) {
            calendarRef.current.inputElement.value = formattedDate || inputValue;
        }
    };
    const formatDateString = (input) => {
        if (input.length === 8) {
            const day = input.substring(0, 2);
            const month = input.substring(2, 4);
            const year = input.substring(4, 8);
            return `${month}/${day}/${year}`;
        }
        return null;
    };

    const handleButtons = (newList: any) => {
        const copyArray: any = JSON.parse(JSON.stringify(newList))
        const distribuirList = [1, 2, 6]
        const firmarList = [3, 4, 5, 7, 8]

        const elementsSelected = copyArray.map((singleItem: any) => {
            if (singleItem.selected) {
                return singleItem.trm_status
            }
        })

        const arrayFiltrado = elementsSelected.filter((elementsSingle: any) => elementsSingle !== undefined);
        setButtonsDisabled(arrayFiltrado.length <= 0)
        setSelectedTramitesCount(arrayFiltrado.length)
        const distribuirCheck = arrayFiltrado.every((singleElement: any) => distribuirList.includes(singleElement));
        const firmarCheck = arrayFiltrado.every((singleElement: any) => firmarList.includes(singleElement));

        if (distribuirCheck && !firmarCheck) {
            setButtons("DISTRIBUIR")
        } else if (firmarCheck && !distribuirCheck) {
            setButtons('FIRMADOR')
        } else {
            if (arrayFiltrado.length <= 0)
                setButtons("DISTRIBUIR")
            else
                setButtons("NONE")
        }
    }

    const CheckBodyTemplate = (rowData: any) => {
        const changeSelected = (data: any) => {
            const copyArray: any = JSON.parse(JSON.stringify(certificationsList))
            const index: any = buscarPorId(copyArray, data.trm_id)
            copyArray[index].selected = copyArray[index].selected ? !(copyArray[index].selected) : true
            handleButtons(copyArray)
            setCertificationsList(copyArray)
        }
        if (rowData.trm_status == 9 || rowData.trm_status == 10) {
            return <Checkbox checked={false} disabled />;
        }
        else {
            return <Checkbox checked={rowData.selected ? rowData.selected : false} onClick={() => { changeSelected(rowData) }} />;
        }
    };


    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-8'>
                    <h3>Boletas</h3>
                </div>
                <div className='col-12 md:col-4'>
                    <div className='grid'>
                        <div className='col-10'>
                            <span className="p-input-icon-right">
                                <i className="pi pi-search" />
                                <span className='p-float-label '>
                                    <InputText id="username" className="p-inputtext-lg" onChange={(e) => { filterSearch(e.target.value) }} />
                                    <label htmlFor="username">Buscador</label>
                                </span>
                            </span>
                        </div>
                        <div className='col-2'>
                            <Button icon="pi pi-filter-fill" aria-label="Filter" size="large" onClick={() => setFilter(true)} />
                        </div>
                    </div>

                </div>

                <div className='col-12'>
                    <DataTable
                        value={certificationsList}
                        paginator
                        rows={10}
                        dataKey="trm_id"
                        tableStyle={{ minWidth: '50rem' }}
                    >
                        <Column sortable field="trm_consecutivo" header="# CI" ></Column>
                        <Column sortable field="trm_fecha" header="Fecha / Hora" body={controlBodyTemplate}></Column>
                        <Column sortable field="prop_folio" header="Folio electronico"></Column>
                        <Column sortable field="prop_clave" header="Clave catastral"></Column>
                        <Column sortable field="actos" header="Actos"></Column>
                        <Column sortable field="trm_status" header="Estatus" body={statusBodyTemplate} ></Column>
                        <Column body={actionBodyTemplate} style={{ width: '3rem' }}></Column>
                    </DataTable>
                </div>
            </div>

            <div className='footer-folio flex justify-content-end'>
                <div className='flex col-3'>

                    {buttons === 'FIRMADOR' &&
                        <Button label="Mandar a Firmar" className='p-button-outlined mx-4' onClick={() => { setVisible(true) }} disabled={buttonsDisabled} />
                    }

                </div>
            </div>

            {/*inicia sidebr de filtracion*/}

            <Sidebar visible={filter} position="right" onHide={() => setFilter(false)}>
                <h4>Filtración</h4>
                <br />
                <p> Selecciona los campos necesarios para realizar una filtración.</p>
                <hr />
                <br />
                <div className='grid'>
                    <div className='col-12'>
                        <span className="p-float-label">
                            {/* Input Done */}
                            <Calendar
                                value={selectedDate}
                                onChange={(e: any) => setSelectedDate(e.value)}
                                dateFormat="dd/MM/yy"
                                showIcon
                                className="p-inputtext-lg"
                                locale="es"
                                showButtonBar
                                onClearButtonClick={() => { setNewStatus(true) }}
                                ref={calendarRef}
                                onInput={(e) => handleInputChange(e)}
                            />
                            <label htmlFor="username">Fecha</label>
                        </span>
                    </div>

                    <div className='col-12'>
                        <span className="p-float-label">
                            <Dropdown
                                inputId="dd-city"
                                value={selectedCelda}
                                onChange={(e) => {
                                    setNewStatus(true)
                                    setSelectedCelda(e.value)
                                }}
                                options={analistasFirmadores}
                                optionLabel="usu_trab_id"
                                className="w-100 p-inputtext-lg"
                                itemTemplate={analistaTemplate}
                                valueTemplate={analistaSelectedTemplate}
                                showClear
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                            <label htmlFor="dd-city">Asignación</label>
                        </span>
                    </div>
                    <div className='col-12'>
                        <span className="p-float-label">
                            <MultiSelect
                                value={selectedStatus}
                                onChange={(e) => {
                                    setNewStatus(true)
                                    setSelectedStatus(e.value)
                                }}
                                options={estatus}
                                optionLabel="name"
                                display="chip"
                                placeholder="Estatus Seleccionados"
                                maxSelectedLabels={3}
                                className="w-100 p-inputtext-lg"
                            />
                            <label htmlFor="dd-city">Estatus</label>
                        </span>
                    </div>
                </div>
                <div className='footer-proccess'>
                    <div className='grid'>
                        <div className='col-6'> <Button label="Limpiar" size="large" outlined autoFocus onClick={clearFilters} /></div>
                        <div className='col-6'>  <Button label="Filtrar" size="large" autoFocus onClick={() => { applyFilters() }} /></div>
                    </div>
                </div>
            </Sidebar>

            {/*inicia dialog firmar: si el usuario firmador, no agrego su e-firma en el perfil, le pedira que agrege su firma por todos los que selecciono*/}

            <Dialog header="E-Firma" visible={EFirmavisible} maximizable style={{ width: '40vw' }} onHide={() => EFirmasetVisible(false)} footer={EFirmafooterContent}>
                <p>Al ingresar la E-firma, estas autorizando que los certificados seleccionados sean firmados.</p>
                <div className='grid'>
                    <div className='col-12'>
                        <span className="p-float-label">
                            <InputText id="username" className="p-inputtext-lg" />
                            <label htmlFor="username">Contraseña de E-Firma</label>
                        </span>
                    </div>
                </div>
                <p>{selectedTramitesCount} Tramites seleccionados para firmar</p>
            </Dialog>

            <Dialog header="Enviar a firma" visible={visible} onHide={() => setVisible(false)} footer={footerContent}>
                <p>Selecciona el firmador para enviar los tramites seleccionados</p>
                <div className='grid'>
                    <div className='col-12'>
                        <span className="p-float-label">
                            <Dropdown
                                inputId="dd-city"
                                value={selectedFirmador}
                                onChange={(e) => setSelectedFirmador(e.value)}
                                options={listFirmadores}
                                optionLabel="usu_trab_id"
                                className="w-100 p-inputtext-lg"
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                            <label htmlFor="dd-city">Firmador</label>
                        </span>
                    </div>
                </div>
                <p>{selectedTramitesCount} Tramites seleccionados para enviar a firmar</p>
            </Dialog>

        </section>
    );

}

export default React.memo(AnaInicio);
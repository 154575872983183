import React, { Component } from "react";
import TabButtons from './TabsButton'
import './tab.css'

class Tabs extends Component {
    state = {
        activeTab: this.props.children[0].props.label
    }
    changeTab = (tab) => {

        this.setState({ activeTab: tab });
    };
    render() {

        let content;
        let buttons = [];
        let diableds = []
        return (
            <div>
                {React.Children.map(this.props.children, child => {
                    if (child) {
                        buttons.push({ label: child.props.label, buttonDisabled: child.props.disabled, click: child.props.onClick })
                        if (child.props.label === this.state.activeTab) content = child.props.children
                    }
                })}

                <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab} />
                <div className="tab-content">{content}</div>

            </div>
        );
    }
}

export default Tabs;
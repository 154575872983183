import { Divider } from 'primereact/divider';
import { memo } from 'react';
import FolioCalidad from './SubComponentes/FolioCalidad';
import LineaDelTiempo from './SubComponentes/LineaDelTiempo';

interface DatosJson {
    folio: string;
    consecutivo: string | null;
    fecha: Date | null;
    hora: Date | null;
    calidadFolio: number | string,
    lineaDelTiempo: any;
    title?: string
}

interface DatosProps {
    data: DatosJson;
}

const MainHeader = (props: DatosProps) => (
    <section>
        <div className='flex justify-content-between align-items-center'>
            <div className='flex col-12 md:col-6'>
                <FolioCalidad
                    data={{
                        folio: props.data.folio,
                        consecutivo: props.data.consecutivo,
                        fecha: props.data.fecha,
                        hora: props.data.hora,
                        calidadFolio: props.data.calidadFolio,
                        title: props.data.title
                    }}
                />
            </div>
            <div className='col-12 md:col-6'>
                <LineaDelTiempo lineaDelTiempo={props.data.lineaDelTiempo} />
            </div>
        </div>
        <Divider />
    </section >
);

export default memo(MainHeader);



import { memo } from 'react';

interface DatosJson {
    folio: string;
    consecutivo: string | null;
    fecha: Date | null;
    hora: Date | null;
    calidadFolio: number | string;
    title?: string
}

interface DatosProps {
    data: DatosJson;
}

const DetalleFolio = (props: DatosProps) => {

    function formatDate(date: Date, hours: Date) {
        const months = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const newDate = new Date(date);
        const newHour = new Date(hours);
        const day = newDate.getUTCDate();
        const month = months[newDate.getUTCMonth()];
        const year = newDate.getUTCFullYear();
        const hour = newHour.getUTCHours();
        const minutes = newHour.getUTCMinutes();

        const fechaFormateada = `${day} ${month} ${year} a las ${hour}:${minutes}`;
        return fechaFormateada;
    }

    let calidadFolioClass = ''
    let calidadFolioMensaje = ''
    let calidadFolioActivado = false

    if (props.data.calidadFolio == 1) {
        calidadFolioClass = 'verde'
        calidadFolioMensaje = 'Este es un folio de calidad'
        calidadFolioActivado = true
    } else if (props.data.calidadFolio == 2) {
        calidadFolioClass = 'rojo'
        calidadFolioMensaje = 'Este es un folio cerrado'
        calidadFolioActivado = true
    } else if (props.data.calidadFolio == 3) {
        calidadFolioClass = 'naranja'
        calidadFolioMensaje = 'Advertencia'
        calidadFolioActivado = true
    } else {
        calidadFolioClass = 'negro'
        calidadFolioMensaje = ''
        calidadFolioActivado = false
    }

    return (
        <section>
            <div className='flex col-12 md:col-12'>

                {calidadFolioActivado &&
                    <div className='text-center'>
                        <i className={`pi pi-check-circle ${calidadFolioClass}`}></i>
                        <div className={`${calidadFolioClass}Line`}></div>
                    </div>
                }

                <div className={calidadFolioClass}>
                    <h3 className={calidadFolioClass}>Folio {props.data.folio}</h3>
                    <h6 className={`${calidadFolioClass}`}>{calidadFolioMensaje}</h6>
                    {(props.data.consecutivo && props.data.fecha && props.data.hora) &&
                        <>
                            <p className='pt-2 text-center black'>
                                <small className='font-bold'>{`${props.data.title || ''} ${props.data.consecutivo}`}</small>
                                <br />
                                <small>{`${formatDate(props.data.fecha, props.data.hora)}`}</small>
                            </p>
                        </>
                    }
                </div>
            </div>
        </section >
    );

}

export default memo(DetalleFolio);



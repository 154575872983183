import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../../serviceExample/ProductService';
import { Message } from 'primereact/message';
import configuration from '../../../../../configurationFile';
import Solicitador from '../../../../../refactor/PagoFolio/PagoResulFolio/Folio/DatosGenerales/SubComponentes/Solicitador';

interface City {
    name: string;
}

const TabGenerales = (props: any) => {
    const [selectedCity, setSelectedCity] = useState<City | null>(null);
    const [checked, setChecked] = useState<boolean>(false);

    const notario: City[] = [
        { name: 'notario n' },
        { name: 'notario n' },
        { name: 'notario n' },
    ];

    const dt = useRef<any>(null);
    const [products, setProducts] = useState<any>(null);

    useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then(data => setProducts(data));
    }, []);

    const codeBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Tipo material</span>
                {rowData.code}
            </>
        );
    }
    const IconDocumentTemplate = (rowData: any) => {
        return (
            <>
                <div className='text-center'>
                    <i className='pi pi-file' style={{ 'fontSize': '2em' }}></i>
                </div>
            </>
        );
    }

    return (
        <section className=''>
            <div className='grid'>
                <div className='col-12 md:col-4'>
                    <small className='black'>Folio</small>
                    <p className='result'>{props?.propiedadDetail?.prop_folio ? props.propiedadDetail.prop_folio : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Clave catastral</small>
                    <p className='result'>{props?.propiedadDetail?.prop_clave ? props.propiedadDetail.prop_clave : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Antecedentes</small>
                    <p className='result'>{props?.propiedadDetail?.prop_antecedente ? props.propiedadDetail.prop_antecedente : ''}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <h5>Domicilio registral</h5>
                </div>
                <div className='col-12 md:col-12'>
                    <small className='black'>Domicilio registral</small>
                    <p className='result'>{props?.propiedadDetail?.prop_direccion ? props.propiedadDetail.prop_direccion : ''}</p>
                </div>
                <div className='col-12'>
                    <h5>Domicilio convencional</h5>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Codigo postal</small>
                    <p className='result'>{props?.propiedadDetail?.cp_cp ? props.propiedadDetail.cp_cp : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Localidad</small>
                    <p className='result'>{props?.propiedadDetail?.cp_loc ? props.propiedadDetail.cp_loc : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Municipio</small>
                    <p className='result'>{props?.propiedadDetail?.cp_mun ? props.propiedadDetail.cp_mun : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Estado</small>
                    <p className='result'>{props?.propiedadDetail?.cp_edo ? props.propiedadDetail.cp_edo : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Colonia</small>
                    <p className='result'>{props?.propiedadDetail?.cp_col ? props.propiedadDetail.cp_col : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>N°. interior</small>
                    <p className='result'>{props?.propiedadDetail?.prop_int ? props.propiedadDetail.prop_int : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>N°. exterior</small>
                    <p className='result'>{props?.propiedadDetail?.prop_ext ? props.propiedadDetail.prop_ext : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Calle</small>
                    <p className='result'>{props?.propiedadDetail?.prop_calle ? props.propiedadDetail.prop_calle : ''}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12'>
                    <h5>Superficie del predio</h5>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Medidas y Colindancias</small>
                    <p className='result'>{props?.propiedadDetail?.prop_medidas ? props.propiedadDetail.prop_medidas : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Construidas</small>
                    <p className='result'>{props?.propiedadDetail?.prop_constr ? props.propiedadDetail.prop_constr : ''}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small className='black'>Superficie total</small>
                    <p className='result'>{props?.propiedadDetail?.prop_suptotal ? props.propiedadDetail.prop_suptotal : ''}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12 md:col-12'>
                    <Solicitador
                        data={{
                            view: true,
                            solicitador: props?.tramiteDetail?.tramite?.trm_solicitante ? props?.tramiteDetail?.tramite?.trm_solicitante : '',
                            notariaSeleccionada: props?.notaria,
                            catalogoNotarias: props?.notarios,
                            numeroEscritura: props?.tramiteDetail?.tramite?.trm_escritura ? props?.tramiteDetail?.tramite?.trm_escritura : '',
                            titulares: false
                        }}
                        setSolicitador={() => { console.log('') }}
                        setNotariaSeleccionada={() => { console.log('') }}
                        setNumeroEscritura={() => { console.log('') }}
                        seleccionarTitular={() => { console.log('') }}
                    />
                </div>

                {props?.tramiteDetail?.docs && props?.tramiteDetail?.docs?.length > 0 &&
                    <div className='col-12 md:col-12'>
                        <h5>Documentación</h5>
                        <div className='grid' style={{ marginTop: 20, marginBottom: 50 }}>
                            {
                                props.tramiteDetail.docs.map((singleDocument: any) => (
                                    <div className='col-12 md:col-4' >
                                        <Message
                                            style={{
                                                border: 'solid #696cff',
                                                borderWidth: '0 0 0 6px',
                                                color: '#696cff'
                                            }}
                                            className="border-primary w-full justify-content-start"
                                            severity="info"
                                            content={
                                                <div className="flex align-items-center" style={{ justifyContent: 'space-between', width: '100%' }}>
                                                    <div>{singleDocument.trmd_desc}</div>
                                                    <div onClick={() => { window.open(`${configuration.url}${singleDocument.trmd_url}`, '_blank', 'noreferrer') }}>Ver Archivo</div>
                                                </div>
                                            }
                                        />
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                }
            </div>
        </section >
    );

}

export default React.memo(TabGenerales);
import { User } from "../data/user";
import LocalService from "./local.service";

export default class authGuardService {
  static sendUser(user: User) {
    if (user) {
      LocalService.setJsonValue('usuario', user);
    }
  }

  static sendPermits(permisos: any) {
    if (permisos) {
      LocalService.setJsonValue('permisos', permisos);
    }
  }

  static sendKey(key: string) {
    LocalService.setJsonValue("token", key)
  }

  static getPermits() {
    return LocalService.getJsonValue('permisos');
  }

  static getUser() {
    return LocalService.getJsonValue('usuario');
  }
  static getToken() {
    return LocalService.getJsonValue('token');
  }
  static isLoggednIn() {
    return authGuardService.getUser() !== null;
  }
  static logout() {
    return LocalService.clearToken();
  }
}





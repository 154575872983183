import React, { createContext, useState, useEffect } from 'react';
export const TimerContext = createContext();
import LocalService from '../service/local.service';
import authGuardService from '../service/authGuardService';
import eventEmitter from '../actions/timerActions';

export const TimerProvider = ({ children }) => {
    const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft());

    function calculateTimeLeft() {
        const startTime = LocalService.getJsonValue('sessionStartTime');
        if (startTime) {
            const elapsedTime = Math.floor((Date.now() - parseInt(startTime)) / 1000);
            const remainingTime = 3600 - elapsedTime;
            return remainingTime > 0 ? remainingTime : 0;
        }
        return 3600; // Tiempo por defecto si no hay una sesión activa
    }

    const resetTimer = () => {
        const remainingTime = calculateTimeLeft();
        if (remainingTime <= 0) {
            authGuardService.logout()
            window.location.reload();
        }
        const currentTime = Date.now();
        LocalService.setJsonValue('sessionStartTime', currentTime);
        setTimeLeft(3600);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const remainingTime = calculateTimeLeft();
            setTimeLeft(remainingTime);
            if (remainingTime === 0) {
                clearInterval(interval);
                authGuardService.logout()
                window.location.reload();
                // Aquí puedes ejecutar la acción cuando el tiempo se acabe
            }
        }, 1000);

        // Escuchar el evento de reinicio del temporizador
        const handleResetTimer = () => {
            resetTimer();
        };
        eventEmitter.on('resetTimer', handleResetTimer);

        return () => {
            clearInterval(interval);
            eventEmitter.off('resetTimer', handleResetTimer); // Limpia el evento al desmontar
        };
    }, []);

    return (
        <TimerContext.Provider value={{ timeLeft, resetTimer }}>
            {children}
        </TimerContext.Provider>
    );
};


import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Divider } from 'primereact/divider';
import { memo, useRef } from 'react';

interface DatosJson {
    titulares: Array<any>;
}

interface DatosProps {
    data: DatosJson;
}

const Titulares = (props: DatosProps) => {
    const dt = useRef(null);

    const cols = [
        { field: 'ptit_nom', header: 'Nombre' },
        { field: 'ptit_pat', header: 'Primer apellido' },
        { field: 'ptit_mat', header: 'Segundo apellido' },
        { field: 'ptit_rfc', header: 'RFC' },
        { field: 'ptit_nuda', header: 'Nuda %' },
        { field: 'ptit_usufructo', header: 'Usufructo %' }
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportCSV = (selectionOnly: boolean) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc: any = new jsPDF.default('landscape', 'mm', 'a4');
                doc.autoTable(exportColumns, props.data.titulares);
                doc.save('products.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(props.data.titulares);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Titulares');
        });
    };

    const saveAsExcelFile = (buffer: BlobPart, fileName: string) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" rounded icon="pi pi-file" onClick={() => exportCSV(false)} tooltip='Descargar CSV' tooltipOptions={{ position: 'top' }} />
            <Button type="button" rounded icon="pi pi-file-excel" onClick={exportExcel} tooltip='Descargar Excel' tooltipOptions={{ position: 'top' }} />
            <Button type="button" rounded icon="pi pi-file-pdf" onClick={exportPdf} tooltip='Descargar PDF' tooltipOptions={{ position: 'top' }} />
        </div>
    );

    return (
        <section>
            <div className='col-12 md:col-12' style={{ marginTop: 20 }}>
                <h3 className='color-violet'>Titulares</h3>
            </div>
            <Divider />
            <div className='pt-4'>
                <p>{`${props.data.titulares ? props.data.titulares.length : 0} Titulares que son parte de este terreno.`}</p>
                <DataTable
                    ref={dt}
                    value={props.data.titulares}
                    dataKey="ptit_id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    className="datatable-responsive"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} titulares"
                    emptyMessage="No se encontraron titulares."
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    header={header}
                    removableSort
                >
                    {cols.map((col, index) => (
                        <Column key={index} field={col.field} header={col.header} sortable />
                    ))}
                </DataTable>
            </div>

        </section>
    );

}

export default memo(Titulares);
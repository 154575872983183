
import { Toast } from 'primereact/toast';
import { Fragment, memo, SetStateAction, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tab from '../../../Components/Tabs/Tab';
import Tabs from '../../../Components/Tabs/Tabs';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import Actos from '../PagoResulFolio/Folio/Actos/Actos';
import DatosGenerales from '../PagoResulFolio/Folio/DatosGenerales/DatosGenerales';
import MainFooter from '../PagoResulFolio/Folio/Footer/MainFooter';
import MainHeader from '../PagoResulFolio/Folio/Header/MainHeader';
import Titulares from '../PagoResulFolio/Folio/Titulares/Titulares';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Password } from 'primereact/password';
import { Messages } from 'primereact/messages';

const EditarPreTramite = (props: any) => {

    /* Temporal */
    function obtenerFechaYHoraActualEnFormato(fecha: string | number | Date, tiempo: string | number | Date) {
        const mesesEnEspanol = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        // Parsear las fechas y horas del data
        const fechaActual = new Date(fecha);
        const horaActual = new Date(tiempo);

        if (fechaActual || horaActual) {
            const dia = fechaActual.getDate();
            const mes = mesesEnEspanol[fechaActual.getMonth()];
            const anio = fechaActual.getFullYear();
            let hora = horaActual.getUTCHours();
            const minutos = horaActual.getUTCMinutes();
            const segundos = horaActual.getUTCSeconds();
            let periodo = "am";

            if (hora >= 12) {
                periodo = "pm";
                if (hora > 12) {
                    hora -= 12;
                }
            }
            if (hora === 0) {
                hora = 12;
            }

            const minutosFormateados = minutos < 10 ? "0" + minutos : minutos;
            const segundosFormateados = segundos < 10 ? "0" + segundos : segundos;

            const fechaYHoraFormateada = `${dia + 1} ${mes} ${anio} a las ${hora}:${minutosFormateados}:${segundosFormateados} ${periodo}`;
            return fechaYHoraFormateada;
        } else {
            return ''
        }
    }
    function formatFecha(fecha: any) {
        if (fecha) {
            const fechaObj = new Date(fecha);
            fechaObj.setUTCHours(0, 0, 0, 0);
            const año = fechaObj.getUTCFullYear();
            const mes = (fechaObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const dia = fechaObj.getUTCDate().toString().padStart(2, '0');
            return `${dia}/${mes}/${año}`;
        } else {
            return ''
        }
    }
    /*  */

    const navigate = useNavigate();
    const user = authGuardService.getUser()

    const toast = useRef<any>(null);
    const mensaje = useRef<any>(null);

    const pagosAnteriores = props.pagosAnteriores
    const detallePreTramite = props.detallePreTramite

    const [activeIndex, setActiveIndex] = useState(0)
    const [id, setId] = useState<string>('0') // ID del PreTramite o Tramite (Solo se usa una vez creado)
    const [tramiteConsecutivo, setTramiteConsecutivo] = useState<string>('0') // Tramite Consecutivo (Solo se usa una vez creado)
    const [fechaTramite, setFechaTramite] = useState<Date>() // Fecha del Tramite (Solo se usa una vez creado)
    const [horaTramite, setHoraTramite] = useState<Date>() // Hora del tramite (Solo se usa una vez creado)
    const [folio, setFolio] = useState('0') // Folio de la propiedad
    const [propiedadId, setPropiedadId] = useState<string>('0') // ID de la propiedad
    const [calidadFolio, setCalidadFolio] = useState(0) // Calidad del Folio
    const [listadoNotarios, setListadoNotarios] = useState<any>([]) // Listado de Notarios
    const [numeroDeEscritura, setNumeroDeEscritura] = useState<string>('') // Numero de Escritura
    const [notariaSeleccionada, setNotariaSeleccionada] = useState<any>(null) // Notaria Seleccionada
    const [nombreSolicitante, setNombreSolicitante] = useState<string>('') // Nombre del Solicitante
    const [titulares, setTitulares] = useState([])
    const [titularesTamaño, setTitularesTamaño] = useState(0)
    const [dataGeneral, setDataGeneral] = useState<any>({
        folio: '',
        claveCatastral: '',
        antecedentes: '',
        domicilioRegistral: '',
        codigoPostal: '',
        localidad: '',
        municipio: '',
        estado: '',
        colonia: '',
        numeroExterior: '',
        numeroInterior: '',
        calle: '',
        medidasColindancias: '',
        construidas: '',
        superificieTotal: ''
    })
    const [detallePropiedad, setDetallePropiedad] = useState()
    const [actos, setActos] = useState([])
    const [documentos, setDocumentos] = useState([])
    const [hijos, setHijos] = useState([])
    const [firmaTemporal, setFirmaTemporal] = useState('')
    const [motivoRechazo, setMotivoRechazo] = useState('')
    const [modalRechazar, setModalRechazar] = useState(false);
    const [modalRechazado, setModalRechazado] = useState(false)
    const [modalMandarNotario, setModalMandarNotario] = useState(false)
    const [guardadoPreTramiteResponse, setGuardadoPreTramiteResponse] = useState<any>()
    const [sentModal, setSentModal] = useState<boolean>(false);
    const [tramite, setTramite] = useState<any>()
    const [modalFirma, setModalFirma] = useState(false)
    const [folioDocumento, setFolioDocumento] = useState<File>();
    const [folioDocumentoNombre, setFolioDocumentoNombre] = useState('')
    const [view, setView] = useState(false)
    const [total, setTotal] = useState(0.00)
    const [pagosInternos, setPagosInterno] = useState([])

    const seleccionarTitular = () => {
        setNombreSolicitante(titulares ? `${titulares[titularesTamaño].ptit_nom} ${titulares[titularesTamaño].ptit_pat} ${titulares[titularesTamaño].ptit_mat}` : nombreSolicitante)
        const lenghtSize = titulares ? titulares.length : 0
        const selectedIndex = (titularesTamaño + 1) % lenghtSize;
        setTitularesTamaño(selectedIndex)
    }

    const buscarNotaria = (not_id = user.not_id) => {
        if (not_id && not_id != 0)
            for (let notario of props.listadoNotarios) {
                if (notario.not_id == not_id) {
                    setNotariaSeleccionada(notario)
                }
            }
    }

    const searchPropiedadPropId = (id: any) => {
        let datos = { prop_id: id }

        return ApiServices.apiPost('getBuscaPropiedad', datos).then(async res => {
            let datos = {
                prop_folio: res.data.addenda[0].prop_folio,
                prop_online: 0
            }
            return ApiServices.apiPost('getBuscaFolio', datos).then(async res => {
                return res.data.addenda
            }).catch(error => {
                console.log(error)
            })
        }).catch(error => {
            console.log(error)
        })
    }

    const encontrarHijos = async (datos: string | any[]) => {
        if (datos && datos.length > 0) {
            const array = []
            for (const element of datos) {
                const result = await searchPropiedadPropId(element.prop_id)
                let final = element
                if (result && result.length > 0) {
                    result[0].more = element
                    final = result[0]
                }

                array.push(final)
            }
            setHijos(array)
        }
    }

    const handleProps = () => {
        if (props.detallePropiedad) {
            setDetallePropiedad(props.detallePropiedad)
            setFolio(props.detallePropiedad.prop_folio)
            setPropiedadId(props.detallePropiedad.prop_id)
            setCalidadFolio(props.detallePropiedad.prop_calidad)
            setDataGeneral({
                folio: props.detallePropiedad.prop_folio || '',
                claveCatastral: props.detallePropiedad.prop_clave || '',
                antecedentes: props.detallePropiedad.prop_antecedente || '',
                domicilioRegistral: props.detallePropiedad.prop_direccion || '',
                calle: props.detallePropiedad.prop_calle || '',
                numeroExterior: props.detallePropiedad.prop_ext || '',
                numeroInterior: props.detallePropiedad.prop_int || '',
                colonia: props.detallePropiedad.cp_col || '',
                municipio: props.detallePropiedad.cp_mun || '',
                estado: props.detallePropiedad.cp_edo || '',
                localidad: props.detallePropiedad.cp_loc || '',
                codigoPostal: props.detallePropiedad.cp_cp || '',
                indiviso: props.detallePropiedad.prop_indiviso || '',
                usoSuelo: props.detallePropiedad.prop_ususuelo || '',
                superificieTotal: props.detallePropiedad.prop_suptotal || '',
                restante: props.detallePropiedad.prop_suprestante || '',
                construidas: props.detallePropiedad.prop_constr || '',
                medidasColindancias: props.detallePropiedad.prop_medidas || '',
            })
        }
        if (props.titulares) setTitulares(props.titulares)
        if (props.listadoNotarios) setListadoNotarios(props.listadoNotarios)
        if (props.detallePreTramite) {
            setId(props.detallePreTramite.tramite.ptrm_id)
            setNumeroDeEscritura(props.detallePreTramite.tramite.ptrm_escritura)
            setNombreSolicitante(props.detallePreTramite.tramite.ptrm_solicitante)
            setTramiteConsecutivo(props.detallePreTramite.tramite.ptrm_consecutivo)
            setFechaTramite(props.detallePreTramite.tramite.ptrm_fecha)
            setHoraTramite(props.detallePreTramite.tramite.ptrm_hora)
            setDocumentos(props.detallePreTramite.docs)
            setPagosInterno(props.detallePreTramite.pagos)
            setView(props.detallePreTramite.tramite.ptrm_status == 2 && user.prf_id != 8)

            buscarNotaria(props.detallePreTramite.tramite.not_id)
            encontrarHijos(props.detallePreTramite.hijos)
            handleActos(props.detallePreTramite.tramite.ptrm_id)

            if (props.detallePreTramite.tramite.ptrm_nota) {
                mensaje.current.show(
                    {
                        severity: 'error',
                        sticky: true,
                        closable: false,
                        content: (
                            <Fragment>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                                        <i className="pi pi-ban" style={{ fontSize: '1.5rem', marginRight: 10 }}></i>
                                        <p><b>Pre-Tramite Rechazado</b></p>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                                        <i className="pi pi-minus" style={{ fontSize: '0.5rem', marginRight: 10 }}></i>
                                        <p><b>Motivo:</b> {props.detallePreTramite.tramite.ptrm_nota}</p>
                                    </div>
                                </div>
                            </Fragment>
                        )
                    }
                );
            }
        }
    }

    const obtenerIdsPagos = () => {
        if (pagosAnteriores && pagosAnteriores.length > 0) {
            const ids = pagosAnteriores.map((element: any) => {
                return element.pago_id
            })
            return ids
        } else if (pagosInternos && pagosInternos.length > 0) {
            const ids = pagosInternos.map((element: any) => {
                return element.pago_id
            })
            return ids
        } else {
            return []
        }
    }

    const obtenerActos = () => {
        if (actos && actos.length > 0) {
            const ids = actos.map((element: any) => {
                return element.act_id
            })
            return ids
        }
    }

    const getHijosArray = () => {
        if (hijos && hijos.length > 0) {
            const final = hijos.map((singleFolio: any) => {
                if (singleFolio && singleFolio.more && singleFolio.more.ptrm_id)
                    return null
                else
                    return singleFolio.prop_id
            })
            return final.filter(element => element !== null);
        } else
            return []
    }

    const handleSavePreTramite2 = async (showToast = true) => {
        const actos = obtenerActos()
        const pagos = obtenerIdsPagos()
        const hijos = getHijosArray()

        let datos: any = {
            code: 306,
            ptrm_id: id,
            prop_id: props.detallePropiedad.prop_id,
            ptrm_solicitante: nombreSolicitante,
            not_id: notariaSeleccionada ? notariaSeleccionada.not_id : user.not_id,
            ptrm_escritura: numeroDeEscritura,
            actos: actos || [],
            pagos: pagos || [],
            hijos: hijos || [],
            usu_id: user.usu_id
        }

        datos = EncryptService.encryptData(JSON.stringify(datos));
        await ApiServices.processRequestAdd(datos)
    }

    const handleSavePreTramite = (showToast = true) => {
        const actos = obtenerActos()
        const pagos = obtenerIdsPagos()
        const hijos = getHijosArray()

        let datos: any = {
            code: 306,
            ptrm_id: id,
            prop_id: props.detallePropiedad.prop_id,
            ptrm_solicitante: nombreSolicitante,
            not_id: notariaSeleccionada ? notariaSeleccionada.not_id : user.not_id,
            ptrm_escritura: numeroDeEscritura,
            actos: actos || [],
            pagos: pagos || [],
            hijos: hijos || [],
            usu_id: user.usu_id
        }

        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido) {
                setGuardadoPreTramiteResponse(res.data.addenda)
                showToast &&
                    toast.current.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Tu Borrador se ha guardado con exito', life: 3000 });
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const refreshInformation = () => {
        props.getDetallePreTramite(id)
    }

    const handleChangePreTramiteStatus = async (status: number) => {

        await handleSavePreTramite(false)

        let datos = {}
        let bloqueos = 0
        await ApiServices.apiPost('bloqueoActivo', datos).then((res: any) => {
            bloqueos = res.data.addenda[0].bloqueos
        })

        if (bloqueos == 1) {
            toast.current.show({
                severity: 'error',
                ƒsummary: 'Sistema Bloqueado',
                detail: 'Por el momento no puedes mandar tramites al instituto',
                life: 3000
            });
        } else {
            let datos: any = {
                code: 304,
                ptrm_id: id,
                usu_id: user.usu_id,
                ptrm_status: status,
                ptrm_nota: motivoRechazo || '',
                usu_efirma_password: user.usu_efirma_password || EncryptService.encryptData(firmaTemporal)
            }

            if (status === 3) {
                setModalRechazar(false)
                datos.ptrm_nota = motivoRechazo;
            }

            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido) {
                    if (status === 3) {
                        setModalRechazado(true)
                    }
                    if (status === 2) {
                        if (user.prf_id !== 8) {
                            setTramite(res.data.addenda)
                            setModalMandarNotario(true)
                        }
                    } else if (status === 5) {
                        setTramite(res.data.addenda)
                        setSentModal(true)
                    }
                } else {
                    toast.current.show({ severity: 'error', summary: res.data.mensaje, life: 3000 });
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const EFirmafooterContent = () => (
        <div className=''>
            <Button
                label="Cancelar"
                onClick={() => setModalFirma(false)}
                severity="secondary"
                outlined
            />
            <Button label="Firmar" onClick={handleCheckNewFirma} autoFocus />
        </div>
    );

    const handleCheckNewFirma = () => {
        if (firmaTemporal && firmaTemporal.length > 0) {
            handleChangePreTramiteStatus(5)
        } else {
            toast.current.show({ severity: 'error', summary: 'Ingresa tu contraseña', detail: 'Ingresa tu contraseña para firmar', life: 3000 });
        }
        setModalFirma(false)
    }

    const handleCheckFirma = (id: any) => {
        if (user && user.usu_efirma_password) {
            if (user.usu_efirma_password != '') {
                handleChangePreTramiteStatus(5)
            } else {
                setModalFirma(true)
            }
        } else {
            setModalFirma(true)
        }
    }

    const uploadDocumento = () => {
        if (folioDocumentoNombre && folioDocumento) {
            const data = {
                "ptrm_id": id,
                "ptrmd_desc": folioDocumentoNombre || folioDocumento.name,
                "ptrmd_url": "media/doc/"
            }

            ApiServices.apiPostFile('addDocPre', folioDocumento, data).then(async res => {
                if (res.data.valido === 1) {
                    toast.current.show({ severity: 'success', summary: 'Archivo cargado', detail: 'Archivo Cargado con Exito', life: 3000 });

                    refreshInformation()
                    setFolioDocumento(null)
                    setFolioDocumentoNombre('')
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            if (!folioDocumento)
                toast.current.show({ severity: 'error', summary: 'Subir Archivo', detail: 'Debes de seleccionar un archivo para subir', life: 3000 });
            else
                toast.current.show({ severity: 'error', summary: 'Nombre del Archivo', detail: 'Debes ingresar un nombre al archivo', life: 3000 });
        }
    }

    const deleteDocumento = (documento: { ptrm_id: string; ptrmd_id: string; }) => {
        const data = {
            "ptrm_id": documento.ptrm_id,
            "ptrmd_id": documento.ptrmd_id,
        }

        ApiServices.apiPost('deleteDocPre', data).then(async res => {
            if (res.data.valido === 1) {
                refreshInformation()
                toast.current.show({ severity: 'success', summary: 'Archivo eliminado', detail: 'Archivo Eliminado con Exito', life: 3000 });
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const matchSavedActos = (newActos: any) => {
        let newArray = []
        if (newActos && newActos.length > 0) {
            let total = 0;
            const matchedActos = newActos.map((singleActo: any) => {
                const match = props.listadoActos.find((item: any) => item.act_id == singleActo.act_id)
                if (match) {
                    singleActo.act_pesos = match.valormx
                    singleActo.valormx = match.valormx

                    if (match.valormx) {
                        total = total + parseFloat(match.valormx)
                    }
                }
                return singleActo
            })

            if (actos && actos.length > 0) {
                const mapArray2 = new Map(matchedActos.map((obj: any) => [obj.act_id, obj]));
                const mergedArray = props.actos.map((obj1: any) => {
                    const obj2 = mapArray2.get(parseInt(obj1.act_id));
                    return obj2 && typeof obj1 === 'object' && typeof obj2 === 'object' ? { ...obj1, ...obj2 } : obj1;
                });
                newArray = mergedArray;
            }
            else
                newArray = matchedActos

            setActos(newArray)
        }
    }

    const rematchI39 = (array) => {
        let ci1 = null;
        let i39 = null;

        array.forEach(item => {
            if (item.act_clave === "CI1") {
                ci1 = JSON.parse(JSON.stringify(item));;
            }
            if (item.act_clave === "I39") {
                i39 = JSON.parse(JSON.stringify(item));
            }
        });

        if (ci1 && i39) {
            ci1.valormx = i39.valormx;
            i39.valormx = 0;
        }
        const totalActPesos = array.reduce((sum, item) => sum + item.valormx, 0);

        setActos(array)
    }

    const handleActos = async (idPreTramite) => {
        if (idPreTramite) {
            let datos = { ptrm_id: idPreTramite, trm_id: 0 }

            await ApiServices.apiPost('getFormatosResp', datos).then(async res => {
                if (res.data.valido === 1) {
                    if (res.data.addenda && res.data.addenda.length > 0) {
                        matchSavedActos(res.data.addenda)
                    } else {
                        if (props && props.detallePreTramite && props.detallePreTramite.actos && props.detallePreTramite.actos.length > 0) {
                            const result = [];
                            let total = 0.00

                            props && props.detallePreTramite && props.detallePreTramite.actos.forEach(item2 => {
                                const match = props.listadoActos.find(item1 => item1.act_id === item2.acto_id);
                                if (match) {
                                    total = total + match.valormx
                                    result.push({
                                        ...match,
                                        ...item2
                                    });
                                }
                            });
                            rematchI39(result)
                        } else {
                            console.log('No hay Actos Previos')
                        }
                    }
                } else {
                    console.log('No Valido')
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    useEffect(() => {
        if (props)
            handleProps()
    }, [props])

    return (
        <section>
            <Toast ref={toast} />

            <div className='container-fluid'>
                <MainHeader
                    data={{
                        folio: folio,
                        consecutivo: tramiteConsecutivo,
                        fecha: fechaTramite,
                        hora: horaTramite,
                        calidadFolio: calidadFolio,
                        lineaDelTiempo: null,
                        title: 'Control Interno Notaria - '
                    }}
                />

                <Messages ref={mensaje} />

                <div className=''>
                    <Tabs activeIndex={activeIndex} onTabChange={(e: { index: SetStateAction<number>; }) => setActiveIndex(e.index)}>
                        <Tab label="Datos generales">
                            <DatosGenerales
                                data={{
                                    id: id,
                                    view: view,
                                    catalogoNotarias: listadoNotarios,
                                    numeroEscritura: numeroDeEscritura,
                                    notariaSeleccionada: notariaSeleccionada,
                                    solicitador: nombreSolicitante,
                                    documentos: documentos,
                                    general: dataGeneral,
                                    titulares: titulares && titulares.length > 0,
                                    folioDocumento: folioDocumento,
                                    folioDocumentoNombre: folioDocumentoNombre
                                }}
                                refreshInformation={refreshInformation} // DELETE
                                setSolicitador={setNombreSolicitante}
                                setNotariaSeleccionada={setNotariaSeleccionada}
                                setNumeroEscritura={setNumeroDeEscritura}
                                seleccionarTitular={seleccionarTitular}
                                setFolioDocumento={setFolioDocumento}
                                setFolioDocumentoNombre={setFolioDocumentoNombre}
                                uploadDocumento={uploadDocumento}
                                deleteDocumento={deleteDocumento}
                                docsIDs={["ptrmd_id", "ptrmd_desc"]}
                            />
                        </Tab>
                        <Tab label="Actos">
                            <Actos
                                id={id}
                                view={view}
                                actos={actos}
                                setActos={setActos}
                                detallePropiedad={detallePropiedad}
                                notariaSeleccionada={notariaSeleccionada}
                                listadoActos={props && props.listadoActos}
                                listadoActosCategorias={props && props.listadoActosFiltrados}
                                detallePreTramite={props && props.detallePreTramite && props.detallePreTramite.tramite}
                                actosPrevios={props && props.detallePreTramite && props.detallePreTramite.actos}
                                pagosPrevios={props && props.detallePreTramite && props.detallePreTramite.pagos}
                                historial={props && props.historial}
                                hijos={hijos}
                                setHijos={setHijos}
                                handleSavePreTramite={handleSavePreTramite2}
                                handleActoID={{ ptrm_id: id, trm_id: 0 }}
                                reordenarActosID={{ code: 307, ptrm_id: id }}
                                consecutivo={'ptrm_consecutivo'}
                            />
                        </Tab>
                        <Tab label="Titulares">
                            <Titulares data={{ titulares: titulares }} />
                        </Tab>
                    </Tabs>
                </div>
            </div>

            {!view &&
                <>
                    {
                        user.prf_id === 8 && id && id === user.usu_id &&
                        <MainFooter data={{
                            buttons:
                                [
                                    {
                                        label: "Guardar Borrador",
                                        outlined: true,
                                        function: (handleSavePreTramite)
                                    },
                                    {
                                        label: "Enviar al Instituto",
                                        outlined: false,
                                        function: (() => { handleCheckFirma(5) })
                                    }
                                ]
                        }} />
                    }
                    {
                        user.prf_id === 8 && id && id !== user.usu_id &&
                        <MainFooter data={{
                            buttons:
                                [
                                    {
                                        label: "Guardar Borrador",
                                        outlined: true,
                                        function: (handleSavePreTramite)
                                    },
                                    {
                                        label: "Rechazar",
                                        outlined: true,
                                        function: (() => { setModalRechazar(true) })
                                    },
                                    {
                                        label: "Enviar al Instituto",
                                        outlined: false,
                                        function: (() => { handleCheckFirma(5) })
                                    }
                                ]
                        }} />
                    }
                    {
                        user.prf_id === 9 &&
                        <MainFooter data={{
                            buttons:
                                [
                                    {
                                        label: "Guardar Borrador",
                                        outlined: true,
                                        function: (handleSavePreTramite)
                                    },
                                    {
                                        label: "Enviar al Revisión",
                                        outlined: false,
                                        function: (() => { handleChangePreTramiteStatus(2) })
                                    }
                                ]
                        }} />
                    }
                </>
            }

            {/* Modal Rechazar  */}
            <Dialog
                header="Rechazar"
                visible={modalRechazar}
                style={{ width: '40vw' }}
                onHide={() => setModalRechazar(false)}
                footer={
                    <div>
                        <Button
                            label="Cancelar"
                            onClick={() => setModalRechazar(false)}
                            severity="secondary"
                            outlined
                        />
                        <Button
                            label="Enviar"
                            onClick={() => handleChangePreTramiteStatus(3)}
                        />
                    </div>
                }
            >
                <div className='grid'>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputTextarea
                                rows={5}
                                cols={30}
                                value={motivoRechazo}
                                onChange={(e) => setMotivoRechazo(e.target.value)}
                            />
                            <label htmlFor="rechazo">Motivo del rechazo</label>
                        </span>
                    </div>
                </div>
            </Dialog>

            {/* Modal Rechazado */}
            <Dialog
                header="Se mandó el rechazo a tu trabajador"
                visible={modalRechazado}
                style={{ width: '40vw' }}
                onHide={() => {
                    setModalRechazado(false)
                    navigate(-1)
                }}
                footer={
                    <div>
                        <Button label="Cerrar" onClick={() => {
                            setModalRechazado(false)
                            navigate(-1)
                        }} />
                    </div>
                }>
                <div className='text-center'>
                    <i className='pi pi-thumbs-up mb-5' style={{ 'fontSize': '5em' }}></i>
                    <div className='my-5'>
                        <p>Se envió el trámite rechazado al trabajador para su revisión</p>
                    </div>
                </div>
            </Dialog>

            {/* Dialog Enviado a Notario */}
            <Dialog
                header="Tu solicitud ha sido enviada"
                visible={modalMandarNotario}
                style={{ width: '40vw' }}
                onHide={() => {
                    setModalMandarNotario(false)
                    navigate('/')
                }}
                footer={
                    <div>
                        <Button
                            label="Cerrar"
                            onClick={() => {
                                setModalMandarNotario(false)
                                navigate('/')
                            }}
                            autoFocus
                        />
                    </div>
                }
            >
                <div className='text-center'>
                    <i className='pi pi-thumbs-up mb-5' style={{ 'fontSize': '5em' }}></i>
                    <div className='my-5'>
                        <p>Se envió tu solicitud <h2>{tramiteConsecutivo}</h2> al notario para su revisión</p>
                    </div>
                    <div>
                        <p className='m-0'>{
                            guardadoPreTramiteResponse &&
                            guardadoPreTramiteResponse.ptrm_fecha &&
                            guardadoPreTramiteResponse.ptrm_hora &&
                            obtenerFechaYHoraActualEnFormato(guardadoPreTramiteResponse.ptrm_fecha, guardadoPreTramiteResponse.ptrm_hora)
                        }</p>

                        <small>Fecha y hora de registro</small>
                    </div>
                </div>
            </Dialog>

            {/* Dialog Enviado al Instituto */}
            <Dialog
                header="Tu solicitud ha sido enviada"
                visible={sentModal}
                style={{ width: '30vw' }}
                onHide={() => {
                    setSentModal(false)
                    navigate('/')
                }}
                footer={
                    <div>
                        <Button
                            label="Cerrar"
                            onClick={() => {
                                setSentModal(false)
                                navigate('/')
                            }}
                            autoFocus
                        />
                    </div>
                }
            >
                <div className='text-center'>
                    <i className='pi pi-thumbs-up mb-5' style={{ 'fontSize': '5em' }}></i>
                    <div className='my-5'>
                        <h2>{tramite ? `${tramite.trm_consecutivo} - ${formatFecha(tramite.trm_fecha)}` : ''}</h2>
                        <small>Número control interno ISRyC</small>
                    </div>
                    <div>
                        <p className='m-0'>{
                            tramite &&
                            tramite.trm_fecha &&
                            tramite.trm_hora &&
                            obtenerFechaYHoraActualEnFormato(tramite.trm_fecha, tramite.trm_hora)}</p>
                        <small>Fecha y hora de registro</small>
                    </div>
                </div>
            </Dialog>

            {/* Modal EFirma */}
            <Dialog
                header="E-Firma"
                visible={modalFirma}
                maximizable
                style={{ width: '40vw' }}
                onHide={() => setModalFirma(false)}
                footer={EFirmafooterContent}
            >
                <p>Al ingresar la E-firma, estas autorizando que los certificados seleccionados sean firmados.</p>
                <div className='grid'>
                    <div className='col-12'>
                        <span className="p-float-label">
                            <Password
                                id="password"
                                inputId='password'
                                className={`p-inputtext-lg`}
                                onChange={(e) => { setFirmaTemporal(e.target.value) }}
                                toggleMask
                                feedback={false}
                                style={{ width: '100%' }}
                            />
                            <label htmlFor="password">Contraseña de E-Firma</label>
                        </span>
                    </div>
                </div>
            </Dialog>

        </section >
    );
}

export default memo(EditarPreTramite);



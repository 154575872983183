import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import configuration from '../../../configurationFile';
import formatDate from '../../../utilities/formatDate';
import { TieredMenu } from 'primereact/tieredmenu';

const NotHistorial = () => {

    const user = authGuardService.getUser()
    const navigate = useNavigate();
    const status = [0, 1, 2, 3, 4, 5]
    const toast = useRef<Toast>(null);

    const [globalFilter, setGlobalFilter] = useState<any>(null);

    const statusBodyTemplate = (tramite: any) => {
        const statusType = getStatusType(tramite.trm_status)
        return <Tag value={statusType} severity={getSeverity(tramite.trm_status)}></Tag>;
    };

    const controlInternoTemplate = (item: any) => {
        return (
            <p>{`${formatDate(item.trm_fecha)}`}</p>
        )
    };

    const getStatusType = (ptrm_status: any) => {
        switch (ptrm_status) {
            case 0:
                return 'Borrador';
            case 1:
                return 'Capturado';
            case 2:
                return 'Asignado';
            case 3:
                return 'Analizado';
            case 4:
                return 'Rechazado';
            case 5:
                return 'Por Subsanar';
            case 6:
                return 'Subsanado';
            case 7:
                return 'Por Firmar Aceptado';
            case 8:
                return 'Por Firmar Rechazado';
            case 9:
                return 'Firmado Aceptado';
            case 10:
                return 'Firmado Rechazado';
            default:
                return null;
        }
    };

    const getSeverity = (ptrm_status: any) => {
        switch (ptrm_status) {
            case 0:
                return 'danger'
            case 1:
                return 'info'
            case 2:
                return 'warning';
            case 3:
                return 'danger';
            case 4:
                return 'warning';
            case 5:
                return 'success';
            case 6:
                return 'info'
            case 7:
                return 'warning';
            case 8:
                return 'danger';
            case 9:
                return 'success';
            case 10:
                return 'success';
            default:
                return null;
        }
    };

    const header = (
        <div className="grid">
            <div className='col-12 md:col-8'>
                <h3 className="m-0">Historial</h3>
                <p>Historial de solicitudes </p>
            </div>
            <div className='col-12 md:col-4'>
                <span className="p-float-label p-input-icon-left w-100">
                    <i className="pi pi-search" />
                    <InputText
                        className='p-inputtext-lg'
                        type="search"
                        onChange={(e) => {
                            setGlobalFilter(e.target.value)
                        }}
                    />
                    <label htmlFor="username">Buscador general</label>
                </span>
            </div>
        </div>
    );

    const SavePDFfolio = async (trm: any) => {
        let datos
        datos = { "code": 701, area_id: user.area_id, trm_id: trm.trm_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.url[0].valido == 0) {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error', life: 3000 });
                } else {
                    if (typeof res.data.url === 'object') {
                        if (res.data.url && res.data.url.length > 0) {
                            res.data.url.forEach((single: any) => {
                                window.open(`${configuration.url}${single.url}`, '_blank');
                            });
                        }
                    } else {
                        window.open(`${configuration.url}${res.data.url}`, '_blank')
                    }
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const downloadPDFIngreso = async (id: any) => {
        let datos
        datos = { "code": 213, "trm_id": id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.valido == 0) {
                } else {
                    window.open(`${configuration.url}${res.data.addenda}`)
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }


    const actionBodyTemplate = (rowData: any) => {
        const menu = useRef<any>()

        const navigateOptions = {
            state: {
                tramite: rowData
            }
        };

        if (rowData.trm_status == 2 || rowData.trm_status == 0) {
            return (
                <div className="actions">
                    <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => { navigate('/notarios/pago-folio-view', navigateOptions) }} />
                </div>
            );
        }
        else if (rowData.trm_status == 7 || rowData.trm_status == 8 || rowData.trm_status == 9 || rowData.trm_status == 10) {
            return (
                <div className="actions">
                    <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => { navigate('/notarios/pago-folio-view', navigateOptions) }} />
                    <TieredMenu model={[
                        {
                            label: 'Descargar Boleta de Ingreso',
                            icon: 'pi pi-file-export',
                            command: () => {
                                downloadPDFIngreso(rowData.trm_id)
                            }
                        },
                        {
                            label: 'Descargar Documento',
                            icon: 'pi pi-file-export',
                            disabled: !(rowData && rowData.trm_status == 7 || rowData.trm_status == 8 || rowData.trm_status == 9 || rowData.trm_status == 10),
                            command: () => {
                                SavePDFfolio(rowData)
                            }
                        }
                    ]} popup ref={menu} breakpoint="767px" />
                    <Button icon="pi pi-ellipsis-h" rounded text severity="secondary" onClick={(e) => menu.current.toggle(e)} />
                </div>
            );
        } else {
            return (
                <div className="actions">
                    <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => { navigate('/notarios/pago-folio', navigateOptions) }} />
                </div>
            );
        }

    }

    const [allTramites, setAllTramites] = useState([])
    const [allTramitesLoading, setAllTramitesLoading] = useState(true)
    const [notarioWorkers, setNotariosWorkers] = useState<any>()

    useEffect(() => {
        getNotariosWorkers()
    }, []);

    useEffect(() => {
        if (notarioWorkers) {
            getTramites()
        }
    }, [notarioWorkers])

    const getTramites = async () => {
        if (user.prf_id === 9) {
            let tramites: any = []
            let datos
            datos = {
                code: 207,
                usu_id: user.usu_id,
                texto: "",
                fecha: ""
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            await ApiServices.processRequestAdd(datos).then(async res => {
                if (res && res.data && res.data.addenda && res.data.addenda.length > 0) {
                    res.data.addenda.map((singleTramite: any) => {
                        tramites.push(singleTramite)
                    })
                }
            }).catch(error => {
                console.log(error)
            })
            const statusPermitidos = [7, 8, 9, 10];
            const newTramites = tramites.filter((item: any) => statusPermitidos.includes(item.trm_status));

            setAllTramites(newTramites)
            setAllTramitesLoading(false)
        } else if (user.prf_id === 8) {
            let tramites: any = []
            if (notarioWorkers && notarioWorkers.length > 0) {
                for (const singleNotarioWorker of notarioWorkers as any) {
                    let datos
                    datos = {
                        code: 207,
                        usu_id: singleNotarioWorker.usu_id,
                        texto: "",
                        fecha: ""
                    }
                    datos = EncryptService.encryptData(JSON.stringify(datos));
                    await ApiServices.processRequestAdd(datos).then(async res => {
                        if (res && res.data && res.data.addenda && res.data.addenda.length > 0) {
                            res.data.addenda.map((singleTramite: any) => {
                                singleTramite.worker = singleNotarioWorker
                                tramites.push(singleTramite)
                            })
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            }

            let datos
            datos = {
                code: 207,
                usu_id: user.usu_id,
                texto: "",
                fecha: ""
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            await ApiServices.processRequestAdd(datos).then(async res => {
                if (res && res.data && res.data.addenda && res.data.addenda.length > 0) {
                    res.data.addenda.map((singleTramite: any) => {
                        singleTramite.worker = user
                        tramites.push(singleTramite)
                    })
                }
            }).catch(error => {
                console.log(error)
            })

            const statusPermitidos = [7, 8, 9, 10];
            const newTramites = tramites.filter((item: any) => statusPermitidos.includes(item.trm_status));

            setAllTramites(newTramites)
            setAllTramitesLoading(false)
        }
    }

    const getNotariosWorkers = () => {
        const usu = authGuardService.getUser()
        let datos = { "not_id": usu.not_id }
        ApiServices.apiPost('getUsuarioNotario', datos).then(async resp => {
            setNotariosWorkers(resp.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }


    return (
        <section className='container-fluid'>
            <Toast ref={toast} />

            <DataTable
                value={allTramites}
                dataKey="ptrm_id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} solicitudes"
                globalFilter={globalFilter}
                emptyMessage="No se encontraron trámites."
                header={header}
                loading={allTramitesLoading}
            >
                <Column sortable field="trm_consecutivo" header="Control Interno ISRyC"></Column>
                <Column sortable field="trm_id" header="Fecha" body={controlInternoTemplate}></Column>
                <Column sortable field="trm_escritura" header="Folio electrónico"></Column>
                <Column sortable field="prop_clave" header="Clave catastral"></Column>
                <Column sortable field="actos" header="Actos"></Column>
                <Column sortable field="trm_status" header="Estatus" body={statusBodyTemplate}></Column>
                <Column body={actionBodyTemplate} style={{ width: '6rem' }}></Column>
            </DataTable>
        </section >
    );
}

export default React.memo(NotHistorial);
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Menu } from 'primereact/menu';
import ProductService from '../../../../../../serviceExample/ProductService';
import IntHistorialActo from './HistorialActo';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import ApiServices from '../../../../../../service/apiServices';
import DynamicForm from '../../../../../actos/DynamicForm';
import authGuardService from '../../../../../../service/authGuardService';
import EncryptService from '../../../../../../service/encrypt.service';
import DynamicFormView from '../../../../../actos/DynamicFormView';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import configuration from '../../../../../../configurationFile';
import { Divider } from 'primereact/divider';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import Historial from '../../../../../../refactor/PagoFolio/Historial/Historial';
import Titulares from '../../../../../../refactor/PagoFolio/PagoResulFolio/Folio/Titulares/Titulares';
import { Accordion, AccordionTab } from 'primereact/accordion';

interface City {
    name: string;
}

const TabActos = (props: any) => {
    const user = authGuardService.getUser()

    const menu = useRef<any>(null);
    const buttonEl = useRef<any>(null);

    const [selected, setSelected] = useState<any>('')
    const [selectedInside, setSelectedInside] = useState<any>('')

    const [tramiteSelected, setTramiteSelected] = useState<any>('')
    const [tramiteSelectedInside, setTramiteSelectedInside] = useState<any>('')

    const [dynamicFormInformation, setDynamicFormInformation] = useState<any>()
    const [dynamicInformationParent, setDynamicInformationParent] = useState<any>()
    const [dynamicFormInformationInside, setDynamicFormInformationInside] = useState()
    const [dynamicInformationParentInside, setDynamicInformationParentInside] = useState<any>()

    const [visible, setVisible] = useState<boolean>(false);
    const [visibleActoView, setVisibleActoView] = useState<boolean>(false);
    const [visibleRechazo, setVisibleRechazo] = useState<boolean>(false);
    const [visibleMultiple, setVisibleMultiple] = useState<boolean>(false);
    const [motivo, setMotivo] = useState('')
    const [visibleSubsanar, setVisibleSubsanar] = useState<boolean>(false);
    const [visibleActoViewInside, setVisibleActoViewInside] = useState<boolean>(false);

    const accept = (rowData: any) => {
        subsanarActo(rowData)
    };

    const reject = () => {
        setVisibleSubsanar(false)
    };


    async function encontrarTrmPorActoId(trmArray: any, actoId: any) {
        const resultados: any = [];
        const promises: Promise<any>[] = [];

        for (let i = 0; i < trmArray.length; i++) {
            const trm = trmArray[i];

            if (trm.actos && trm.actos.results && Array.isArray(trm.actos.results)) {
                for (let j = 0; j < trm.actos.results.length; j++) {
                    const result = trm.actos.results[j];
                    const objetoConActo = result.find((item: any) => item.acto_id == actoId);

                    if (objetoConActo) {
                        let datos = {
                            prop_id: trm.prop_id,
                        };

                        // Capturamos la promesa en un array
                        const promise = ApiServices.apiPost('getBuscaPropiedad', datos)
                            .then(async res => {
                                if (res.data.valido === 1 && res.data.addenda && res.data.addenda.length > 0) {
                                    trm.propiedadDetail = res.data.addenda;
                                    let datos = {
                                        prop_folio: res.data.addenda[0].prop_folio
                                    }
                                    const responseHistorial = await ApiServices.apiPost('getHistorial', datos)
                                    trm.history = responseHistorial.data.addenda
                                    resultados.push(trm);
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            });

                        promises.push(promise);
                        break;
                    }
                }
            }
        }

        await Promise.all(promises);

        if (resultados.length > 0) {
            setHyperFolio(resultados[0])
        }

        return resultados.length > 0 ? setMatchedActosMultipleFolio(resultados) : false;

    }

    function encontrarTrmPorActoIdBoolean(trmArray: any, actoId: any) {
        const resultados: any = [];
        for (let i = 0; i < trmArray.length; i++) {
            const trm: any = trmArray[i];
            if (trm.actos && trm.actos.results && Array.isArray(trm.actos.results)) {
                for (let j = 0; j < trm.actos.results.length; j++) {
                    const result = trm.actos.results[j];
                    const objetoConActo = result.find((item: any) => item.acto_id == actoId);
                    if (objetoConActo) {
                        resultados.push(trm);
                        break;
                    }
                }
            }
        }
        return resultados.length > 0 ? true : false;
    }

    const [selectedActo, setSelectedActo] = useState<any>()
    const [hyperFolio, setHyperFolio] = useState<any>()

    const [matchedActosMultipleFolio, setMatchedActosMultipleFolio] = useState<any>()

    const AccionTemplate = (rowData: any) => {
        return (
            <>
                <div className='text-center'>
                    {rowData && rowData.rch_status && rowData.rch_status == 1 &&
                        <>
                            <ConfirmPopup
                                target={buttonEl.current}
                                visible={visibleSubsanar}
                                onHide={() => setVisibleSubsanar(false)}
                                message="¿Quieres subsanar el acto?"
                                icon="pi pi-exclamation-triangle"
                                accept={() => accept(rowData)}
                                reject={reject}
                                acceptLabel='Si'
                                rejectLabel='No'
                            />
                            <Button ref={buttonEl} aria-controls="popup_menu" aria-haspopup style={{ color: 'red' }} className="p-button-text p-button-plain" icon="pi pi-stopwatch" onClick={() => { setVisibleSubsanar(true) }} />
                        </>
                    }
                    {rowData && rowData.rch_status && rowData.rch_status == 2 &&
                        <Button aria-controls="popup_menu" aria-haspopup style={{ color: 'green' }} className="p-button-text p-button-plain" icon="pi pi-stopwatch" />
                    }
                    {rowData && !rowData.rch_status &&
                        <Button style={{ color: 'transparent' }} className="p-button-text p-button-plain" icon="pi pi-stopwatch" disabled />
                    }

                    {rowData && props.tramiteDetail && props.tramiteDetail.hijos && props.tramiteDetail.hijos.length > 0 && encontrarTrmPorActoIdBoolean(props.tramiteDetail.hijos, rowData.act_id) &&
                        <Button tooltip={props && props.tramiteDetail && props.tramiteDetail.hijos ? `${props.tramiteDetail.hijos.length} Folios` : 'Folios'} tooltipOptions={{ position: 'top' }} className="p-button-text p-button-plain" icon="pi pi-link" onClick={() => {
                            encontrarTrmPorActoId(props.tramiteDetail.hijos, rowData.act_id)
                            setSelectedActo(rowData)
                            setVisibleMultiple(true)
                        }} />
                    }


                    <Menu model={(props.previousFolio.trm_status == 9 || props.previousFolio.trm_status == 10) ? items2 : items} popup ref={menu} id="popup_menu" />
                    <Button aria-controls="popup_menu" aria-haspopup onClick={(event) => {
                        setSelected(rowData)
                        menu.current.toggle(event)
                    }} className="p-button-text p-button-plain" icon="pi pi-ellipsis-v" />
                </div>
            </>
        );
    }

    const subsanarActo = (information: any) => {
        let datos = {
            rch_id: information.rch_id,
            rch_status: 2,
            usu_id: user.usu_id
        }
        ApiServices.apiPost('updateRechazoActo', datos).then(async res => {
            if (res.data.valido === 1) {
                props.refreshActos()
                toast.current?.show({ severity: 'success', summary: 'Acto Subsanado', detail: 'El Acto fue subsanado correctamente', life: 3000 });
                props.handleSentRevision()
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al Subsanar el Acto', life: 3000 });
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const items2 = [
        {
            items: [
                {
                    label: 'Ver Acto',
                    icon: 'pi pi-pencil',
                    command: () => {
                        getActoInformation()
                    },
                },
                {
                    label: 'Ver Documento',
                    icon: 'pi pi-file-pdf',
                    command: () => {
                        SavePDFcert()
                    },
                }
            ]
        },
    ]

    const items = (user.prf_id == 3 || user.prf_id == 6 || user.prf_id == 7) ? [
        {
            items: [
                {
                    label: 'Editar',
                    icon: 'pi pi-pencil',
                    command: () => {
                        getActoInformation()
                    },
                },
                {
                    label: 'Ver Documento',
                    icon: 'pi pi-file-pdf',
                    command: () => {
                        SavePDFcert()
                    },
                },
                {
                    label: 'Firmar',
                    icon: 'pi pi-check-circle',
                    command: () => { handleSign() }
                },
                {
                    label: 'Rechazar',
                    icon: 'pi pi-times-circle',
                    command: () => { setVisibleRechazo(true) }
                }
            ]
        },
    ] : [
        {
            items: [
                {
                    label: 'Editar',
                    icon: 'pi pi-pencil',
                    command: () => {
                        getActoInformation()
                    },
                },
                {
                    label: 'Ver Documento',
                    icon: 'pi pi-file-pdf',
                    command: () => {
                        SavePDFcert()
                    },
                },
                {
                    label: 'Rechazar',
                    icon: 'pi pi-times-circle',
                    command: () => { setVisibleRechazo(true) }
                }
            ]
        },
    ];

    const getActoInformation = () => {
        let datos = {
            act_id: selected.act_id
        }
        ApiServices.apiPost('getCampos', datos).then(async res => {
            setDynamicFormInformation(res.data.addenda)
            setSelected(selected)
            setVisible(true)
        }).catch(error => {
            console.log(error)
        })
    }

    const [previousActoHistory, setPreviousActoHistory] = useState()
    const [previousActoHistoryInside, setPreviousActoHistoryInside] = useState()

    const getActoInformationView = (information: any) => {
        let datos = {
            ptrm_id: 0,
            trm_id: information.trm_id
        }
        ApiServices.apiPost('getFormatosResp', datos).then(async res => {
            if (res.data.valido === 1 && res.data.addenda.length > 0) {
                const found = res.data.addenda.find((objeto: any) => objeto.act_id === information.act_id);
                if (found && found.frmu_id) {
                    let datos = {
                        act_id: information.act_id
                    }
                    ApiServices.apiPost('getCampos', datos).then(async res => {
                        setDynamicFormInformation(res.data.addenda)
                        let merged = { ...information };
                        merged = { ...merged, ...found };
                        setSelected(merged)
                        setVisibleActoView(true)
                        setPreviousActoHistory(information)
                    }).catch(error => {
                        console.log(error)
                    })
                }
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Formulario no encontrado', life: 3000 });
            }
        }).catch(error => {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error en el servidor, intente de nuevo mas tarde', life: 3000 });
        })
    }

    const getActoInformationViewInside = (information: any) => {
        let datos = {
            ptrm_id: 0,
            trm_id: information.trm_id
        }
        ApiServices.apiPost('getFormatosResp', datos).then(async res => {
            if (res.data.valido === 1 && res.data.addenda.length > 0) {
                const found = res.data.addenda.find((objeto: any) => objeto.act_id === information.act_id);
                if (found && found.frmu_id) {
                    let datos = {
                        act_id: information.act_id
                    }
                    ApiServices.apiPost('getCampos', datos).then(async res => {
                        setDynamicFormInformationInside(res.data.addenda)
                        let merged = { ...information };
                        merged = { ...merged, ...found };
                        setSelectedInside(merged)
                        setVisibleActoViewInside(true)
                        setPreviousActoHistoryInside(information)
                    }).catch(error => {
                        console.log(error)
                    })
                }
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Formulario no encontrado', life: 3000 });
            }
        }).catch(error => {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error en el servidor, intente de nuevo mas tarde', life: 3000 });
        })
    }

    const normalFormatMappingCMP = (data: any) => {
        return {
            id: data.cmp_id,
            cmp_tipo: data.cmp_tipo,
            val: data.value || ''
        }
    }

    const normalFormatMapping = (data: any) => {
        if (data.cmp_tipo === "DT") {
            return { id: data.id, val: '' }
        }
        return {
            id: data.id,
            val: data.val || ''
        }
    }

    function arrayToString(arr) {
        return arr.join(',');
    }

    const formatInformation = (informationToFormat: any) => {
        const flattenedArray = informationToFormat.flat();
        const filteredObjects = flattenedArray.filter((obj: any) => obj.cmp_tipo !== "L" && obj.cmp_tipo !== 'vacio');
        const result = filteredObjects.map(({ cmp_id, frm_id, cmp_tipo, value }: any) => {
            let newValue = value
            if (typeof value == 'object') {
                if (value && value.not_id) {
                    newValue = value.not_id
                } else if (value && value.cp_edo) {
                    newValue = value.cp_edo
                } else if (value && value.cp_mun) {
                    newValue = value.cp_mun
                } else if (cmp_tipo == 'AC') {
                    const result = arrayToString(value);
                    newValue = result
                } else if (cmp_tipo == 'D') {
                    newValue = value
                } else {
                    newValue = JSON.stringify(value)
                }
            }

            return normalFormatMappingCMP({ cmp_id, frm_id, cmp_tipo, value: newValue })
        });
        return result;
    }

    const findDt = () => {
        let element = {}
        if (dynamicInformationParent && typeof dynamicInformationParent == 'object' && dynamicInformationParent.length > 0) {
            dynamicInformationParent.flat().map((singleElement) => {
                if (singleElement.cmp_tipo == 'DT') element = singleElement
            })
        }

        return element
    }

    const [loading, setLoading] = useState(false)

    const handleSave = () => {
        setLoading(true)
        let datos: any
        const formatResponse = formatInformation(dynamicInformationParent)

        let validation100 = true
        let validationClave = true

        formatResponse.filter((comp: any) => comp.cmp_tipo === 'DT' && comp.val.length > 0).forEach((value: any, index: any) => {
            if (value && value.val && value.val.length > 0 && (typeof value.val == 'object')) {
                const oArray: any = findDt()

                let nuda = 0
                let nudaEnaje = 0
                let usoFructo = 0
                let usoFructoEnaje = 0

                const formattedTitulares = value.val.map((singleTitular) => {
                    nuda = nuda + parseFloat(singleTitular.ptit_nuda || 0.00) + parseFloat(singleTitular.nuda_adquiere || 0.00)
                    nudaEnaje = nudaEnaje + parseFloat(singleTitular.nuda_enaje || 0.00)
                    usoFructo = usoFructo + parseFloat(singleTitular.ptit_usufructo || 0.00) + parseFloat(singleTitular.usufructo_adquiere || 0.00)
                    usoFructoEnaje = usoFructoEnaje + parseFloat(singleTitular.usufructo_enaje || 0.00)

                    return {
                        "cmptdt_id": singleTitular.cmptdt_id || '',
                        "paterno": singleTitular.ptit_pat,
                        "materno": singleTitular.ptit_mat,
                        "nombre": singleTitular.ptit_nom,
                        "rfc": singleTitular.ptit_rfc,
                        "curp": singleTitular.field_5 || singleTitular.cmpdt_curp || '',
                        "nuda": singleTitular.ptit_nuda,
                        "usufructo": singleTitular.ptit_usufructo,
                        "representacion": singleTitular.field_8 || singleTitular.cmptd_representacion || '',
                        "ptit_id": singleTitular.cmptd_valorid || singleTitular.ptit_id,
                        "movimiento": !singleTitular.cmptdt_id ? 1 : singleTitular.ptit_status == 0 ? 3 : 2,
                        'nuda_enaje': singleTitular.nuda_enaje || 0.00,
                        'usufructo_enaje': singleTitular.usufructo_enaje || 0.00,
                        'cmptd_status': !singleTitular.cmptdt_id ? 1 : singleTitular.ptit_status == 0 ? 3 : 2
                    }
                })
                if (oArray.cmp_funcion != 'titulares') {
                    nudaEnaje = 100
                    usoFructoEnaje = 100
                }

                if (nuda > 100 || usoFructo > 100 || nuda < 100 || usoFructo < 100) {
                    validation100 = false
                    if (nuda > 100) {
                        toast.current?.show({ severity: 'warn', summary: 'Nuda Mayor', detail: 'La suma de las nudas es mayor a 100%', life: 3000 });
                        setLoading(false)
                    } else if (nuda < 100) {
                        console.log(nuda)
                        toast.current?.show({ severity: 'warn', summary: 'Nuda Menor', detail: 'La suma de las nudas es menor a 100%', life: 3000 });
                        setLoading(false)
                    }
                    if (usoFructo > 100) {
                        toast.current?.show({ severity: 'warn', summary: 'Uso Mayor', detail: 'La suma de los Uso Fructo es mayor a 100%', life: 3000 });
                        setLoading(false)
                    } else if (usoFructo < 100) {
                        toast.current?.show({ severity: 'warn', summary: 'Uso Menor', detail: 'La suma de los Uso Fructo es menor a 100%', life: 3000 });
                        setLoading(false)
                    }
                } else {
                    validation100 = true
                }
            } else {
                const parsed = JSON.parse(value.val)
                if (parsed && parsed.length > 0 && (typeof parsed == 'object')) {
                    const oArray: any = findDt()

                    let nuda = 0
                    let nudaEnaje = 0
                    let usoFructo = 0
                    let usoFructoEnaje = 0

                    const formattedTitulares = parsed.map((singleTitular) => {
                        nuda = nuda + parseFloat(singleTitular.ptit_nuda || 0.00) + parseFloat(singleTitular.nuda_adquiere || 0.00)
                        nudaEnaje = nudaEnaje + parseFloat(singleTitular.nuda_enaje || 0.00)
                        usoFructo = usoFructo + parseFloat(singleTitular.ptit_usufructo || 0.00) + parseFloat(singleTitular.usufructo_adquiere || 0.00)
                        usoFructoEnaje = usoFructoEnaje + parseFloat(singleTitular.usufructo_enaje || 0.00)

                        return {
                            "cmptdt_id": singleTitular.cmptdt_id || '',
                            "concepto": singleTitular?.cmpdt_concepto?.name || '',
                            "paterno": singleTitular.ptit_pat,
                            "materno": singleTitular.ptit_mat,
                            "nombre": singleTitular.ptit_nom,
                            "rfc": singleTitular.ptit_rfc,
                            "curp": singleTitular.field_5 || singleTitular.cmpdt_curp || '',
                            "nuda": singleTitular.ptit_nuda,
                            "usufructo": singleTitular.ptit_usufructo,
                            "representacion": singleTitular.field_8 || singleTitular.cmptd_representacion || '',
                            "ptit_id": singleTitular.cmptd_valorid || singleTitular.ptit_id,
                            "movimiento": !singleTitular.cmptdt_id ? (singleTitular.ptit_status == 0 ? 3 : 2) : singleTitular.ptit_status == 0 ? 3 : 2,
                            'nuda_enaje': singleTitular.nuda_enaje || 0.00,
                            'usufructo_enaje': singleTitular.usufructo_enaje || 0.00,
                            'cmptd_status': !singleTitular.cmptdt_id ? (singleTitular.ptit_status == 0 ? 3 : 2) : singleTitular.ptit_status == 0 ? 3 : 2,
                        }
                    })
                    if (oArray.cmp_funcion != 'titulares') {
                        nudaEnaje = 100
                        usoFructoEnaje = 100
                    }

                    if (nuda > 100 || usoFructo > 100 || nuda < 100 || usoFructo < 100) {
                        validation100 = false
                        if (nuda > 100) {
                            toast.current?.show({ severity: 'warn', summary: 'Nuda Mayor', detail: 'La suma de las nudas es mayor a 100%', life: 3000 });
                            setLoading(false)
                        } else if (nuda < 100) {
                            toast.current?.show({ severity: 'warn', summary: 'Nuda Menor', detail: 'La suma de las nudas es menor a 100%', life: 3000 });
                            setLoading(false)
                        }
                        if (usoFructo > 100) {
                            toast.current?.show({ severity: 'warn', summary: 'Uso Mayor', detail: 'La suma de los Uso Fructo es mayor a 100%', life: 3000 });
                            setLoading(false)
                        } else if (usoFructo < 100) {
                            toast.current?.show({ severity: 'warn', summary: 'Uso Menor', detail: 'La suma de los Uso Fructo es menor a 100%', life: 3000 });
                            setLoading(false)
                        }
                    } else {
                        validation100 = true
                    }
                }
            }
        })

        formatResponse.filter((comp: any) => comp.cmp_tipo === 'DT2' && comp.val.length > 0).forEach((value: any, index: any) => {
            if (value && value.val && value.val.length > 0 && (typeof value.val == 'object')) {
                value.val.map((singleTitular) => {
                    if (singleTitular.prop_clave && singleTitular.prop_clave.length > 0) {
                    } else {
                        toast.current?.show({ severity: 'warn', summary: 'Clave', detail: 'Es necesario que todos los registros tengan una clave.', life: 3000 });
                        validationClave = false
                    }
                })
            } else {
                const parsed = JSON.parse(value.val)
                if (parsed && parsed.length > 0 && (typeof parsed == 'object')) {
                    parsed.map((singleTitular) => {
                        if (singleTitular.prop_clave && singleTitular.prop_clave.length > 0) {
                        } else {
                            toast.current?.show({ severity: 'warn', summary: 'Clave', detail: 'Es necesario que todos los registros tengan una clave.', life: 3000 });
                            validationClave = false
                        }

                    })
                }
            }
        })

        if (selected.frmu_id) {
            datos = {
                code: 402,
                frmu_id: selected.frmu_id,
                usu_id: user.usu_id,
                campos: formatResponse,
                ptrm_id: 0,
                trm_id: props.tramiteDetail.tramite.trm_id
            }
        } else {
            datos = {
                code: 401,
                usu_id: user.usu_id,
                act_id: selected.act_id,
                campos: formatResponse,
                ptrm_id: 0,
                trm_id: props.tramiteDetail.tramite.trm_id
            }
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        if (validation100 && validationClave) {
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    let frmu_id = 0

                    if (selected.frmu_id) {
                        frmu_id = selected.frmu_id
                    } else {
                        frmu_id = res.data.addenda.frmu_id
                    }

                    formatResponse.filter((comp: any) => comp.cmp_tipo === 'DF' && comp.val.length > 0).forEach((value: any, index: any) => {
                        if (value && value.val && value.val.length > 0 && (typeof value.val == 'object')) {
                            value.val.map((singleTitular) => {
                                const newJSON = {
                                    frmu_id: frmu_id,
                                    cmp_id: value.id,
                                    cmp_uno: singleTitular.cmp_uno,
                                    cmp_status: singleTitular.cmp_status,
                                    usu_id: singleTitular.usu_id
                                }


                                ApiServices.apiPost('addControlDinamicoFolio', newJSON).then(async res => {
                                })
                            })
                        } else {
                            const parsed = JSON.parse(value.val)
                            if (parsed && parsed.length > 0 && (typeof parsed == 'object')) {
                                parsed.map((singleTitular) => {
                                    const newJSON = {
                                        frmu_id: frmu_id,
                                        cmp_id: value.id,
                                        cmp_uno: singleTitular.cmp_uno,
                                        cmp_status: singleTitular.cmp_status,
                                        usu_id: singleTitular.usu_id
                                    }


                                    ApiServices.apiPost('addControlDinamicoFolio', newJSON).then(async res => {
                                    })
                                })
                            }
                        }
                    })
                    formatResponse.filter((comp: any) => comp.cmp_tipo === 'DT2' && comp.val.length > 0).forEach((value: any, index: any) => {
                        if (value && value.val && value.val.length > 0 && (typeof value.val == 'object')) {
                            value.val.map((singleTitular) => {
                                if (singleTitular.prop_clave && singleTitular.prop_clave.length > 0)
                                    ApiServices.apiPost('addPropiedadDT', singleTitular).then(async res => {
                                    })
                            })
                        } else {
                            const parsed = JSON.parse(value.val)
                            if (parsed && parsed.length > 0 && (typeof parsed == 'object')) {
                                parsed.map((singleTitular) => {
                                    if (singleTitular.prop_clave && singleTitular.prop_clave.length > 0)
                                        ApiServices.apiPost('addPropiedadDT', singleTitular).then(async res => {
                                        })
                                })
                            }
                        }
                    })
                    formatResponse.filter((comp: any) => comp.cmp_tipo === 'DT' && comp.val.length > 0).forEach((value: any, index: any) => {
                        if (value && value.val && value.val.length > 0 && (typeof value.val == 'object')) {
                            const oArray: any = findDt()

                            let nuda = 0
                            let nudaEnaje = 0
                            let usoFructo = 0
                            let usoFructoEnaje = 0

                            const formattedTitulares = value.val.map((singleTitular) => {
                                nuda = nuda + parseFloat(singleTitular.ptit_nuda || 0.00)
                                nudaEnaje = nudaEnaje + parseFloat(singleTitular.nuda_enaje || 0.00)
                                usoFructo = usoFructo + parseFloat(singleTitular.ptit_usufructo || 0.00)
                                usoFructoEnaje = usoFructoEnaje + parseFloat(singleTitular.usufructo_enaje || 0.00)

                                return {
                                    "cmptdt_id": singleTitular.cmptdt_id || '',
                                    "concepto": singleTitular?.cmpdt_concepto?.name || '',
                                    "paterno": singleTitular.ptit_pat,
                                    "materno": singleTitular.ptit_mat,
                                    "nombre": singleTitular.ptit_nom,
                                    "rfc": singleTitular.ptit_rfc,
                                    "curp": singleTitular.field_5 || singleTitular.cmpdt_curp || '',
                                    "nuda": singleTitular.ptit_nuda,
                                    "usufructo": singleTitular.ptit_usufructo || 0.00,
                                    "representacion": singleTitular.field_8 || singleTitular.cmptd_representacion || '',
                                    "ptit_id": singleTitular.cmptd_valorid || singleTitular.ptit_id,
                                    "movimiento": !singleTitular.cmptdt_id ? (singleTitular.ptit_status == 0 ? 3 : 2) : singleTitular.ptit_status == 0 ? 3 : 2,
                                    'nuda_enaje': singleTitular.nuda_enaje || 0.00,
                                    'usufructo_enaje': singleTitular.usufructo_enaje || 0.00,
                                    'cmptd_status': !singleTitular.cmptdt_id ? (singleTitular.ptit_status == 0 ? 3 : 2) : singleTitular.ptit_status == 0 ? 3 : 2,
                                    'nuda_adquiere': singleTitular.nuda_adquiere ? singleTitular.nuda_adquiere : 0,
                                    'usufructo_adquiere': singleTitular.usufructo_adquiere ? singleTitular.usufructo_adquiere : 0,
                                    'folio': singleTitular.folio ? singleTitular.folio.prop_folio : 0
                                }
                            })
                            if (oArray.cmp_funcion != 'titulares') {
                                nudaEnaje = 100
                                usoFructoEnaje = 100
                            }

                            validation100 = true
                            let datos2 = {
                                code: 507,
                                "titulares": formattedTitulares,
                                "frmu_id": frmu_id,
                                "cmp_id": value.id,
                                "usu_id": user.usu_id
                            }

                            datos2 = EncryptService.encryptData(JSON.stringify(datos2));
                            ApiServices.processRequestAdd(datos2).then(async res => {
                                if (res.data.valido === 1) {

                                }
                            }).catch(error => {
                                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al guardar el tramite', life: 3000 });
                            })
                        } else {
                            const parsed = JSON.parse(value.val)
                            if (parsed && parsed.length > 0 && (typeof parsed == 'object')) {
                                const oArray: any = findDt()

                                let nuda = 0
                                let nudaEnaje = 0
                                let usoFructo = 0
                                let usoFructoEnaje = 0

                                const formattedTitulares = parsed.map((singleTitular) => {
                                    nuda = nuda + parseFloat(singleTitular.ptit_nuda || 0.00)
                                    nudaEnaje = nudaEnaje + parseFloat(singleTitular.nuda_enaje || 0.00)
                                    usoFructo = usoFructo + parseFloat(singleTitular.ptit_usufructo || 0.00)
                                    usoFructoEnaje = usoFructoEnaje + parseFloat(singleTitular.usufructo_enaje || 0.00)

                                    return {
                                        "cmptdt_id": singleTitular.cmptdt_id || '',
                                        "concepto": singleTitular?.cmpdt_concepto?.name || '',
                                        "paterno": singleTitular.ptit_pat,
                                        "materno": singleTitular.ptit_mat,
                                        "nombre": singleTitular.ptit_nom,
                                        "rfc": singleTitular.ptit_rfc,
                                        "curp": singleTitular.field_5 || singleTitular.cmpdt_curp || '',
                                        "nuda": singleTitular.ptit_nuda,
                                        "usufructo": singleTitular.ptit_usufructo,
                                        "representacion": singleTitular.field_8 || singleTitular.cmptd_representacion || '',
                                        "ptit_id": singleTitular.cmptd_valorid || singleTitular.ptit_id,
                                        "movimiento": !singleTitular.cmptdt_id ? (singleTitular.ptit_status == 0 ? 3 : 2) : singleTitular.ptit_status == 0 ? 3 : 2,
                                        'nuda_enaje': singleTitular.nuda_enaje || 0.00,
                                        'usufructo_enaje': singleTitular.usufructo_enaje || 0.00,
                                        'cmptd_status': !singleTitular.cmptdt_id ? (singleTitular.ptit_status == 0 ? 3 : 2) : singleTitular.ptit_status == 0 ? 3 : 2,
                                        'nuda_adquiere': singleTitular.nuda_adquiere ? singleTitular.nuda_adquiere : 0,
                                        'usufructo_adquiere': singleTitular.usufructo_adquiere ? singleTitular.usufructo_adquiere : 0,
                                        'folio': singleTitular.folio ? singleTitular.folio.prop_folio : 0
                                    }
                                })
                                if (oArray.cmp_funcion != 'titulares') {
                                    nudaEnaje = 100
                                    usoFructoEnaje = 100
                                }

                                validation100 = true
                                let datos2 = {
                                    code: 507,
                                    "titulares": formattedTitulares,
                                    "frmu_id": frmu_id,
                                    "cmp_id": value.id,
                                    "usu_id": user.usu_id
                                }

                                datos2 = EncryptService.encryptData(JSON.stringify(datos2));
                                ApiServices.processRequestAdd(datos2).then(async res => {
                                    if (res.data.valido === 1) {

                                    }
                                }).catch(error => {
                                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al guardar el tramite', life: 3000 });
                                })
                            }
                        }
                    })

                    toast.current.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Se ha guardado el Acto', life: 3000 })
                    setVisible(false)
                    setLoading(false)
                }
            }).catch(error => {
                setLoading(false)
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al guardar el tramite', life: 3000 });
            })
        } else {
            setLoading(false)
        }
    }

    const handleReject = () => {
        if (motivo && motivo.length > 0 && selectedCity) {
            let datos = {
                trm_id: props.tramiteDetail.tramite.trm_id,
                act_id: selected.act_id,
                rch_tipo: selectedCity.code,
                rch_nota: motivo,
                usu_id: user.usu_id
            }
            ApiServices.apiPost('addRechazoActo', datos).then(async res => {
                if (res.data.valido === 1) {
                    toast.current?.show({ severity: 'success', summary: 'Acto Rechazado', detail: 'El Acto fue rechazado correctamente', life: 3000 });
                    props.refreshActos()
                    setVisibleRechazo(false)
                    props.handleSentRevision()
                } else {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al Rechazar el Acto', life: 3000 });
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            toast.current?.show({ severity: 'error', summary: 'Datos Incompletos', detail: 'Faltan datos para poder mandar la solicitud', life: 3000 });
        }
    }


    const SavePDFcert = async () => {
        let datos
        datos = { "code": 701, area_id: user.area_id, trm_id: props.tramiteDetail.tramite.trm_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.url[0].valido == 0) {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error', life: 3000 });
                } else {
                    if (typeof res.data.url === 'object') {
                        if (res.data.url && res.data.url.length > 0) {
                            res.data.url.forEach((single: any) => {
                                window.open(`${configuration.url}${single.url}`, '_blank');
                            });
                        }
                    } else {
                        window.open(`${configuration.url}${res.data.url}`, '_blank')
                    }
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }


    /* OLD */

    const [historyVisible, setHistoryVisible] = useState<any>(false);

    const [selectedCity, setSelectedCity] = useState<any>(null);
    const toast = useRef<any>(null);

    const rechazo: any = [
        { name: 'Rechazo', code: 1 },
        { name: 'Rechazo total', code: 2 },
    ];

    const footerContent = (props.previousFolio.trm_status == 9 || props.previousFolio.trm_status == 10 || props.previousFolio.trm_status == 3) ? (<div></div>) : (
        <div>
            <Button label="Cancelar" onClick={() => setVisible(false)} className="p-button-text" loading={loading} />
            <Button label="Guardar" onClick={handleSave} autoFocus loading={loading} />
        </div>
    );

    const footerContentRechazar = (
        <div>
            <Button label="Cancelar" onClick={() => setVisibleRechazo(false)} className="p-button-text" />
            <Button label="Guardar" onClick={handleReject} autoFocus />
        </div>
    );

    const pagoBodyTemplate = (payment: any) => {
        return (
            <p>$ {parseFloat(payment.act_pesos).toFixed(0)} MXN</p>
        )
    }

    const fechaBodyTemplate = (payment: any) => {
        return (
            <p>{formatFecha(payment.pago_fecha)}</p>
        )
    }

    const descriptionBodyTemplate = (rowData: any) => {
        const json: any = rowData.pago_validacion && JSON.parse(rowData.pago_validacion)
        if (json instanceof Array) {
            return (
                <div>
                    {
                        json.map((singleElement) => {
                            return (
                                <p>{`${singleElement && singleElement.$ && singleElement.$.Descripcion ? `${singleElement.$.Descripcion} ${singleElement.$.Monto && '- $ '} ${singleElement.$.Monto}` : ''}`}</p>)
                        })
                    }

                </div>
            )
        }
        else
            return (
                <div>
                    <p>{`${json && json.$ && json.$.Descripcion ? json.$.Descripcion : ''}`}</p>
                </div>
            )
    }

    function formatFecha(fecha: any) {
        if (fecha) {
            const fechaObj = new Date(fecha);
            fechaObj.setUTCHours(0, 0, 0, 0);
            const año = fechaObj.getUTCFullYear();
            const mes = (fechaObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const dia = fechaObj.getUTCDate().toString().padStart(2, '0');
            return `${año}-${mes}-${dia}`;
        } else {
            return ''
        }
    }
    const [sumaMonto, setSumaMonto] = useState(0)

    const dt = useRef<any>(null);
    const footerPagos = (
        <ColumnGroup>
            <Row>
                <Column className='text-right' footer="Precio total:" colSpan={2} />
                <Column footer={`${sumaMonto}`} />
            </Row>
        </ColumnGroup>
    );


    const calcularMonto = () => {
        const sumaMonto = props.tramiteDetail.pagos && props.tramiteDetail.pagos.reduce((acumulador: any, elemento: any) => {
            return acumulador + elemento.pago_monto;
        }, 0)

        setSumaMonto(formatCurrency(parseFloat(sumaMonto)))
    }

    const formatCurrency = (amount: any): any => {
        return amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });
    };

    useEffect(() => {
        calcularMonto()
    }, [])

    const montoBodyTemplate = (rowData: any) => {
        return (
            <>
                <span className="p-column-title">Monto de derechos</span>
                ${rowData.pago_monto}.°°
            </>
        );
    }


    const [newFolio, setNewFolio] = useState()

    const handleNewFolio = () => {
        if (newFolio) {
            let datos
            datos = {
                code: 208, prop_clave: '', prop_folio: newFolio, ptit_nom: '', ptit_pat: '', ptit_mat: '', prop_direccion: '', prop_calle: '', prop_ext: '', prop_int: ''
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    if (res.data.addenda && res.data.addenda.length > 0) {
                        let datosEx = EncryptService.encryptData(JSON.stringify({ code: 210, trm_id: parseInt(props.tramiteDetail.tramite.trm_id), prop_id: parseInt(res.data.addenda[0].prop_id) }));
                        ApiServices.processRequestAdd(datosEx).then(async (res: any) => {
                            if (res.data.valido == 1) {
                                props.getTramiteDetail()
                            }
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                } else {
                    toast.current?.show({ severity: 'error', summary: 'Folio', detail: res.data.mensaje, life: 3000 });
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const deleteFolioFromActo = (rowData: any) => {
        let datosEx = EncryptService.encryptData(JSON.stringify({ code: 211, trm_id: rowData.trm_id, prop_id: rowData.prop_id }));
        ApiServices.processRequestAdd(datosEx).then(async res => {
            if (res.data.valido == 1) {
                props.getTramiteDetail()
            }
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (visibleMultiple) {
            encontrarTrmPorActoId(props.tramiteDetail.hijos, selectedActo.act_id)
        }
    }, [props.tramiteDetail.hijos])

    const [EFirmavisible, EFirmasetVisible] = useState<boolean>(false);
    const [temporalFirma, setTemporalFirma] = useState('')

    const handleSign = () => {
        if (user.usu_efirma_password && user.usu_efirma_password.length > 0) {
            signTramite()
        } else {
            EFirmasetVisible(true)
        }
    }

    const signTramite = () => {

        if (EFirmavisible && !temporalFirma && temporalFirma.length <= 0) {
            return toast.current?.show({ severity: 'error', summary: 'Ingresa la contraseña ', detail: 'Ingresa la contraseña de tu firma', life: 3000 });
        }

        let datos
        datos = { "code": 506, trm_id: [selected.trm_id], frmu_id: [selected.frmu_id], usu_id: user.usu_id, usu_efirma_password: user.usu_efirma_password || EncryptService.encryptData(temporalFirma) }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                toast.current?.show({ severity: 'success', summary: 'Tramites Actualizados', detail: res.data.mensaje, life: 3000 });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
                EFirmasetVisible(false)
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error al Firmar', detail: res.data.mensaje, life: 3000 });
            }
        }).catch(error => {
            toast.current?.show({ severity: 'error', summary: 'Error al Firmar', detail: JSON.stringify(error), life: 3000 });
        })
    }

    const EFirmafooterContent = (
        <div className=''>
            <Button label="Cancelar" onClick={() => EFirmasetVisible(false)} severity="secondary" outlined />
            <Button label="Firmar" onClick={signTramite} autoFocus />
        </div>
    );

    const reORderActos = (newValues: any) => {
        const localArray = []
        const ordered = newValues && newValues.map((singleValue, index) => {
            singleValue.ta_consecutivo = index + 1
            localArray.push(singleValue)
            return {
                act_id: singleValue.act_id,
                ta_consecutivo: index + 1
            }
        })

        let datos
        datos = { code: 214, trm_id: props.tramiteDetail.tramite.trm_id, actos: ordered }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                console.log('Toast Ordenados')
            } else {
                console.log('Error al REordenar')
            }
        }).catch(error => {
            console.log('Error al REordenar')
        })


        props.setFormattedActos(localArray)
    }

    return (
        <section>
            <Toast ref={toast} />

            <div className='grid'>

                <div className='col-12 md:col-6'>
                    <h3 className='color-violet'>Actos</h3>
                    <p>Ingresa los datos complementarios de los actos ingresados.</p>
                </div>
            </div>

            <div className='pt-4'>
                <DataTable
                    reorderableColumns reorderableRows
                    value={props.formattedActos}
                    sortField="ta_consecutivo"
                    sortOrder={1}
                    onRowReorder={(e) => reORderActos(e.value)}
                    paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                    <Column rowReorder style={{ width: '3rem' }} />
                    <Column field="ta_consecutivo" header="Consecutivo" style={{ width: '10%' }}></Column>
                    <Column field="act_pesos" header="Pago" body={pagoBodyTemplate} style={{ width: '10%' }}></Column>
                    <Column field="act_clave" header="Clave" style={{ width: '10%' }}></Column>
                    <Column field="act_nombre" header="Detalle acto" style={{ width: '60%' }}></Column>
                    <Column field="price" body={AccionTemplate} style={{ width: '10%' }}></Column>
                </DataTable>
            </div>

            <Divider style={{ marginTop: 25, marginBottom: 25 }} />

            {props && props.tramiteDetail && props.tramiteDetail.pagos && props.tramiteDetail.pagos.length > 0 &&
                < div >
                    <div className='col-12 md:col-12'>
                        <h3 className='color-violet'>Pagos realizados</h3>
                        <p>Podrá visualizar los pagos</p>

                        <div>
                            <DataTable ref={dt} value={props.tramiteDetail.pagos} footerColumnGroup={footerPagos}
                                dataKey="id" paginator rows={5} className="datatable-responsive"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pagos"
                                emptyMessage="No products found." responsiveLayout="scroll">
                                <Column field="pago_serie" header="Serie" sortable ></Column>
                                <Column field="pago_folio" header="Pago" sortable /* body={pagoBodyTemplate} */ ></Column>
                                <Column header="Descripción" body={descriptionBodyTemplate} ></Column>
                                <Column field="pago_update" header="Fecha de pago" sortable body={fechaBodyTemplate} ></Column>
                                <Column field="pago_monto" header="Monto de derechos" body={montoBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            }

            <Divider style={{ marginTop: 25, marginBottom: 25 }} />

            <div className='pt-4' style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
                <Historial historial={props.actoHistoryClear} />
            </div>

            {/* Modal Editar Acto */}
            <Dialog
                header={`${(selected && selected.act_nombre) || ''}`}
                maximizable
                visible={visible}
                style={{ width: '60%' }}
                onHide={() => setVisible(false)}
                footer={footerContent}
            >
                <DynamicForm
                    edicion={props.edicion}
                    propiedadDetail={props.propiedadDetail}
                    informationForm={dynamicFormInformation}
                    information={selected}
                    setDynamicInformationParent={setDynamicInformationParent}
                    titulares={props.titulares}
                    previousActoHistory={previousActoHistory}
                    tramite={props.tramiteDetail.tramite}
                    tramiteDetail={props.tramiteDetail}
                    actoHistory={props.actoHistory}
                    setSelected={setSelectedInside}
                    getActoInformationView={getActoInformationViewInside}
                    detail={props.propiedadDetail}
                    setActoHistory={props.setActoHistory}
                    actoHistoryClone={props.actoHistoryClone}
                    setTramiteSelected={setTramiteSelectedInside}
                    loading={loading}
                />
            </Dialog>

            {/* Modal Ver Acto */}
            <Dialog
                header={`${(selected && selected.act_nombre) || ''}`}
                maximizable
                visible={visibleActoView}
                style={{ width: '50%' }}
                onHide={() => setVisibleActoView(false)}
            >
                <DynamicForm
                    edicion={props.edicion}
                    propiedadDetail={props.propiedadDetail}
                    informationForm={dynamicFormInformation}
                    information={selected}
                    setDynamicInformationParent={setDynamicInformationParent}
                    titulares={props.titulares}
                    previousActoHistory={previousActoHistory}
                    tramite={props.tramiteDetail.tramite}
                    tramiteDetail={props.tramiteDetail}
                    actoHistory={props.actoHistory}
                    setSelected={setSelectedInside}
                    getActoInformationView={getActoInformationViewInside}
                    detail={props.propiedadDetail}
                    setActoHistory={props.setActoHistory}
                    actoHistoryClone={props.actoHistoryClone}
                    setTramiteSelected={setTramiteSelectedInside}
                />
            </Dialog>

            {/* Modal Ver Acto Dentro Acto*/}
            <Dialog
                header={`${(selectedInside && selectedInside.act_nombre) || ''}`}
                maximizable
                visible={visibleActoViewInside}
                style={{ width: '50%' }}
                onHide={() => setVisibleActoViewInside(false)}
            >
                <DynamicForm
                    propiedadDetail={props.propiedadDetail}
                    informationForm={dynamicFormInformationInside}
                    information={selectedInside}
                    setDynamicInformationParent={setDynamicInformationParent}
                    titulares={props.titulares}
                    previousActoHistory={previousActoHistoryInside}
                    tramite={tramiteSelectedInside}

                    actoHistory={props.actoHistory}
                    setSelected={setSelectedInside}
                    getActoInformationView={getActoInformationViewInside}
                    detail={props.propiedadDetail}
                    setActoHistory={props.setActoHistory}
                    actoHistoryClone={props.actoHistoryClone}
                    setTramiteSelected={setTramiteSelectedInside}
                />
            </Dialog>

            <Dialog header="E-Firma" visible={EFirmavisible} maximizable style={{ width: '40vw' }} onHide={() => EFirmasetVisible(false)} footer={EFirmafooterContent}>
                <p>Al ingresar la E-firma, estas autorizando que el acto seleccionado sea firmado.</p>
                <div className='grid'>
                    <div className='col-12'>
                        <span className="p-float-label">
                            <Password
                                id="password"
                                inputId='password'
                                className={`p-inputtext-lg`}
                                onChange={(e) => { setTemporalFirma(e.target.value) }}
                                toggleMask
                                feedback={false}
                                style={{ width: '100%' }}
                            />
                            <label htmlFor="username">Contraseña de E-Firma</label>
                        </span>
                    </div>
                </div>
            </Dialog>


            {/*inicia dialog de rechazo*/}
            <Dialog header="Rechazar" visible={visibleRechazo} maximizable style={{ width: '40vw' }} onHide={() => setVisibleRechazo(false)} footer={footerContentRechazar}>
                <div className='grid'>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label">
                            <Dropdown inputId="dd-city" value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={rechazo} optionLabel="name" className="w-100 p-inputtext-lg" virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }} />
                            <label htmlFor="dd-city">Tipo de rechazo</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputTextarea id="username" rows={5} cols={30} onBlur={(e) => { setMotivo(e.target.value) }} />
                            <label htmlFor="username">Motivo</label>
                        </span>
                    </div>
                </div>
            </Dialog>

            {/*inicia dialog de multiple folio*/}
            <Dialog header="Múltiples folios" visible={visibleMultiple} maximizable style={{ width: '80vw' }} onHide={() => setVisibleMultiple(false)}>
                <div className='grid'>
                    <div className='col-12 md:col-4'>
                        <div>
                            <small>Acto a registrar</small>
                            <p>{selectedActo && `${selectedActo.act_clave} - ${selectedActo.act_nombre}`}</p>
                        </div>
                        <div>
                            <table className='w-full p-2'>
                                <thead>
                                    <tr className=''>
                                        <th className='p-2 border-table'>Folio</th>
                                        <th className='p-2 border-table'>Estatus</th>
                                        <th className='p-2 border-table'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        matchedActosMultipleFolio && matchedActosMultipleFolio.length > 0 && matchedActosMultipleFolio.map((singleFolio: any) => {
                                            return (
                                                <tr>
                                                    <td className='border-table p-2'>
                                                        {hyperFolio && hyperFolio.prop_id && singleFolio && singleFolio.prop_id && hyperFolio.prop_id == singleFolio.prop_id ?
                                                            <p style={{ color: '#873efe' }}>{singleFolio && singleFolio.propiedadDetail && singleFolio.propiedadDetail[0] && singleFolio.propiedadDetail[0].prop_folio}</p>
                                                            :
                                                            <p onClick={() => { setHyperFolio(singleFolio) }}>{singleFolio && singleFolio.propiedadDetail && singleFolio.propiedadDetail[0] && singleFolio.propiedadDetail[0].prop_folio}</p>
                                                        }
                                                    </td>
                                                    <td className='border-table text-center p-2'>
                                                        <Tooltip target=".custom-target-icon" />
                                                        {
                                                            selectedActo &&
                                                                selectedActo.frmu_status &&
                                                                selectedActo.frmu_status == 0 ? <i className='pi pi-eraser p-overlay-badge custom-target-icon' style={{ color: 'black' }} data-pr-tooltip="Borrado" data-pr-position="top" /> :
                                                                selectedActo.frmu_status == 1 ? <i className='pi pi-circle p-overlay-badge custom-target-icon' style={{ color: 'purple' }} data-pr-tooltip="Abierto" data-pr-position="top" /> :
                                                                    selectedActo.frmu_status == 2 ? <i className='pi pi-times p-overlay-badge custom-target-icon' style={{ color: 'red' }} data-pr-tooltip="Cerrado" data-pr-position="top" /> :
                                                                        selectedActo.frmu_status == 3 ? <i className='pi pi-times p-overlay-badge custom-target-icon' style={{ color: 'red' }} data-pr-tooltip="Cancelado" data-pr-position="top" /> :
                                                                            selectedActo.frmu_status == 4 ? <i className='pi pi-times-circle p-overlay-badge custom-target-icon' style={{ color: 'red' }} data-pr-tooltip="4 Firmado Rechazado" data-pr-position="top" /> :
                                                                                selectedActo.frmu_status == 5 ? <i className='pi pi-check-circle p-overlay-badge custom-target-icon' style={{ color: 'green' }} data-pr-tooltip="5 Firmado Rechazado" data-pr-position="top" /> : <p></p>
                                                        }
                                                    </td>
                                                    <td className='border-table p-2'>
                                                        <Button tooltip="Eliminar" style={{ color: 'red' }} tooltipOptions={{ position: 'top' }} className="p-button-text p-button-plain" icon="pi pi-trash" onClick={() => { deleteFolioFromActo(singleFolio) }} />
                                                        <Button
                                                            tooltip="Ver"
                                                            tooltipOptions={{ position: 'top' }}
                                                            className="p-button-text p-button-plain" icon="pi pi-eye"
                                                            onClick={() => { setHyperFolio(singleFolio) }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td className='border-table p-2'>
                                            <span className="p-float-label">
                                                <InputText id="username" value={newFolio} onChange={(e: any) => { setNewFolio(e.target.value) }} />
                                                <label htmlFor="username">Folio</label>
                                            </span>
                                        </td>
                                        <td className='border-table p-2'></td>
                                        <td className='border-table p-2'>
                                            <Button tooltip="Agregar" tooltipOptions={{ position: 'top' }} className="" icon="pi pi-plus" onClick={handleNewFolio} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{`Total ${matchedActosMultipleFolio ? matchedActosMultipleFolio.length : 0}`}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='col-12 md:col-8'>
                        <div className='grid'>
                            <div className='col-12 md:col-4'>
                                <small className='black'>Folio</small>
                                <p className='result'>{hyperFolio && hyperFolio.propiedadDetail && hyperFolio.propiedadDetail[0] && hyperFolio.propiedadDetail[0].prop_folio}</p>
                            </div>
                            <div className='col-12 md:col-4'>
                                <small className='black'>Clave catastral</small>
                                <p className='result'>{hyperFolio && hyperFolio.propiedadDetail && hyperFolio.propiedadDetail[0] && hyperFolio.propiedadDetail[0].prop_clave}</p>
                            </div>
                            <div className='col-12 md:col-12'>
                                <small className='black'>Antecedentes</small>
                                <p className='result'></p>
                            </div>
                            <div className='col-12 md:col-12'>
                                <small className='black'>Domicilio registral</small>
                                <p className='result'>{hyperFolio && hyperFolio.propiedadDetail && hyperFolio.propiedadDetail[0] && hyperFolio.propiedadDetail[0].prop_direccion}</p>
                            </div>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Accordion>
                                <AccordionTab header="Titulares">
                                    <Titulares data={{ titulares: props.titulares }} />
                                </AccordionTab>
                            </Accordion>
                            <Accordion activeIndex={0}>
                                <AccordionTab header="Historial de Actos">
                                    {hyperFolio && hyperFolio.history && hyperFolio.history.length > 0 &&
                                        <Historial
                                            historial={hyperFolio.history}
                                            preTramiteFolio={props.detallePreTramite}
                                            propiedadDetail={props.detallePropiedad}
                                        />
                                    }
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </Dialog>
        </section >
    );

}

export default React.memo(TabActos);
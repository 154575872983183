import { useNavigate } from 'react-router-dom';
import AppMenuGeneral from './VerticalMenu/AppMenuGeneralSearch';
import { classNames } from 'primereact/utils';
import authGuardService from '../../../service/authGuardService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import LiveClockUpdate from '../../../utilities/LiveClock';
import configuration from '../../../configurationFile';
import { RadioButton } from 'primereact/radiobutton';
import ApiServices from '../../../service/apiServices';

const TopBarGeneralSearch = (props: any) => {

    const user = authGuardService.getUser()

    const [searchType, setSearchType] = useState<any>(null)
    const [visible, setVisible] = useState<boolean>(false);
    const navigate = useNavigate();

    const onTopbarSubItemClick = (event: any) => {
        authGuardService.logout()
        event.preventDefault();
        navigate('/login')
    }

    const navigateSearch = () => {
        setVisible(false)
        if (searchType === 'folios') {
            navigate('/ventanilla/vt-buscador', {
                state: {
                    onlyView: true
                }
            })
        } else if (searchType === 'control') {
            navigate('/ventanilla/vt-buscador-interno', {
                state: {
                    onlyView: true
                }
            })
        } else if (searchType === 'morales') {
            navigate('/ventanilla/vt-buscador-interno-morales', {
                state: {
                    onlyView: true
                }
            })
        }
    }

    const footerContent = (
        <div className='gap-3 justify-content-right'>
            <Button className='w-2' label="Cancelar" onClick={navigateSearch} style={{ background: '#5B5B5F', borderColor: '#5B5B5F', padding: 16 }} />
            <Button className='w-2' label="Iniciar" onClick={navigateSearch} style={{ padding: 16 }} disabled={!searchType} />
        </div>
    );



    /* NOTIFICATIONS */
    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        getDesfirmarNotifications()
        getSettings()
    }, [])

    const getDesfirmarNotifications = () => {
        let datos
        datos = { usu_id: user.usu_id }
        ApiServices.apiPost('getNotificacion', datos).then(async res => {
            setNotifications(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const readNotification = (notification: any) => {
        let datos
        datos = {
            usu_id: user.usu_id,
            not_id: notification.not_id
        }
        ApiServices.apiPost('setViewNotificacion', datos).then(async res => {
            getDesfirmarNotifications()
        }).catch(error => {
            console.log(error)
        })
    }

    const [colorButtons, setColorButtons] = useState()
    const [colorTitles, setColorTitles] = useState()
    const [image1, setImage1] = useState<any>()
    const [image2, setImage2] = useState<any>()
    const [image3, setImage3] = useState<any>()

    const getSettings = () => {
        ApiServices.apiPost('getUMA', { "setting_id": 4 }).then(async (res: any) => { setColorButtons(res.data.addenda[0].setting_valor) })
        ApiServices.apiPost('getUMA', { "setting_id": 5 }).then(async (res: any) => { setColorTitles(res.data.addenda[0].setting_valor) })
        ApiServices.apiPost('getUMA', { "setting_id": 6 }).then(async (res: any) => { setImage1(res.data.addenda[0].setting_valor) })
        ApiServices.apiPost('getUMA', { "setting_id": 7 }).then(async (res: any) => { setImage2(res.data.addenda[0].setting_valor) })
        ApiServices.apiPost('getUMA', { "setting_id": 8 }).then(async (res: any) => { setImage3(res.data.addenda[0].setting_valor) })
    }




    return (

        <div className="layout-topbar">
            <div className="layout-topbar-left" >
                <button className="topbar-menu-button p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-bars"></i>
                </button>

                <button className="p-link" onClick={() => navigate('/')} style={{ display: 'flex', width: '100%', height: 'auto', justifyContent: 'center' }}>
                    <img className='logo' src={`${configuration.url}/${image1}`} alt="logo" style={{ maxWidth: '33%', height: 'auto', display: 'flex', alignSelf: 'center' }} />
                    <img className='logo' src={`${configuration.url}/${image2}`} alt="logo" style={{ maxWidth: '33%', height: 'auto', display: 'flex', alignSelf: 'center' }} />
                    <img className='logo' src={`${configuration.url}/${image3}`} alt="logo" style={{ maxWidth: '33%', height: 'auto', display: 'flex', alignSelf: 'center' }} />
                </button>
            </div>

            <AppMenuGeneral model={props.items} menuMode={props.menuMode} colorScheme={props.colorScheme} menuActive={props.menuActive} activeInlineProfile={props.activeInlineProfile}
                onSidebarMouseOver={props.onSidebarMouseOver} onSidebarMouseLeave={props.onSidebarMouseLeave} toggleMenu={props.onToggleMenu} onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                onMenuClick={props.onMenuClick} onMenuClickFalse={props.onMenuClickFalse} onRootMenuItemClick={props.onRootMenuItemClick} onMenuItemClick={props.onMenuItemClick} />

            <div className="layout-topbar-right">
                <ul className="layout-topbar-right-items">
                    <li id="profile" className={classNames('profile-item', { 'active-topmenuitem': props.topbarMenuActive })}>
                        <button className="p-link" onClick={props.onTopbarItemClick}>
                            {user && user.usu_foto ?
                                <img src={`${configuration.url}/${user.usu_foto}`} alt="profile" />
                                :
                                <img src="../../../../assets/layout/images/profile-image.png" alt="profile" />
                            }
                        </button>

                        <ul className="fadeInDown">
                            <li role="menuitem">
                                <button className="p-link" onClick={() => { navigate('/ventanilla/ven-perfil') }}>
                                    <i className="pi pi-fw pi-user"></i>
                                    <span>Perfil</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button className="p-link" onClick={onTopbarSubItemClick}>
                                    <i className="pi pi-fw pi-sign-out"></i>
                                    <span>Cerrar sesión</span>
                                </button>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <div className='nav-user px-3 mx-3'>
                            <h5 className='black'>{user ? `${user.usu_nom ? user.usu_nom.charAt(0).toUpperCase() + user.usu_nom.slice(1) : ''} ${user.usu_pat ? user.usu_pat.charAt(0).toUpperCase() + user.usu_pat.slice(1) : ''}` : ''}</h5>
                            {/* <small>10 Enero 2023 9:00 am</small> */}
                            <LiveClockUpdate />
                        </div>
                    </li>

                    {/* <li id="notification" className={classNames('notification-item', { 'active-topmenuitem': props.notificationMenuActive })}>
                        <button className="p-link" onClick={props.onNotificationItemClick}>
                            <i className="topbar-icon pi pi-fw pi-bell"></i>
                            <span className="topbar-badge">{notifications ? notifications.length : 0}</span>
                            <span className="topbar-item-name">Notifications</span>
                        </button>

                        <ul className="fadeInDown notificacion" >
                            {
                                notifications && notifications.length <= 0 &&

                                <li role="menuitem">
                                    <a>
                                        <h6><i className="pi pi-fw pi-circle-fill"></i>Sin Notificaciones</h6>
                                    </a>
                                </li>
                            }
                            {
                                notifications && notifications.length > 0 && notifications.map((singleNotification: any) => {
                                    return (
                                        <li role="menuitem" onClick={() => { readNotification(singleNotification) }}>
                                            <a>
                                                <h6><i className="pi pi-fw pi-circle-fill"></i>{singleNotification.not_titulo}</h6>
                                                <p>{singleNotification.not_texto}</p>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </li> */}

                    <li className=''>
                        <Button label="Buscador" className='w-full mr-5 p-button-help' onClick={() => setVisible(true)} />
                    </li>
                </ul>
            </div>

            <Dialog header="Iniciar búsqueda" visible={visible} style={{ width: '40vw' }} onHide={() => setVisible(false)} footer={footerContent}>
                <div className='grid'>
                    <div className='col-12 md:col-12'>
                        <p className='text-left'>Selecciona qué tipo de búsqueda quieres realizar.</p>
                        <div className="flex flex-wrap gap-3 justify-content-center mt-6 mb-6">
                            <div className="flex align-items-center">
                                <RadioButton inputId="morales" name="morales" value="morales" onChange={() => { setSearchType('morales') }} checked={searchType === 'morales'} />
                                <label htmlFor="morales" className="ml-2">Buscador de Personas Morales</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton inputId="folios" name="folios" value="folios" onChange={() => { setSearchType('folios') }} checked={searchType === 'folios'} />
                                <label htmlFor="folios" className="ml-2">Buscador de folios</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton inputId="control" name="control" value="control" onChange={() => { setSearchType('control') }} checked={searchType === 'control'} />
                                <label htmlFor="control" className="ml-2">Buscador controles internos</label>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div >
    )
}

export default TopBarGeneralSearch;
import { memo, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import ApiServices from '../../service/apiServices';
import NuevoPreTramite from './TiposTramite/NuevoPreTramite';
import EditarPreTramite from './TiposTramite/EditarPreTramite';
import EncryptService from '../../service/encrypt.service';
import { ProgressBar } from 'primereact/progressbar';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import VerTramite from './TiposTramite/VerTramite';

const MiddleWareTramite = (props: any) => {

    const navigate = useNavigate();

    const location = useLocation();
    const toast = useRef(null);

    const folio: any = location.state.datos
    const pagosAnteriores: any = location.state.pagos
    const preTramite: any = location.state.preTramite
    const tramite: any = location.state.tramite

    const [loading, setLoading] = useState(true)
    const [redirectToPage, setRedirectToPage] = useState('')
    const [detallePropiedad, setDetallePropiedad] = useState()
    const [listadoActos, setListadoActos] = useState([])
    const [listadoActosFiltrados, setListadoActosFiltrados] = useState([])
    const [listadoNotarios, setListadoNotarios] = useState([])
    const [titulares, setTitulares] = useState([])
    const [lineaDelTiempo, setLineaDelTiempo] = useState()
    const [detallePreTramite, setDetallePreTramite] = useState()
    const [detalleTramite, setDetalleTramite] = useState()
    const [historial, setHistorial] = useState()

    const getActos = async () => {
        try {
            let datos;
            const res1 = await ApiServices.apiPost('catActo', datos);
            setListadoActos(res1.data.addenda);

            const res2 = await ApiServices.apiPost('catCategoriaActo', datos);
            let generalInformation = [];

            if (res2.data.addenda && res2.data.addenda.length > 0) {
                const clonedArray = [{ ccatacto_id: 0, ccatacto_desc: 'General', ccatacto_status: 1 }, ...res2.data.addenda];
                const promises = clonedArray.map(async (singleAct) => {
                    let datos = { ccatacto_id: singleAct.ccatacto_id };
                    try {
                        const res3 = await ApiServices.apiPost('catActoCat', datos);
                        if (res3.data.addenda && res3.data.addenda.length > 0) {
                            const newArray = res3.data.addenda.map((act) => ({
                                key: act.act_id,
                                label: `${act.act_clave} ${act.act_nombre}`,
                                data: act,
                                icon: ""
                            }));
                            return {
                                key: singleAct.ccatacto_id,
                                label: singleAct.ccatacto_desc,
                                data: singleAct,
                                icon: "",
                                children: newArray,
                                selectable: false
                            };
                        }
                    } catch (error) {
                        console.log(error);
                    }
                    return null;
                });

                generalInformation = (await Promise.all(promises)).filter(info => info !== null);
                setListadoActosFiltrados(generalInformation);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getNotarios = async () => {
        await ApiServices.apiPost('catNotarios').then(async res => {
            setListadoNotarios(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getTimeLine = async (trm_id: any) => {
        if (trm_id) {
            let datos = { trm_id }
            await ApiServices.apiPost('getTimeLine', datos).then(async res => {
                if (res.data.valido === 1 && res.data.addenda && res.data.addenda.length > 0) {
                    setLineaDelTiempo(res.data.addenda[0])
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const getTitulares = async (prop_id: any) => {
        let datos = { prop_id }
        await ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
            setTitulares(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getHistorial = async (prop_folio) => {
        let datos = { prop_folio }
        ApiServices.apiPost('getHistorial', datos).then(async res => {
            setHistorial(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getDetallePropiedad = async (prop_id: any) => {
        if (prop_id) {
            let datos = { prop_id }
            await ApiServices.apiPost('getBuscaPropiedad', datos).then(async res => {
                if (res && res.data && res.data.addenda && res.data.addenda.length > 0) {
                    setDetallePropiedad(res.data.addenda[0])
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const getDetallePreTramite = async (ptrm_id: number | string) => {
        let datos
        datos = { code: 303, ptrm_id: ptrm_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                setDetallePreTramite(res.data.addenda)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const getDetalleTramite = async (trm_id: number | string) => {
        let datos = { code: 204, trm_id: trm_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                setDetalleTramite(res.data.addenda)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const handleFolio = async () => {
        await getTitulares(folio[0].prop_id)
        await getDetallePropiedad(folio[0].prop_id)
        await getActos()
        await getNotarios()
        setRedirectToPage('nuevoPreTramite')
        setLoading(false)
    }

    const handlePreTramite = async () => {
        try {
            setLoading(true);
            await Promise.all([
                getHistorial(preTramite.prop_folio),
                getTitulares(preTramite.prop_id),
                getDetallePropiedad(preTramite.prop_id),
                getActos(),
                getNotarios(),
                getDetallePreTramite(preTramite.ptrm_id)
            ]);

            setRedirectToPage('existentePreTramite');
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Error en el proceso',
                detail: 'Hubo un error al descargar los datos, intenta de nuevo mas tarde',
                life: 5000
            });
            setTimeout(() => { navigate('/') }, 5000);

        } finally {
            setLoading(false);
        }
    };

    const handleTramite = async () => {
        try {
            setLoading(true);
            await Promise.all([
                getHistorial(tramite.prop_folio),
                getTitulares(tramite.prop_id),
                getDetallePropiedad(tramite.prop_id),
                getActos(),
                getNotarios(),
                getTimeLine(tramite.trm_id),
                getDetalleTramite(tramite.trm_id)
            ]);

            setRedirectToPage('tramite');
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Error en el proceso',
                detail: 'Hubo un error al descargar los datos, intenta de nuevo mas tarde',
                life: 5000
            });
            setTimeout(() => { navigate('/') }, 5000);

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (folio)
            handleFolio()
    }, [folio])

    useEffect(() => {
        if (preTramite)
            handlePreTramite()
    }, [preTramite])

    useEffect(() => {
        if (tramite)
            handleTramite()
    }, [tramite])

    return (
        <section>
            <Toast ref={toast} />

            {loading &&
                <>
                    <ProgressBar mode="indeterminate" style={{ height: '10px', marginBottom: '20px' }} />
                    <Skeleton className="mb-2"></Skeleton>
                    <Skeleton width="50rem" className="mb-2"></Skeleton>
                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                    <Skeleton height="2rem" className="mb-2"></Skeleton>
                    <Skeleton width="70rem" height="20rem"></Skeleton>
                </>
            }

            {!loading && redirectToPage === 'nuevoPreTramite' &&
                <NuevoPreTramite
                    titulares={titulares}
                    detallePropiedad={detallePropiedad}
                    listadoActos={listadoActos}
                    listadoActosFiltrados={listadoActosFiltrados}
                    listadoNotarios={listadoNotarios}
                    lineaDelTiempo={lineaDelTiempo}
                    pagosAnteriores={pagosAnteriores}
                />
            }

            {!loading && redirectToPage === 'existentePreTramite' &&
                <EditarPreTramite
                    getDetallePreTramite={getDetallePreTramite}
                    titulares={titulares}
                    detallePropiedad={detallePropiedad}
                    listadoActos={listadoActos}
                    listadoActosFiltrados={listadoActosFiltrados}
                    listadoNotarios={listadoNotarios}
                    detallePreTramite={detallePreTramite}
                    lineaDelTiempo={lineaDelTiempo}
                    historial={historial}
                />
            }

            {!loading && redirectToPage === 'tramite' &&
                <VerTramite
                    getDetalleTramite={getDetalleTramite}
                    titulares={titulares}
                    detallePropiedad={detallePropiedad}
                    listadoActos={listadoActos}
                    listadoActosFiltrados={listadoActosFiltrados}
                    listadoNotarios={listadoNotarios}
                    detalleTramite={detalleTramite}
                    lineaDelTiempo={lineaDelTiempo}
                    historial={historial}
                />
            }

        </section >
    );

}

export default memo(MiddleWareTramite);



// @ts-nocheck
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../../../../service/apiServices';
import ProductService from '../../../../../serviceExample/ProductService';
import EncryptService from '../../../../../service/encrypt.service';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

const CatActos = () => {

    const now = new Date()

    const toast = useRef<Toast>(null);
    const productService = new ProductService();
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);
    const [date, setDate] = useState<any>(null);
    const [checked, setChecked] = useState<boolean>(false);
    const [SelectedActo, setSelectedActo] = useState<any>({
        act_id: { value: '', valid: true },
        area_id: { value: '', valid: true },
        act_umas: { value: '', valid: true },
        cnpg_id: { value: '', valid: true },
        act_dias: { value: '', valid: true },
        act_clave: { value: '', valid: true },
    });
    const [rowClick, setRowClick] = useState(true);
    const [visible, setVisible] = useState<boolean>(false);
    const [visiuma, setUma] = useState<boolean>(false);
    const [selectedCities, setSelectedCities] = useState(null);
    const [selectedAsignacion, setselectedAsignacion] = useState<any>(null);
    const [selectedConceptoPago, setselectedConceptoPago] = useState<any>(null);
    const [value4, setValue4] = useState();
    const [selectedArea, setselectedArea] = useState<any>();
    const [catConceptos, setCatConceptos] = useState([]);
    const [catAreas, setcatAreas] = useState([]);
    const [catActos, setcatActos] = useState([]);
    const [catActosClone, setCatActosClone] = useState([]);
    const [UMA, setUMA] = useState<any>({
        setting_id: { value: '', valid: true },
        setting_valor: { value: '', valid: true }
    });


    useEffect(() => {
        getUma()
        getCConceptos()
        getCatActos()
        getCAreas()
    }, []);

    const getCConceptos = async () => {
        let datos
        ApiServices.apiPost('catConceptoPago', datos).then(async resp => {
            setCatConceptos(resp.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    function filterAreasToKeep(array, labels) {
        return array.filter(item => labels.includes(item.area_desc));
    }


    const getCAreas = async () => {
        let datos
        ApiServices.apiPost('catAreas', datos).then(async resp => {
            const filtered = filterAreasToKeep(resp.data.addenda, ['Certificaciones', 'Jurídico', 'Registro']);
            setcatAreas(filtered)
        }).catch(error => {
            console.log(error)
        })
    }

    const getCatActos = async () => {
        let datos
        ApiServices.apiPost('catActo', datos).then(async resp => {
            setcatActos(resp.data.addenda)
            setCatActosClone(resp.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getUma = async () => {
        let datos = { "setting_id": 1 }
        ApiServices.apiPost('getUMA', datos).then(async resp => {
            setUMA(resp.data.addenda[0])
        }).catch(error => {
            console.log(error)
        })
    }

    const handleGeneralData = (e: any, json: any) => {
        const temporalData = JSON.parse(JSON.stringify(json));
        temporalData.setting_valor = e.target.value
        setUMA(temporalData)
    }

    const handleActData = (value: any, jsonId: any) => {
        const temporalData = JSON.parse(JSON.stringify(SelectedActo));
        temporalData[jsonId] = value;
        setSelectedActo(temporalData);
    };

    function obtenerJsonPorCodigo(array: any, codigo: any, searchType: any = 'code') {
        if (codigo) {
            for (var i = 0; i < array.length; i++) {
                const tempArray = array[i]
                if (tempArray[searchType] === codigo) {
                    return array[i];
                }
            }
            return null;
        } else {
            return null
        }
    }


    const checkFields = () => {
        const fieldsToCheck = ['Acto', 'Area', 'Concepto']

        const newArray = fieldsToCheck.map((field) => {
            if (field === 'Acto') {
                if (SelectedActo && SelectedActo.act_id) {
                    return true
                }
                else {
                    toast.current?.show({ severity: 'info', summary: 'Error', detail: 'El error al actualizar acto', life: 3000 });
                    return false
                }
            } else if (field === 'Area') {
                if (selectedArea && selectedArea.area_id) {
                    return true
                }
                else {
                    toast.current?.show({ severity: 'info', summary: 'Area no seleccionada', detail: 'Selecciona un area', life: 3000 });
                    return false
                }
            } else if (field === 'Concepto') {
                if (selectedConceptoPago && selectedConceptoPago.cnpg_id) {
                    return true
                }
                else {
                    toast.current?.show({ severity: 'info', summary: 'Concepto de Pago no seleccionado', detail: 'Selecciona un concepto de pago', life: 3000 });
                    return false
                }
            } else {
                return false
            }
        })

        if (newArray.includes(false)) {
            return false
        } else {
            return true
        }
    }

    function formatElements(array1, array2) {
        const result = [];
        const idsArray1 = new Set(array1.map((item) => item.usu_id));
        const idsArray2 = new Set(array2.map((item) => item.usu_id));
        const combined = [...array1, ...array2];
        const uniqueElements = new Map();
        combined.forEach((item) => {
            const aa_status =
                idsArray1.has(item.usu_id) && idsArray2.has(item.usu_id) ? 1 : 0;
            uniqueElements.set(item.usu_id, {
                usu_id: item.usu_id,
                act_id: item.area_id,
                aa_status: aa_status,
            });
        });
        return Array.from(uniqueElements.values());
    }

    const SaveData = async () => {
        if (checkFields()) {
            if (selectedAsignacion && selectedAsignacion.length > 0) {
                const formattedElements = formatElements(selectedAsignacion, trabajadoresArea)
                for (const singleUser of formattedElements) {
                    let datosUser
                    datosUser = { "usu_id": parseInt(singleUser.usu_id), "act_id": parseInt(SelectedActo.act_id), "aa_status": parseInt(singleUser.aa_status) }
                    await ApiServices.apiPost('addAnalistaActo', datosUser)
                }
            }

            let datos
            datos = {
                "act_id": SelectedActo.act_id,
                "area_id": selectedArea.area_id,
                "act_umas": SelectedActo.act_umas,
                "cnpg_id": selectedConceptoPago.cnpg_id,
                "act_dias": SelectedActo.act_dias,
                "act_clave": SelectedActo.act_clave,
            }
            ApiServices.apiPost('updateActo', datos).then(async res => {
                if (res.data.valido == 1) {
                    setVisible(false)
                    getCatActos()
                    showSuccess()
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const SaveUMA = () => {
        let datos
        datos = {
            "setting_id": UMA.setting_id,
            "setting_valor": UMA.setting_valor,
        }

        ApiServices.apiPost('updateUMA', datos).then(async res => {
            if (res.data.valido == 1) {
                setUma(false)
                getUma()
                showSuccess()
                if (checked) {
                    const fechaFormateada = dateProg.toISOString();
                    let datos
                    datos = {
                        "setting_valor": fechaFormateada
                    }

                    ApiServices.apiPost('setProrrogaUMA', datos).then(async res => {
                        if (res.data.valido == 1) {
                            showSuccess()
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }


    const cities1 = [
        { name: 'C001', code: 'NY' },
        { name: 'C002', code: 'RM' },
        { name: 'C003', code: 'LDN' },
    ];
    const cities = [
        { name: 'Certificaciones', code: 'NY' },
        { name: 'Juridico', code: 'RM' },
        { name: 'Registro', code: 'LDN' },
    ];

    const groupedItemTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.label} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.label}</div>
            </div>
        );
    };

    const showSuccess = () => {
        toast.current?.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Se han guardado los cambios exitosamente', life: 3000 });
    }

    const footerContent = (
        <div>
            <Button label="Cancelar" onClick={() => setVisible(false)} severity="secondary" outlined />
            <Button label="Guardar" onClick={SaveData} autoFocus />
            {/* <Button label="Guardar" onClick={() => { { setVisible(false); showSuccess() } }} autoFocus /> */}
        </div>
    );

    const footerContentUMA = (
        <div>
            <Button label="Cancelar" onClick={() => setUma(false)} severity="secondary" outlined />
            <Button label="Guardar" onClick={SaveUMA} autoFocus />
            {/* <Button label="Guardar" onClick={() => { { setVisible(false); showSuccess() } }} autoFocus /> */}
        </div>
    );

    useEffect(() => {
        productService.getProductsSmall().then((data) => setProducts(data));
    }, []);

    const statusBodyTemplate = (product: Product) => {
        return <Tag value={product.inventoryStatus} severity={getSeverity(product)}></Tag>;
    };

    const getSeverity = (product: Product) => {
        switch (product.inventoryStatus) { //EN ESTATUS SOLO APLICA ACTIVADO Y DESACTIVADO
            case 'INSTOCK':
                return 'success';

            case 'LOWSTOCK':
                return 'warning';

            case 'OUTOFSTOCK':
                return 'danger';

            default:
                return null;
        }
    };

    const [trabajadoresArea, setTrabajadoresArea] = useState([])

    const getUsers = (area_id: any) => {
        let datos
        datos = { "code": 106, "area_id": area_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        return ApiServices.processRequestAdd(datos)
    }

    const countryTemplate = (option) => (
        <div className="flex align-items-center">
            <div>{option.usu_trab_id} - {option.usu_nombre}({option.area_desc})</div>
        </div>
    );

    const findWorkers = async (area_id: any, act_id: any) => {
        let users = []

        let datos = {
            area_id: area_id,
            act_id: act_id
        }
        ApiServices.apiPost('getAnalistaActo', datos).then(async resp => {
            let selected
            if (resp.data.addenda && resp.data.addenda.length > 0) {
                selected = resp.data.addenda.map((singleUser) => {
                    if (singleUser.asignado != 0)
                        return singleUser
                })
                setselectedAsignacion(selected.filter(item => item))
            }
            setTrabajadoresArea(resp.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    function filterByUserId(array1, array2) {
        const userIds = array2.map(item => item.usu_id);
        return array1.filter(item => userIds.includes(item.usu_id));
    }

    function findById(array, id) {
        return array.find(item => item.cnpg_id == id);
    }

    function findByAreaId(array, id) {
        return array.find(item => item.area_id == id);
    }

    const HandleActoDetail = async (rowData: any) => {
        if (rowData.cnpg_id) {
            const selectedPago = findById(catConceptos, rowData.cnpg_id)
            setselectedConceptoPago(selectedPago)
        }
        if (rowData.area_id) {
            const selectedArea = findByAreaId(catAreas, rowData.area_id)
            setselectedArea(selectedArea)
        }

        setVisible(true)
        setSelectedActo(rowData)

        findWorkers(rowData.area_id, rowData.act_id)
    }
    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-eye" rounded text severity="secondary" onClick={() => { HandleActoDetail(rowData) }} />
            </div>
        );
    }

    const formatCurrency = (amount: any): any => {
        return amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });
    };

    const priceUMASTemplate = (rowData: any) => {
        if (UMA.setting_valor) {
            return (
                <p>
                    {formatCurrency(parseFloat(rowData.act_umas) * parseFloat(UMA.setting_valor))}
                </p>
            );
        } else {
            return (
                <p>
                    {rowData.act_umas}
                </p>
            );
        }
    }

    function searchByYear(array: any, year: any) {
        const object = array.find((singleItem: any) => singleItem.uma_anio == year);
        return object || null;
    }

    const [previousUma, setPreviousUma] = useState<any>()
    const [selectedYear, setSelectedYear] = useState<any>()

    const [dateProg, setDateProg] = useState<any>()

    const requestHistory = (e: any) => {
        if (e && e.value) {
            setDate(e.value)
            setSelectedYear(e.value.getFullYear())
            let datos = {}
            ApiServices.apiPost('historicoUMA', datos).then(async resp => {
                const found = searchByYear(resp.data.addenda, e.value.getFullYear())
                if (found) {
                    setPreviousUma(found)
                } else {
                    setPreviousUma(null)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const priceUMASPreviousTemplate = (rowData: any) => {
        if (UMA.setting_valor) {
            return (
                <p>
                    {formatCurrency(parseFloat(rowData.act_umas) * parseFloat(previousUma.uma_precio))}
                </p>
            );
        }
    }

    function buscarCoincidencias(texto: any, array: any) {
        const textoMinusculas = texto.toLowerCase();
        const coincidencias = array.filter((objeto: any) => {
            for (const propiedad in objeto) {
                if (objeto[propiedad].toString().toLowerCase().includes(textoMinusculas)) {
                    return true;
                }
            }
            return false;
        });

        return coincidencias;
    }

    const searchActos = (e: any) => {
        const clone = JSON.parse(JSON.stringify(catActosClone))
        setcatActos(buscarCoincidencias(e, clone))
    }

    const paymentConceptTempalte = (option: any) => {
        return (
            <div>
                <p style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', maxWidth: '80%' }}>
                    {option.cnpg_desc}
                </p>
            </div >
        );
    };

    const calendarRef = useRef(null);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const formattedDate = formatDateString(inputValue);
        if (formattedDate) {
            setDateProg(new Date(formattedDate));
        } else {
            setDateProg(null);
        }
        if (calendarRef.current && calendarRef.current.inputElement) {
            calendarRef.current.inputElement.value = formattedDate || inputValue;
        }
    };
    const formatDateString = (input) => {
        if (input.length === 8) {
            const day = input.substring(0, 2);
            const month = input.substring(2, 4);
            const year = input.substring(4, 8);
            return `${month}/${day}/${year}`;
        }
        return null;
    };

    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-10'>
                    <h3>Catálogo de UMAS actos</h3>
                    <p className=''>Agrega un nuevo acto para generar el precio de la UMA, y le aparezca a los ingresadores de pago.
                    </p>
                </div>
                <div className='col-12 md:col-2'>
                    <Button label={'1 UMA = $' + UMA.setting_valor} className="p-button-text" onClick={() => setUma(true)} />
                </div>
                <div className='col-12'>
                    <div className='flex justify-content-end flex-wrap'>
                        <div className='mr-2 col-3'>
                            {/* Input Done NA */}
                            <Calendar
                                value={date}
                                onChange={requestHistory}
                                view="year"
                                dateFormat="yy"
                                showIcon
                                locale="es"
                                maxDate={now}
                                showButtonBar
                                onClearButtonClick={() => {
                                    setPreviousUma(null)
                                    setSelectedYear(null)
                                }}
                            />
                        </div>
                        <div className="flex justify-content-between col-3">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText placeholder="Buscador general" onChange={(e: any) => {
                                    searchActos(e.target.value)
                                }} />
                            </span>
                        </div>
                    </div>
                </div>

                <div className='col-12'>
                    <DataTable value={catActos} paginator rows={10}
                        onSelectionChange={(e) => {
                            const value = e.value;
                            setSelectedProducts(value);
                        }} dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                        <Column sortable field="act_clave" header="ID"></Column>
                        <Column sortable field="act_nombre" header="Acto"></Column>
                        <Column sortable field="act_umas" header="Cantidad de UMAS"></Column>
                        <Column sortable field="act_umas" header="Precio UMAS" body={priceUMASTemplate}></Column>
                        {previousUma && <Column sortable field="act_umas" header={`Precio UMAS ${selectedYear}`} body={priceUMASPreviousTemplate}></Column>}
                        <Column body={actionBodyTemplate} style={{ width: '3rem' }}></Column>
                    </DataTable>
                </div>
            </div>


            <Dialog header="UMAS acto" visible={visible} maximizable style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
                <div className='grid'>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label">

                            <InputText id="act_clave" value={SelectedActo.act_clave} onChange={(e) => { handleActData(e.target.value, e.target.id) }} className="p-inputtext-lg" />
                            <label htmlFor="act_clave">Clave</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-3'>
                        <span className="p-float-label">
                            <InputText id="act_umas" value={SelectedActo.act_umas} onChange={(e) => { handleActData(e.target.value, e.target.id) }} className="p-inputtext-lg" />
                            <label htmlFor="act_umas">UMAS</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-3'>
                        <h5>= {(SelectedActo.act_umas * UMA.setting_valor).toFixed(0) || 0} MNX</h5>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText id="act_nombre" value={SelectedActo.act_nombre} className="p-inputtext-lg" />
                            <label htmlFor="act_nombre">Nombre del acto</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            {/* <MultiSelect value={selectedConceptoPago} onChange={(e) => { setselectedConceptoPago(e.value) }} options={catConceptos} optionLabel="cnpg_desc" display="chip"
                                filter placeholder="Selecciona concepto" maxSelectedLabels={1} className="w-full" /> */}

                            <Dropdown value={selectedConceptoPago} onChange={(e) => setselectedConceptoPago(e.value)} options={catConceptos} optionLabel="cnpg_desc"
                                placeholder="Selecciona concepto"
                                className='p-dropdown-panel-long w-full'
                                filter
                                itemTemplate={paymentConceptTempalte}
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                            <label htmlFor="username">Concepto de pago</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label">
                            <Dropdown value={selectedArea}
                                onChange={(e) => {
                                    setselectedArea(e.value)
                                    findWorkers(e.value.area_id, SelectedActo.act_id)
                                }}
                                options={catAreas}
                                optionLabel="area_desc"
                                className="w-full p-inputtext-lg"
                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                            />
                            <label htmlFor="username">Área</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label">
                            <MultiSelect
                                value={selectedAsignacion}
                                onChange={(e) => setselectedAsignacion(e.value)}
                                options={trabajadoresArea}
                                maxSelectedLabels={10}
                                selectedItemsLabel='Usuarios Seleccionados...'
                                optionLabel="usu_trab_id"
                                placeholder="Asignación" className="w-full"
                                itemTemplate={countryTemplate}
                            />
                            <label htmlFor="username">Asignación</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label">
                            <InputText id="act_dias" value={SelectedActo.act_dias} min={0} max={100} onChange={(e) => { handleActData(e.target.value, e.target.id) }} className="p-inputtext-lg w-full" />
                            <label htmlFor="username">Plazo máximo de resolución</label>
                        </span>
                    </div>
                </div>
            </Dialog>

            <Dialog header="Configuración UMA" visible={visiuma} maximizable style={{ width: '50vw' }} onHide={() => setUma(false)} footer={footerContentUMA}>
                <div className='grid'>
                    <div className='col-12 md:col-12'>
                        <span className="p-float-label">
                            <InputText keyfilter="money" id="uma" value={UMA.setting_valor} onChange={(e) => { handleGeneralData(e, UMA) }} className="p-inputtext-lg" />
                            <label htmlFor="username">Precio UMA actual</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-12'>
                        <p>¿Necesitas una prorroga?</p>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className="field-checkbox">
                            <Checkbox inputId="binary" checked={checked} onChange={() => { setChecked(!checked) }} />
                            <label htmlFor="binary">Si, con el precio del año anterior</label>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 flex justify-content-center p-float-label">
                        {/* Input Done */}
                        <Calendar
                            value={dateProg}
                            onChange={(e) => setDateProg(e.value)}
                            showIcon
                            locale="es"
                            ref={calendarRef}
                            dateFormat="dd/MM/yy"
                            onInput={(e) => handleInputChange(e)}
                        />
                        <label htmlFor="username">Fecha limite de prorroga</label>
                    </div>
                </div>
            </Dialog>
        </section>


    );

}

export default React.memo(CatActos);
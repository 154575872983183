import { memo } from 'react';

interface DatosJson {
    folio: string;
    claveCatastral: string;
    antecedentes: string;
    domicilioRegistral: string;
    calle: string;
    numeroExterior: string;
    numeroInterior: string;
    colonia: string;
    municipio: string;
    estado: string;
    localidad: string;
    codigoPostal: string;
    indiviso: string;
    usoSuelo: string;
    superificieTotal: string;
    restante: string;
    construidas: string;
    medidasColindancias: string;
}

interface DatosProps {
    data: DatosJson
}

const Datos = (props: DatosProps) => {
    return (
        <section>
            <div className='grid'>
                <div className='col-12 md:col-4'>
                    <small>Folio</small>
                    <p className='datos-generales-labels'>{props.data.folio ? props.data.folio : '-'}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>Clave catastral</small>
                    <p className='datos-generales-labels'>{props.data.claveCatastral ? props.data.claveCatastral : '-'}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>Antecedentes</small>
                    <p className='datos-generales-labels'>{props.data.antecedentes ? props.data.antecedentes : '-'}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12' />
                <div className='col-12 md:col-12'>
                    <p className='datos-generales-section-title'>Domicilio registral</p>
                    <p className='datos-generales-labels'>{props.data.domicilioRegistral ? props.data.domicilioRegistral : '-'}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12' />
                <div className='col-12'>
                    <p className='datos-generales-section-title'>Domicilio convencional</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>Calle</small>
                    <p className='datos-generales-labels'>{props.data.calle ? props.data.calle : '-'}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>N.º exterior</small>
                    <p className='datos-generales-labels'>{props.data.numeroExterior ? props.data.numeroExterior : '-'}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>N.º interior</small>
                    <p className='datos-generales-labels'>{props.data.numeroInterior ? props.data.numeroInterior : '-'}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>Colonia</small>
                    <p className='datos-generales-labels'>{props.data.colonia ? props.data.colonia : '-'}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>Municipio</small>
                    <p className='datos-generales-labels'>{props.data.municipio ? props.data.municipio : '-'}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>Estado</small>
                    <p className='datos-generales-labels'>{props.data.estado ? props.data.estado : '-'}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>Localidad</small>
                    <p className='datos-generales-labels'>{props.data.localidad ? props.data.localidad : '-'}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>Código postal</small>
                    <p className='datos-generales-labels'>{props.data.codigoPostal ? props.data.codigoPostal : '-'}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>Indiviso</small>
                    <p className='datos-generales-labels'>{props.data.indiviso ? props.data.indiviso : '-'}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>Uso Suelo</small>
                    <p className='datos-generales-labels'>{props.data.usoSuelo ? props.data.usoSuelo : '-'}</p>
                </div>
            </div>

            <div className='grid'>
                <div className='col-12' />
                <div className='col-12'>
                    <p className='datos-generales-section-title'>Superficie del predio</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>Superficie total</small>
                    <p className='datos-generales-labels'>{props.data.superificieTotal ? props.data.superificieTotal : '-'}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>Restante</small>
                    <p className='datos-generales-labels'>{props.data.restante ? props.data.restante : '-'}</p>
                </div>
                <div className='col-12 md:col-4'>
                    <small>Construidas</small>
                    <p className='datos-generales-labels'>{props.data.construidas ? props.data.construidas : '-'}</p>
                </div>
                <div className='col-12 md:col-12'>
                    <small>Medidas y Colindancias</small>
                    <p className='datos-generales-labels'>{props.data.medidasColindancias ? props.data.medidasColindancias : '-'}</p>
                </div>
            </div>
        </section>
    )
};

export default memo(Datos);
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Row } from 'primereact/row';
import { ScrollTop } from 'primereact/scrolltop';
import { Toast } from 'primereact/toast';
import { TreeSelect } from 'primereact/treeselect';
import React, { useEffect, useRef, useState } from 'react';
import DynamicForm from '../../../../../pages/actos/DynamicForm';
import ApiServices from '../../../../../service/apiServices';
import authGuardService from '../../../../../service/authGuardService';
import EncryptService from '../../../../../service/encrypt.service';
import Historial from '../../../Historial/Historial';

const Actos = (props: any) => {
    /* TemporalData */

    function formatFecha(fecha: any) {
        if (fecha) {
            const fechaObj = new Date(fecha);
            fechaObj.setUTCHours(0, 0, 0, 0);
            const año = fechaObj.getUTCFullYear();
            const mes = (fechaObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const dia = fechaObj.getUTCDate().toString().padStart(2, '0');
            return `${dia}/${mes}/${año}`;
        } else {
            return ''
        }
    }

    /*  */

    const opcionesMenu = useRef<any>(null);
    const actosTable = useRef<any>(null);
    const pagosTable = useRef<any>(null);
    const hijosTable = useRef<any>(null)
    const toast = useRef<any>(null);

    const user = authGuardService.getUser()

    const [loading, setLoading] = useState(false)
    const [selectedNodeKey, setSelectedNodeKey] = useState(null);
    const [total, setTotal] = useState(0.00)
    const [totalPagos, setTotalPagos] = useState(0.00)
    const [actoSelected, setActoSelected] = useState<any>('')
    const [informationForm, setInformationForm] = useState()
    const [dynamicInformationParent, setDynamicInformationParent] = useState<any>()
    const [dynamicFormInformationInside, setDynamicFormInformationInside] = useState()
    const [modalActo, setModalActo] = useState(false);
    const [contieneMultiplesFolios, setContieneMultiplesFolios] = useState(props.hijos && props.hijos.length > 0)
    const [folio, setFolio] = useState('')
    const [folioSeleccionado, setFolioSeleccionado] = useState<any>()
    const [titularesSeleccionados, setTitularesSeleccionados] = useState()
    const [modalFolio, setModalFolio] = useState(false)
    const [selectedInside, setSelectedInside] = useState<any>('')
    const [visibleActoViewInside, setVisibleActoViewInside] = useState<boolean>(false);
    const [previousActoHistoryInside, setPreviousActoHistoryInside] = useState()
    const [tramiteSelectedInside, setTramiteSelectedInside] = useState<any>('')

    const footerPrecioTotal = (
        <ColumnGroup>
            <Row>
                <Column className='text-right' footer="Precio total:" colSpan={4} />
                <Column footer={`$ ${total.toFixed(0)} MXN`} />
            </Row>
        </ColumnGroup>
    );

    const footerPagosTotal = (
        <ColumnGroup>
            <Row>
                <Column className='text-right' footer="Precio total:" colSpan={4} />
                <Column footer={`$ ${totalPagos.toFixed(0)} MXN`} />
            </Row>
        </ColumnGroup>
    );

    const footerActos = () => (
        <div>
            <Button label="Cancelar" onClick={() => setModalActo(false)} outlined disabled={loading} />
            <Button label="Guardar" onClick={handleSaveActo} loading={loading} />
        </div>
    );

    const descripcionTemplate = (data) => {
        const json = data.pago_validacion && JSON.parse(data.pago_validacion)
        return (
            <div>
                <p>{`${json && json.$ && json.$.Descripcion ? json.$.Descripcion : ''}`}</p>
            </div>
        )
    }

    const fechaTemplate = (data) => (
        <p>{formatFecha(data.pago_fecha)}</p>
    )

    const montoDerechosTemplate = (data) => (
        <p>{`$ ${data.pago_monto.toFixed(0)} MXN`}</p>
    )

    const pagoBodyTemplate = (data: any) => {
        return (
            <p>$ {parseFloat(data.valormx).toFixed(0)} MXN</p>
        )
    }

    const titularesTemplate = (data: any) => {
        const titulares = data.proptitutales ? data.proptitutales : data.trm_solicitante
        return (
            <p>{titulares ? titulares : ''}</p>
        )
    }

    const titularesTotalTemplate = (data: any) => {
        const titulares = data.proptitutales ? data.proptitutales.split(',') : '0'
        return (
            <p>{titulares ? titulares.length : 0}</p>
        )
    }

    const optionsTitularesTemplate = (data: any, index: any) => {
        return (
            <div style={{ justifyContent: 'space-around', display: 'flex' }}>
                <i className="pi pi-eye" style={{ fontSize: '1.2rem' }} onClick={() => { openModaltitulares(data) }}
                ></i>
                <i className="pi pi-trash" style={{ fontSize: '1.2rem' }} onClick={() => { eliminarFolio(index.rowIndex) }}></i>
            </div>
        )
    }

    const getTitulares = (propId: any) => {
        let datos = {
            prop_id: propId
        }
        ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
            setTitularesSeleccionados(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const openModaltitulares = (data: any) => {
        setFolioSeleccionado(data)
        setModalFolio(true)
        getTitulares(data.prop_id)
    }

    const eliminarFolio = (index: any) => {
        if (index > -1 && index < props.hijos.length) {
            const cloneFolios = [...props.hijos];
            cloneFolios.splice(index, 1);
            const newArray = cloneFolios.filter(element => element !== null && element !== undefined && element !== '');
            props.setHijos(newArray);
        }
    }

    const getActoInformation = () => {
        let datos = { act_id: actoSelected.act_id }
        ApiServices.apiPost('getCampos', datos).then(async res => {
            setInformationForm(res.data.addenda)
            setModalActo(true)
        }).catch(error => {
            console.log(error)
        })
    }

    function existeActId(array: any, id: any) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].act_id === id) {
                return true;
            }
        }
        return false;
    }

    function buscarObjetoPorActId(array: any, actId: any) {
        for (const item of array) {
            if (item.children) {
                const resultado: any = buscarObjetoPorActId(JSON.parse(JSON.stringify(item.children)), actId);
                if (resultado) {
                    return JSON.parse(JSON.stringify(resultado));
                }
            }
            if (item && item.act_id === actId) {
                return JSON.parse(JSON.stringify(item));
            }
        }
        return null;
    }

    function buscarObjetoPorActClave(array: any, actClave: any) {
        for (const item of array) {
            if (item.children) {
                const resultado: any = buscarObjetoPorActClave(JSON.parse(JSON.stringify(item.children)), actClave);
                if (resultado) {
                    return JSON.parse(JSON.stringify(resultado));
                }
            }
            if (item && item.act_clave == actClave) {
                return JSON.parse(JSON.stringify(item));
            }
        }
        return null;
    }

    const rematchI39 = (array) => {
        console.log('ARRAY: ', JSON.parse(JSON.stringify(array)))
        let ci1 = null;
        let i39 = null;

        array.forEach(item => {
            if (item.act_clave === "CI1") {
                ci1 = item;
            }
            if (item.act_clave === "I39") {
                i39 = item;
            }
        });


        if (ci1 && i39) {
            ci1.valormx = i39.valormx;
            i39.valormx = 0;
        }
        console.log('1: ', ci1)
        console.log('1: ', i39)
        const totalActPesos = array.reduce((sum, item) => sum + item.valormx, 0);

        setTotal(totalActPesos)
        console.log('Array Final: ', array)
        props.setActos(array)
    }

    const addActo = (e: any) => {
        const newArray = JSON.parse(JSON.stringify(props.actos))
        if (!existeActId(newArray, e)) {
            const objetoEncontrado = buscarObjetoPorActId(JSON.parse(JSON.stringify(props.listadoActos)), e);
            if (objetoEncontrado.act_clave == "I39") {
                const founded = buscarObjetoPorActClave(JSON.parse(JSON.stringify(props.listadoActos)), "CI1")
                founded.valormx = JSON.parse(JSON.stringify(objetoEncontrado.valormx))
                objetoEncontrado.valormx = 0
                newArray.push(JSON.parse(JSON.stringify(founded)))
            }
            if (objetoEncontrado) {
                newArray.push(JSON.parse(JSON.stringify(objetoEncontrado)))
            }
        }
        rematchI39(newArray)
    }

    function eliminarObjeto(array: any, objetoAEliminar: any) {
        const nuevoArray = array.filter((objeto: any) => {
            return objeto.act_id !== objetoAEliminar.act_id;
        });

        return nuevoArray;
    }

    const deleteActo = () => {
        let datos = { frmu_id: actoSelected.frmu_id }
        ApiServices.apiPost('deleteRespuestas', datos).then(async res => {
            if (res.data.valido === 1) {
                console.log('Acto Eliminado con Exito: ', res.data)
            }
        }).catch(error => {
            console.log('Error al eliminar el acto: ', error)
            handleActos()
        })

        const newArray = JSON.parse(JSON.stringify(props.actos))
        const deletedArray = eliminarObjeto(newArray, actoSelected)
        props.setActos(deletedArray)
        /* const sumaTotal = sumarValores(deletedArray);
        props.totalFunction(sumaTotal) */
    }

    const opcionesItems = [{
        items: [
            { label: 'Agregar Datos', icon: 'pi pi-pencil', command: () => { getActoInformation() } },
            { label: 'Eliminar', icon: 'pi pi-trash', command: () => { deleteActo() } }
        ]
    }];

    const opcionesItemsView = [{
        items: [
            { label: 'Ver Datos', icon: 'pi pi-eye', command: () => { getActoInformation() } }
        ]
    }];

    const opcionesTemplate = (e: any) => (
        <div className='text-center'>
            <Menu model={props.view ? opcionesItemsView : opcionesItems} popup ref={opcionesMenu} id="opciones_menu" />
            <Button aria-controls="opciones_menu" onClick={(event) => {
                setActoSelected(e)
                opcionesMenu.current.toggle(event)
            }} className="p-button-text p-button-plain" icon="pi pi-ellipsis-v" />
        </div>
    );

    const matchSavedActos = (newActos: any) => {
        let newArray = []
        if (newActos && newActos.length > 0) {
            let total = 0;
            const matchedActos = newActos.map((singleActo: any) => {
                const match = props.listadoActos.find((item: any) => item.act_id == singleActo.act_id)
                if (match) {
                    singleActo.act_pesos = match.valormx
                    singleActo.valormx = match.valormx

                    if (match.valormx) {
                        total = total + parseFloat(match.valormx)
                    }
                }
                return singleActo
            })

            if (props.actos.length > 0) {
                const mapArray2 = new Map(matchedActos.map((obj: any) => [obj.act_id, obj]));
                const mergedArray = props.actos.map((obj1: any) => {
                    const obj2 = mapArray2.get(parseInt(obj1.act_id));
                    return obj2 && typeof obj1 === 'object' && typeof obj2 === 'object' ? { ...obj1, ...obj2 } : obj1;
                });
                newArray = mergedArray;
            }
            else
                newArray = matchedActos

            setTotal(total)
            props.setActos(newArray)
        }
    }

    const handleActos = async () => {
        if (props.id) {
            const dynamicKey0 = Object.keys(props.handleActoID)[0];
            const dynamicValue0 = props.handleActoID[dynamicKey0];

            const dynamicKey1 = Object.keys(props.handleActoID)[1];
            const dynamicValue1 = props.handleActoID[dynamicKey1];

            let datos = {
                [dynamicKey0]: dynamicValue0,
                [dynamicKey1]: dynamicValue1,
            }

            await ApiServices.apiPost('getFormatosResp', datos).then(async res => {
                if (res.data.valido === 1) {
                    if (res.data.addenda && res.data.addenda.length > 0) {
                        matchSavedActos(res.data.addenda)
                    } else {
                        if (props.actosPrevios && props.actosPrevios.length > 0) {
                            const result = [];
                            let total = 0.00

                            props.actosPrevios.forEach(item2 => {
                                const match = props.listadoActos.find(item1 => item1.act_id === item2.acto_id);
                                if (match) {
                                    total = total + match.valormx
                                    result.push({
                                        ...match,
                                        ...item2
                                    });
                                }
                            });
                            rematchI39(result)
                        } else {
                            console.log('No hay Actos Previos')
                        }
                    }
                } else {
                    console.log('No Valido')
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const handleActoModalInside = (information: any) => {
        let datos = {
            ptrm_id: 0,
            trm_id: information.trm_id
        }
        ApiServices.apiPost('getFormatosResp', datos).then(async res => {
            if (res.data.valido === 1 && res.data.addenda.length > 0) {
                const found = res.data.addenda.find((objeto: any) => objeto.act_id === information.act_id);
                if (found && found.frmu_id) {
                    let datos = {
                        act_id: information.act_id
                    }
                    ApiServices.apiPost('getCampos', datos).then(async res => {
                        setDynamicFormInformationInside(res.data.addenda)
                        let merged = { ...information };
                        merged = { ...merged, ...found };
                        setSelectedInside(merged)
                        setVisibleActoViewInside(true)
                        setPreviousActoHistoryInside(information)
                    }).catch(error => {
                        console.log(error)
                    })
                }
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Formulario no encontrado', life: 3000 });
            }
        }).catch(error => {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error en el servidor, intente de nuevo mas tarde', life: 3000 });
        })
    }

    const handleTotalPagos = () => {
        let total = 0
        props.pagosPrevios.forEach(element => {
            if (element && element.pago_monto) {
                const formattedMonto = parseFloat(element.pago_monto)
                total = total + formattedMonto
            }
        });
        setTotalPagos(total)
    }

    const normalFormatMappingCMP = (data: any) => {
        return {
            id: data.cmp_id,
            cmp_tipo: data.cmp_tipo,
            val: data.value || ''
        }
    }

    const formatInformation = (informationToFormat: any) => {
        const flattenedArray = informationToFormat.flat();
        const filteredObjects = flattenedArray.filter((obj: any) => obj.cmp_tipo !== "L" && obj.cmp_tipo !== 'vacio');
        const result = filteredObjects.map(({ cmp_id, frm_id, cmp_tipo, value }: any) => {
            let newValue = value
            if (typeof value == 'object') {
                if (value && value.not_id) {
                    newValue = value.not_id
                } else if (value && value.cp_edo) {
                    newValue = value.cp_edo
                } else if (value && value.cp_mun) {
                    newValue = value.cp_mun
                } else if (cmp_tipo == 'AC') {
                    newValue = value[0]
                } else {
                    newValue = JSON.stringify(value)
                }
            }

            return normalFormatMappingCMP({ cmp_id, frm_id, cmp_tipo, value: newValue })
        });
        return result;
    }

    const buscarDT = () => {
        let element = {}
        if (dynamicInformationParent && typeof dynamicInformationParent == 'object' && dynamicInformationParent.length > 0) {
            dynamicInformationParent.flat().map((singleElement) => {
                if (singleElement.cmp_tipo == 'DT') element = singleElement
            })
        }
        return element
    }

    const handleSaveActo = async () => {
        await props.handleSavePreTramite(false)

        const dynamicKey0 = Object.keys(props.handleActoID)[0];
        const dynamicValue0 = props.handleActoID[dynamicKey0];

        const dynamicKey1 = Object.keys(props.handleActoID)[1];
        const dynamicValue1 = props.handleActoID[dynamicKey1];

        setLoading(true)
        let datos: any
        const formatResponse = formatInformation(dynamicInformationParent)

        let validation100 = true
        let validationClave = true

        formatResponse.filter((comp: any) => comp.cmp_tipo === 'DT' && comp.val.length > 0).forEach((value: any, index: any) => {
            if (value && value.val && value.val.length > 0 && (typeof value.val == 'object')) {
                const oArray: any = buscarDT()

                let nuda = 0
                let nudaEnaje = 0
                let usoFructo = 0
                let usoFructoEnaje = 0

                const formattedTitulares = value.val.map((singleTitular) => {
                    nuda = nuda + parseFloat(singleTitular.ptit_nuda || 0.00)
                    nudaEnaje = nudaEnaje + parseFloat(singleTitular.nuda_enaje || 0.00)
                    usoFructo = usoFructo + parseFloat(singleTitular.ptit_usufructo || 0.00)
                    usoFructoEnaje = usoFructoEnaje + parseFloat(singleTitular.usufructo_enaje || 0.00)

                    return {
                        "cmptdt_id": singleTitular.cmptdt_id || '',
                        "paterno": singleTitular.ptit_pat,
                        "materno": singleTitular.ptit_mat,
                        "nombre": singleTitular.ptit_nom,
                        "rfc": singleTitular.ptit_rfc,
                        "curp": singleTitular.field_5 || singleTitular.cmpdt_curp || '',
                        "nuda": singleTitular.ptit_nuda,
                        "usufructo": singleTitular.ptit_usufructo,
                        "representacion": singleTitular.field_8 || singleTitular.cmptd_representacion || '',
                        "ptit_id": singleTitular.cmptd_valorid || singleTitular.ptit_id,
                        "movimiento": !singleTitular.cmptdt_id ? 1 : singleTitular.ptit_status == 0 ? 3 : 2,
                        'nuda_enaje': singleTitular.nuda_enaje || 0.00,
                        'usufructo_enaje': singleTitular.usufructo_enaje || 0.00,
                        'cmptd_status': !singleTitular.cmptdt_id ? 1 : singleTitular.ptit_status == 0 ? 3 : 2
                    }
                })
                if (oArray.cmp_funcion != 'titulares') {
                    nudaEnaje = 100
                    usoFructoEnaje = 100
                }

                if (nuda > 100 || usoFructo > 100 || nuda < 100 || usoFructo < 100) {
                    validation100 = false
                    if (nuda > 100) {
                        toast.current?.show({ severity: 'warn', summary: 'Nuda Mayor', detail: 'La suma de las nudas es mayor a 100%', life: 3000 });
                        setLoading(false)
                    } else if (nuda < 100) {
                        toast.current?.show({ severity: 'warn', summary: 'Nuda Menor', detail: 'La suma de las nudas es menor a 100%', life: 3000 });
                        setLoading(false)
                    }
                    if (usoFructo > 100) {
                        toast.current?.show({ severity: 'warn', summary: 'Uso Mayor', detail: 'La suma de los Uso Fructo es mayor a 100%', life: 3000 });
                        setLoading(false)
                    } else if (usoFructo < 100) {
                        toast.current?.show({ severity: 'warn', summary: 'Uso Menor', detail: 'La suma de los Uso Fructo es menor a 100%', life: 3000 });
                        setLoading(false)
                    }
                } else {
                    validation100 = true
                }
            } else {
                const parsed = JSON.parse(value.val)
                if (parsed && parsed.length > 0 && (typeof parsed == 'object')) {
                    const oArray: any = buscarDT()

                    let nuda = 0
                    let nudaEnaje = 0
                    let usoFructo = 0
                    let usoFructoEnaje = 0

                    const formattedTitulares = parsed.map((singleTitular) => {
                        nuda = nuda + parseFloat(singleTitular.ptit_nuda || 0.00)
                        nudaEnaje = nudaEnaje + parseFloat(singleTitular.nuda_enaje || 0.00)
                        usoFructo = usoFructo + parseFloat(singleTitular.ptit_usufructo || 0.00)
                        usoFructoEnaje = usoFructoEnaje + parseFloat(singleTitular.usufructo_enaje || 0.00)

                        return {
                            "cmptdt_id": singleTitular.cmptdt_id || '',
                            "concepto": singleTitular?.cmpdt_concepto?.name || '',
                            "paterno": singleTitular.ptit_pat,
                            "materno": singleTitular.ptit_mat,
                            "nombre": singleTitular.ptit_nom,
                            "rfc": singleTitular.ptit_rfc,
                            "curp": singleTitular.field_5 || singleTitular.cmpdt_curp || '',
                            "nuda": singleTitular.ptit_nuda,
                            "usufructo": singleTitular.ptit_usufructo,
                            "representacion": singleTitular.field_8 || singleTitular.cmptd_representacion || '',
                            "ptit_id": singleTitular.cmptd_valorid || singleTitular.ptit_id,
                            "movimiento": !singleTitular.cmptdt_id ? (singleTitular.ptit_status == 0 ? 3 : 2) : singleTitular.ptit_status == 0 ? 3 : 2,
                            'nuda_enaje': singleTitular.nuda_enaje || 0.00,
                            'usufructo_enaje': singleTitular.usufructo_enaje || 0.00,
                            'cmptd_status': !singleTitular.cmptdt_id ? (singleTitular.ptit_status == 0 ? 3 : 2) : singleTitular.ptit_status == 0 ? 3 : 2,
                        }
                    })
                    if (oArray.cmp_funcion != 'titulares') {
                        nudaEnaje = 100
                        usoFructoEnaje = 100
                    }

                    if (nuda > 100 || usoFructo > 100 || nuda < 100 || usoFructo < 100) {
                        validation100 = false
                        if (nuda > 100) {
                            toast.current?.show({ severity: 'warn', summary: 'Nuda Mayor', detail: 'La suma de las nudas es mayor a 100%', life: 3000 });
                            setLoading(false)
                        } else if (nuda < 100) {
                            toast.current?.show({ severity: 'warn', summary: 'Nuda Menor', detail: 'La suma de las nudas es menor a 100%', life: 3000 });
                            setLoading(false)
                        }
                        if (usoFructo > 100) {
                            toast.current?.show({ severity: 'warn', summary: 'Uso Mayor', detail: 'La suma de los Uso Fructo es mayor a 100%', life: 3000 });
                            setLoading(false)
                        } else if (usoFructo < 100) {
                            toast.current?.show({ severity: 'warn', summary: 'Uso Menor', detail: 'La suma de los Uso Fructo es menor a 100%', life: 3000 });
                            setLoading(false)
                        }
                    } else {
                        validation100 = true
                    }
                }
            }
        })

        formatResponse.filter((comp: any) => comp.cmp_tipo === 'DT2' && comp.val.length > 0).forEach((value: any, index: any) => {
            if (value && value.val && value.val.length > 0 && (typeof value.val == 'object')) {
                value.val.map((singleTitular) => {
                    if (singleTitular.prop_clave && singleTitular.prop_clave.length > 0) {
                    } else {
                        toast.current?.show({ severity: 'warn', summary: 'Clave', detail: 'Es necesario que todos los registros tengan una clave.', life: 3000 });
                        validationClave = false
                    }
                })
            } else {
                const parsed = JSON.parse(value.val)
                if (parsed && parsed.length > 0 && (typeof parsed == 'object')) {
                    parsed.map((singleTitular) => {
                        if (singleTitular.prop_clave && singleTitular.prop_clave.length > 0) {
                        } else {
                            toast.current?.show({ severity: 'warn', summary: 'Clave', detail: 'Es necesario que todos los registros tengan una clave.', life: 3000 });
                            validationClave = false
                        }

                    })
                }
            }
        })

        if (actoSelected.frmu_id) {
            datos = {
                code: 402,
                frmu_id: actoSelected.frmu_id,
                usu_id: user.usu_id,
                campos: formatResponse,
                [dynamicKey0]: dynamicValue0,
                [dynamicKey1]: dynamicValue1,
            }
        } else {
            datos = {
                code: 401,
                usu_id: user.usu_id,
                act_id: actoSelected.act_id,
                campos: formatResponse,
                [dynamicKey0]: dynamicValue0,
                [dynamicKey1]: dynamicValue1,
            }
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        if (validation100) {
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    let frmu_id = 0

                    if (actoSelected.frmu_id) {
                        frmu_id = actoSelected.frmu_id
                    } else {
                        frmu_id = res.data.addenda.frmu_id
                    }


                    formatResponse.filter((comp: any) => comp.cmp_tipo === 'DF' && comp.val.length > 0).forEach((value: any, index: any) => {
                        if (value && value.val && value.val.length > 0 && (typeof value.val == 'object')) {
                            value.val.map((singleTitular) => {
                                const newJSON = {
                                    frmu_id: frmu_id,
                                    cmp_id: value.id,
                                    cmp_uno: singleTitular.cmp_uno,
                                    cmp_status: singleTitular.cmp_status,
                                    usu_id: singleTitular.usu_id
                                }


                                ApiServices.apiPost('addControlDinamicoFolio', newJSON).then(async res => {
                                })
                            })
                        } else {
                            const parsed = JSON.parse(value.val)
                            if (parsed && parsed.length > 0 && (typeof parsed == 'object')) {
                                parsed.map((singleTitular) => {
                                    const newJSON = {
                                        frmu_id: frmu_id,
                                        cmp_id: value.id,
                                        cmp_uno: singleTitular.cmp_uno,
                                        cmp_status: singleTitular.cmp_status,
                                        usu_id: singleTitular.usu_id
                                    }


                                    ApiServices.apiPost('addControlDinamicoFolio', newJSON).then(async res => {
                                    })
                                })
                            }
                        }
                    })
                    formatResponse.filter((comp: any) => comp.cmp_tipo === 'DT2' && comp.val.length > 0).forEach((value: any, index: any) => {
                        if (value && value.val && value.val.length > 0 && (typeof value.val == 'object')) {
                            value.val.map((singleTitular) => {
                                ApiServices.apiPost('addPropiedadDT', singleTitular).then(async res => {
                                })
                            })
                        } else {
                            const parsed = JSON.parse(value.val)
                            if (parsed && parsed.length > 0 && (typeof parsed == 'object')) {
                                parsed.map((singleTitular) => {
                                    ApiServices.apiPost('addPropiedadDT', singleTitular).then(async res => {
                                    })
                                })
                            }
                        }
                    })
                    formatResponse.filter((comp: any) => comp.cmp_tipo === 'DT' && comp.val.length > 0).forEach((value: any, index: any) => {
                        if (value && value.val && value.val.length > 0 && (typeof value.val == 'object')) {
                            const oArray: any = buscarDT()

                            let nuda = 0
                            let nudaEnaje = 0
                            let usoFructo = 0
                            let usoFructoEnaje = 0

                            const formattedTitulares = value.val.map((singleTitular) => {
                                nuda = nuda + parseFloat(singleTitular.ptit_nuda || 0.00)
                                nudaEnaje = nudaEnaje + parseFloat(singleTitular.nuda_enaje || 0.00)
                                usoFructo = usoFructo + parseFloat(singleTitular.ptit_usufructo || 0.00)
                                usoFructoEnaje = usoFructoEnaje + parseFloat(singleTitular.usufructo_enaje || 0.00)

                                return {
                                    "cmptdt_id": singleTitular.cmptdt_id || '',
                                    "paterno": singleTitular.ptit_pat,
                                    "materno": singleTitular.ptit_mat,
                                    "nombre": singleTitular.ptit_nom,
                                    "rfc": singleTitular.ptit_rfc,
                                    "curp": singleTitular.field_5 || singleTitular.cmpdt_curp || '',
                                    "nuda": singleTitular.ptit_nuda,
                                    "usufructo": singleTitular.ptit_usufructo,
                                    "representacion": singleTitular.field_8 || singleTitular.cmptd_representacion || '',
                                    "ptit_id": singleTitular.cmptd_valorid || singleTitular.ptit_id,
                                    "movimiento": !singleTitular.cmptdt_id ? 1 : singleTitular.ptit_status == 0 ? 3 : 2,
                                    'nuda_enaje': singleTitular.nuda_enaje || 0.00,
                                    'usufructo_enaje': singleTitular.usufructo_enaje || 0.00,
                                    'cmptd_status': !singleTitular.cmptdt_id ? 1 : singleTitular.ptit_status == 0 ? 3 : 2,
                                    'nuda_adquiere': singleTitular.nuda_adquiere ? singleTitular.nuda_adquiere : 0,
                                    'usufructo_adquiere': singleTitular.usufructo_adquiere ? singleTitular.usufructo_adquiere : 0,
                                    'folio': singleTitular.folio ? singleTitular.folio.prop_folio : 0
                                }
                            })
                            if (oArray.cmp_funcion != 'titulares') {
                                nudaEnaje = 100
                                usoFructoEnaje = 100
                            }

                            if (nuda > 100 || usoFructo > 100 || nuda < 100 || usoFructo < 100) {
                                validation100 = false
                                if (nuda > 100) {
                                    toast.current?.show({ severity: 'warn', summary: 'Nuda Mayor', detail: 'La suma de las nudas es mayor a 100%', life: 3000 });
                                } else if (nuda < 100) {
                                    toast.current?.show({ severity: 'warn', summary: 'Nuda Menor', detail: 'La suma de las nudas es menor a 100%', life: 3000 });
                                }
                                if (usoFructo > 100) {
                                    toast.current?.show({ severity: 'warn', summary: 'Uso Mayor', detail: 'La suma de los Uso Fructo es mayor a 100%', life: 3000 });
                                } else if (usoFructo < 100) {
                                    toast.current?.show({ severity: 'warn', summary: 'Uso Menor', detail: 'La suma de los Uso Fructo es menor a 100%', life: 3000 });
                                }
                            } else {
                                validation100 = true
                                let datos2 = {
                                    code: 507,
                                    "titulares": formattedTitulares,
                                    "frmu_id": frmu_id,
                                    "cmp_id": value.id,
                                    "usu_id": user.usu_id
                                }

                                datos2 = EncryptService.encryptData(JSON.stringify(datos2));
                                ApiServices.processRequestAdd(datos2).then(async res => {
                                    if (res.data.valido === 1) {

                                    }
                                }).catch(error => {
                                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al guardar el tramite', life: 3000 });
                                })
                            }
                        } else {
                            const parsed = JSON.parse(value.val)
                            if (parsed && parsed.length > 0 && (typeof parsed == 'object')) {
                                const oArray: any = buscarDT()

                                let nuda = 0
                                let nudaEnaje = 0
                                let usoFructo = 0
                                let usoFructoEnaje = 0

                                const formattedTitulares = parsed.map((singleTitular) => {
                                    nuda = nuda + parseFloat(singleTitular.ptit_nuda || 0.00)
                                    nudaEnaje = nudaEnaje + parseFloat(singleTitular.nuda_enaje || 0.00)
                                    usoFructo = usoFructo + parseFloat(singleTitular.ptit_usufructo || 0.00)
                                    usoFructoEnaje = usoFructoEnaje + parseFloat(singleTitular.usufructo_enaje || 0.00)

                                    return {
                                        "cmptdt_id": singleTitular.cmptdt_id || '',
                                        "paterno": singleTitular.ptit_pat,
                                        "materno": singleTitular.ptit_mat,
                                        "nombre": singleTitular.ptit_nom,
                                        "rfc": singleTitular.ptit_rfc,
                                        "curp": singleTitular.field_5 || singleTitular.cmpdt_curp || '',
                                        "nuda": singleTitular.ptit_nuda,
                                        "usufructo": singleTitular.ptit_usufructo,
                                        "representacion": singleTitular.field_8 || singleTitular.cmptd_representacion || '',
                                        "ptit_id": singleTitular.cmptd_valorid || singleTitular.ptit_id,
                                        "movimiento": !singleTitular.cmptdt_id ? 1 : singleTitular.ptit_status == 0 ? 3 : 2,
                                        'nuda_enaje': singleTitular.nuda_enaje || 0.00,
                                        'usufructo_enaje': singleTitular.usufructo_enaje || 0.00,
                                        'cmptd_status': !singleTitular.cmptdt_id ? 1 : singleTitular.ptit_status == 0 ? 3 : 2,
                                        'nuda_adquiere': singleTitular.nuda_adquiere ? singleTitular.nuda_adquiere : 0,
                                        'usufructo_adquiere': singleTitular.usufructo_adquiere ? singleTitular.usufructo_adquiere : 0,
                                        'folio': singleTitular.folio ? singleTitular.folio.prop_folio : 0
                                    }
                                })
                                if (oArray.cmp_funcion != 'titulares') {
                                    nudaEnaje = 100
                                    usoFructoEnaje = 100
                                }

                                if (nuda > 100 || usoFructo > 100 || nuda < 100 || usoFructo < 100) {
                                    validation100 = false
                                    if (nuda > 100) {
                                        toast.current?.show({ severity: 'warn', summary: 'Nuda Mayor', detail: 'La suma de las nudas es mayor a 100%', life: 3000 });
                                    } else if (nuda < 100) {
                                        toast.current?.show({ severity: 'warn', summary: 'Nuda Menor', detail: 'La suma de las nudas es menor a 100%', life: 3000 });
                                    }
                                    if (usoFructo > 100) {
                                        toast.current?.show({ severity: 'warn', summary: 'Uso Mayor', detail: 'La suma de los Uso Fructo es mayor a 100%', life: 3000 });
                                    } else if (usoFructo < 100) {
                                        toast.current?.show({ severity: 'warn', summary: 'Uso Menor', detail: 'La suma de los Uso Fructo es menor a 100%', life: 3000 });
                                    }
                                } else {
                                    validation100 = true
                                    let datos2 = {
                                        code: 507,
                                        "titulares": formattedTitulares,
                                        "frmu_id": frmu_id,
                                        "cmp_id": value.id,
                                        "usu_id": user.usu_id
                                    }

                                    datos2 = EncryptService.encryptData(JSON.stringify(datos2));
                                    ApiServices.processRequestAdd(datos2).then(async res => {
                                        if (res.data.valido === 1) {

                                        }
                                    }).catch(error => {
                                        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al guardar el tramite', life: 3000 });
                                    })
                                }
                            }
                        }
                    })

                    toast.current.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Se ha guardado el Acto', life: 3000 })
                    setModalActo(false)
                    handleActos()
                    setLoading(false)
                }
            }).catch(error => {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error al guardar el Acto', life: 3000 });
            })
        }
    }

    const buscarFolio = () => {
        if (folio) {
            let datos
            datos = {
                code: 208,
                prop_folio: folio,
                prop_clave: '',
                ptit_nom: '',
                ptit_pat: '',
                ptit_mat: '',
                prop_direccion: '',
                prop_calle: '',
                prop_ext: '',
                prop_int: '',
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido == 1) {
                    const newFolios = JSON.parse(JSON.stringify(props.hijos))
                    if (res.data.addenda && res.data.addenda.length > 0) {
                        setFolio('')
                        const newArray = newFolios.concat(res.data.addenda)
                        props.setHijos(newArray)
                    }
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Sin Resultados',
                        detail: res.data.mensaje,
                        life: 4000
                    });
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const reordenarActos = (newValues: any) => {

        const dynamicKey0 = Object.keys(props.handleActoID)[0];
        const dynamicValue0 = props.handleActoID[dynamicKey0];

        const dynamicKey1 = Object.keys(props.handleActoID)[1];
        const dynamicValue1 = props.handleActoID[dynamicKey1];


        const localArray = []
        const ordered = newValues && newValues.map((singleValue, index) => {
            singleValue[props.consecutivo] = index + 1
            singleValue.frmu_consecutivo = index + 1
            localArray.push(singleValue)
            return {
                act_id: singleValue.act_id,
                ta_consecutivo: index + 1
            }
        })

        let datos
        datos = { [dynamicKey0]: dynamicValue0, [dynamicKey1]: dynamicValue1, actos: ordered }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido === 1) {
                toast.current?.show({ severity: 'success', summary: 'Actos Ordenados', detail: 'Se Ordenaron los actos correctamente.', life: 3000 });
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error al Ordenar', detail: 'Hubo un error al ordenar los actos.', life: 3000 });
            }
        }).catch(error => {
            toast.current?.show({ severity: 'error', summary: 'Error al Ordenar', detail: 'Hubo un error al ordenar los actos.', life: 3000 });
        })


        props.setActos(localArray)
    }

    useEffect(() => {
        handleActos()
    }, [])

    useEffect(() => {
        if (props && props.pagosPrevios && props.pagosPrevios.length > 0) {
            handleTotalPagos()
        }
    }, [props])

    return (
        <section>
            <Toast ref={toast} />
            <Divider />
            <ScrollTop icon="pi pi-chevron-up" />

            {/* Header */}
            <div className='grid'>
                <div className='col-12 md:col-4'>
                    {props.view ?
                        <h3 className='color-violet'>Actos</h3>
                        :
                        <p>Ingresa un acto para editarlo</p>
                    }
                </div>
                <div className='col-12 md:col-6'>
                    {!props.view &&
                        <span className="p-float-label">
                            <TreeSelect
                                value={selectedNodeKey}
                                onChange={(e) => addActo(e.value)}
                                onNodeSelect={() => { }}
                                options={props.listadoActosCategorias}
                                filter
                                className="w-full"
                                placeholder="Selecciona un Acto para agregar"
                            ></TreeSelect>
                            <label htmlFor="dd-city">Acto</label>
                        </span>
                    }
                </div>
                <div className='col-12 md:col-2' style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ margin: 0, paddingRight: 10 }}>Afecta Multiples Folios</p>
                        <Checkbox
                            disabled={props.view}
                            onChange={e => setContieneMultiplesFolios(!contieneMultiplesFolios)}
                            checked={contieneMultiplesFolios}
                        />
                    </div>
                </div>
            </div>

            {/* Tabla de Actos */}
            <div className='pt-4'>
                <DataTable
                    ref={actosTable}
                    reorderableRows={!props.view}
                    value={props.actos}
                    footerColumnGroup={footerPrecioTotal}
                    dataKey="id"
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    className="datatable-responsive"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    emptyMessage="Sin Actos Agregados"
                    sortField={props.consecutivo}
                    sortOrder={1}
                    onRowReorder={(e) => reordenarActos(e.value)}
                    tableStyle={{ minWidth: '50rem' }}
                >
                    <Column rowReorder style={{ width: '3rem' }} />
                    <Column field={props.consecutivo} header="Consecutivo" style={{ width: '10%' }}></Column>
                    <Column field="act_clave" header="Clave" sortable ></Column>
                    <Column field="act_nombre" header="Acto" sortable ></Column>
                    <Column field="valormx" header="Derechos" body={pagoBodyTemplate} style={{ width: '10rem', textAlign: 'center' }}></Column>
                    <Column field="price" body={(e) => opcionesTemplate(e)}></Column>
                </DataTable>
            </div>

            {/* Tabla de Pagos */}
            {props.pagosPrevios && props.pagosPrevios.length > 0 &&
                <div>
                    <h4 className='color-violet' style={{ marginTop: 15 }}>Pagos realizados</h4>
                    <DataTable
                        ref={pagosTable}
                        value={props.pagosPrevios}
                        footerColumnGroup={footerPagosTotal}
                        dataKey="pago_id"
                        paginator
                        rows={5}
                        className="datatable-responsive"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} pagos"
                        emptyMessage="No se encontraron pagos"
                    >
                        <Column field="pago_serie" header="Serie" sortable></Column>
                        <Column field="pago_folio" header="Folio" sortable></Column>
                        <Column header="Descripción" body={descripcionTemplate} ></Column>
                        <Column field="pago_fecha" header="Fecha de pago" sortable body={fechaTemplate} ></Column>
                        <Column field="price" header="Monto de derechos" body={montoDerechosTemplate} style={{ width: '10rem' }}></Column>
                    </DataTable>
                </div>
            }

            {contieneMultiplesFolios &&
                <div className='grid' style={{ marginTop: 20 }}>
                    <div className='col-12 md:col-6'>
                        <h3 className='color-violet'>Múltiples folios</h3>
                        <p>En caso de que afecte múltiples folios, ingresa los folios necesarios para su registro.</p>
                    </div>
                    <div className='col-12 md:col-6' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, width: '100%', justifyContent: 'space-between' }} >
                            <InputText
                                value={folio}
                                onChange={(e) => setFolio(e.target.value)}
                                style={{ width: '75%' }}
                            />
                            <Button label="Agregar" style={{ width: '20%' }} onClick={buscarFolio} />
                        </div>
                    </div>
                    <div className='col-12 md:col-12'>
                        <DataTable
                            ref={hijosTable}
                            value={props.hijos}
                            dataKey="prop_id"
                            paginator
                            rows={5}
                            className="datatable-responsive"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pagos"
                            emptyMessage="No products found."
                        >
                            <Column field="prop_folio" header="Folio" sortable ></Column>
                            <Column field="proptitutales" header="Titulares" sortable body={titularesTemplate}></Column>
                            <Column field="price" header="Total Titulares" body={titularesTotalTemplate}></Column>
                            <Column field="icons" header="" body={optionsTitularesTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            }

            <Historial
                historial={props.historial}
                preTramiteFolio={props.detallePreTramite}
                propiedadDetail={props.detallePropiedad}
            />

            {/* Modal de Actos */}
            <Dialog
                header={`${(actoSelected && actoSelected.act_nombre) || ''}`}
                maximizable
                visible={modalActo}
                style={{ width: '70%' }}
                onHide={() => setModalActo(false)}
                footer={!props.view && footerActos}
            >
                <DynamicForm
                    edicion={true}
                    tramite={props.detalleTramite}
                    preTramiteFolio={props.detallePreTramite}
                    propiedadDetail={props.detallePropiedad}
                    informationForm={informationForm}
                    information={actoSelected}
                    actoHistory={props.historial}
                    setDynamicInformationParent={setDynamicInformationParent}
                    getActoInformationView={handleActoModalInside}
                    setTramiteSelected={setTramiteSelectedInside}
                />
            </Dialog>

            {/* Modal Ver Acto Dentro Acto*/}
            <Dialog
                header={`${(selectedInside && selectedInside.act_nombre) || ''}`}
                maximizable
                visible={visibleActoViewInside}
                style={{ width: '50%' }}
                onHide={() => setVisibleActoViewInside(false)}
            >
                <DynamicForm
                    propiedadDetail={props.propiedadDetail}
                    informationForm={dynamicFormInformationInside}
                    information={selectedInside}
                    setDynamicInformationParent={setDynamicInformationParent}
                    titulares={props.titulares}
                    previousActoHistory={previousActoHistoryInside}
                    tramite={tramiteSelectedInside}
                    actoHistory={props.actoHistory}
                    setSelected={setSelectedInside}
                    getActoInformationView={handleActoModalInside}
                    detail={props.propiedadDetail}
                    setActoHistory={props.setActoHistory}
                    actoHistoryClone={props.actoHistoryClone}
                    setTramiteSelected={setTramiteSelectedInside}
                />
            </Dialog>

            {/* Modal de Titulares */}
            <Dialog
                header="Titulares"
                visible={modalFolio}
                style={{ width: '70%' }}
                onHide={() => setModalFolio(false)}
            >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 30 }}>
                    <div className="flex flex-column gap-2" style={{ width: '30%', marginRight: 20 }}>
                        <label htmlFor="username">Folio</label>
                        <InputText id="username" disabled value={folioSeleccionado && folioSeleccionado.prop_folio} />
                    </div>
                    <div className="flex flex-column gap-2" style={{ width: '30%' }}>
                        <label htmlFor="username">Clave Catastral</label>
                        <InputText id="username" disabled value={folioSeleccionado && folioSeleccionado.prop_clave} />
                    </div>
                </div>
                <p className="m-0" style={{ marginTop: 30 }}>
                    <DataTable
                        value={titularesSeleccionados}
                        dataKey="ptit_id"
                        paginator
                        rows={5}
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pagos"
                        emptyMessage="No products found."
                    >
                        <Column field="ptit_nom" header="Nombre" sortable ></Column>
                        <Column field="ptit_pat" header="Primer Apellido" sortable ></Column>
                        <Column field="ptit_nom" header="Segundo Apellido" ></Column>
                        <Column field="ptit_rfc" header="RFC" ></Column>
                        <Column field="ptit_nuda" header="Nuda %" ></Column>
                        <Column field="ptit_usufructo" header="Usofructo %" ></Column>

                    </DataTable>
                </p>
            </Dialog>
        </section >
    );

}

export default React.memo(Actos);
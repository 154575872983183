import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import authGuardService from '../../../../service/authGuardService';
import Tab from '../../../../Components/Tabs/Tab';
import Tabs from '../../../../Components/Tabs/Tabs';
import ApiServices from '../../../../service/apiServices';
import TabGenerales from './TabViewFolio/generales';
import TabHistorialActo from './TabViewFolio/HistorialActo';
import TabTitulares from './TabViewFolio/titulares';
import EditCaratula from '../../../Interno/ResulFolio/ViewFolio/EditCaratula';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

interface City {
    name: string;
}

const VenViewFolioMorales = () => {

    const location = useLocation();
    const user = authGuardService.getUser()

    const generalData = location.state.folios[0]

    const [titulares, setTitulares] = useState([])
    const [actoHistory, setActoHistory] = useState()
    const [actoHistoryLoading, setActoHistoryLoading] = useState(true)
    const [actoHistoryClone, setActoHistoryClone] = useState()

    useEffect(() => {
        getTitulares()
        getActoHistory()
    }, [generalData])

    const getTitulares = () => {
        let datos = {
            per_folio: generalData.prop_id
        }
        ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
            setTitulares(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    function groupObjectsByTrmId(inputArray: any) {
        // Ordenar los objetos por fecha antes de agruparlos
        inputArray.sort((a, b) => {
            return a.trm_fecha.localeCompare(b.trm_fecha);
        });

        const groupedObjects: any = {};

        inputArray.forEach((obj) => {
            const trmFecha = obj.trm_fecha;

            if (!groupedObjects[trmFecha]) {
                groupedObjects[trmFecha] = {
                    trm_fecha: trmFecha,
                    history: []
                };
            }

            groupedObjects[trmFecha].history.push(obj);
        });

        return Object.values(groupedObjects);
    }

    function filtrarPorFrmuStatus(data: any) {
        return data.filter((obj: any) => {
            const alMenosUnoFrmuUno = obj.history.some((historyObj: any) => historyObj.frmu_status === 1);
            return alMenosUnoFrmuUno;
        }).map((obj: any) => ({
            ...obj,
            history: obj.history.filter((historyObj: any) => historyObj.frmu_status === 1)
        }));
    }


    const getActoHistory = () => {
        let datos = {
            per_folio: generalData.per_folio
        }
        ApiServices.apiPost('getHistorialPM', datos).then(async res => {
            setActoHistory(res.data.addenda)
            setActoHistoryClone(res.data.addenda)
            setActoHistoryLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }

    const [displayMCFolio, setDisplayMCFolio] = useState(false);


    return (
        <section className='container-fluid'>
            <div className='flex justify-content-between'>
                {/* cuando sean folios de calidad aparecera el color verde se mostrara como el div, si no. se negara el small y el div 0.1 siguiente */}

                <div className='flex col-12 md:col-4'>
                    {generalData && generalData.prop_calidad == 1 &&
                        <div className='text-center'>
                            <i className='pi pi-check-circle verde'></i>
                            <div className='line'></div>
                        </div>
                    }

                    {generalData && generalData.prop_calidad == 2 &&
                        <div className='text-center'>
                            <i className='pi pi-check-circle rojo'></i>
                            <div className='lineRed'></div>
                        </div>
                    }

                    {generalData && generalData.prop_calidad == 3 &&
                        <div className='text-center'>
                            <i className='pi pi-check-circle naranja'></i>
                            <div className='lineOrange'></div>
                        </div>
                    }

                    <div className={
                        (generalData && generalData.prop_calidad == 1) ? 'verde' :
                            (generalData && generalData.prop_calidad == 2) ? 'rojo' :
                                (generalData && generalData.prop_calidad == 3) ? 'naranja' :
                                    ''
                    }>
                        <h3 className={
                            (generalData && generalData.prop_calidad == 1) ? 'verde' :
                                (generalData && generalData.prop_calidad == 2) ? 'rojo' :
                                    (generalData && generalData.prop_calidad == 3) ? 'naranja' :
                                        ''
                        }>Folio Persona Moral {generalData ? generalData.per_folio : ''}</h3>
                        {generalData && generalData.prop_calidad == 1 &&
                            <small>Este es un folio de calidad</small>
                        }
                        {generalData && generalData.prop_calidad == 2 &&
                            <h6>Este es un folio cerrado</h6>
                        }
                        {generalData && generalData.prop_calidad == 3 &&
                            <h6>Advertencia</h6>
                        }
                    </div>
                </div>
            </div>

            <div className='flex justify-content-end flex-wrap'>
                <div className='position-btns'>
                    <div className='flex justify-content-end flex-wrap'>
                        {/*                         {user && user.usu_edicionfolio == 1 &&
 */}                            <div className='pr-3'>
                            <Button onClick={() => { setDisplayMCFolio(true) }} tooltip="Editar Caratula" tooltipOptions={{ position: 'top' }} severity="secondary" icon="pi pi-file-edit" rounded />
                        </div>
                        {/* { */}
                    </div>
                </div>
            </div>

            <div className=''>
                <Tabs>
                    <Tab label="Datos generales">
                        <TabGenerales generalData={generalData} />
                    </Tab>
                    <Tab label="Actos">
                        <TabHistorialActo
                            generalData={generalData}
                            actoHistory={actoHistory}
                            setActoHistory={setActoHistory}
                            actoHistoryClone={actoHistoryClone}
                            actoHistoryLoading={actoHistoryLoading}
                        />
                    </Tab>
                    <Tab label="Titulares">
                        <TabTitulares generalData={generalData} />
                    </Tab>
                    {/* <Tab label="Notificaciones">
                        <ResulNotificador />
                    </Tab> */}
                </Tabs>
            </div>

            {/*modificacion caratula*/}
            <Dialog
                header="Edición de datos"
                visible={displayMCFolio}
                maximizable
                modal
                style={{ width: '70vw' }}
                onHide={() => setDisplayMCFolio(false)}
            >
                <EditCaratula
                    data={{
                        id: generalData && generalData.prop_id,
                        folio: generalData && generalData.prop_folio,
                        claveCatastral: generalData && generalData.prop_clave,
                        antecedentes: generalData && generalData.prop_antecedente,
                        domicilioRegistral: generalData && generalData.prop_direccion,
                        codigoPostal: generalData && generalData.cp_cp && (typeof generalData.cp_cp == 'object' ? (generalData.length > 1 ? generalData.cp_cp[1] : generalData.cp_cp[0]) : generalData.cp_cp),
                        localidad: generalData && generalData.cp_loc,
                        municipio: generalData && generalData.cp_mun,
                        estado: generalData && generalData.cp_edo,
                        cruzamiento: generalData && generalData.prop_cruzamientos,
                        colonia: generalData && generalData.cp_col,
                        numeroInterior: generalData && generalData.prop_int,
                        numeroExterior: generalData && generalData.prop_ext,
                        calle: generalData && generalData.prop_calle,
                        medidasColindancias: generalData && generalData.prop_medidas,
                        construida: generalData && generalData.prop_constr,
                        superficieTotal: generalData && generalData.prop_suptotal,
                        titulares: titulares
                    }}
                    activarModal={() => { setDisplayMCFolio(true) }}
                    desactivarModal={() => { setDisplayMCFolio(false) }}
                />
            </Dialog>

        </section>
    );
}

export default React.memo(VenViewFolioMorales);



import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiServices from '../../service/apiServices';
import authGuardService from '../../service/authGuardService';
import EncryptService from '../../service/encrypt.service';
import configuration from '../../configurationFile';
import { TieredMenu } from 'primereact/tieredmenu';
import NotTramites from 'pages/Notarios/NotTramites/NotTramites';

const VenTrabInicio = () => {

    const user = authGuardService.getUser()

    const currentDate = new Date();
    const isoString = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}.${currentDate.getMilliseconds().toString().padStart(3, '0')}Z`;

    const [list, setList] = useState([])
    const [loadingList, setLoadingList] = useState(true)

    useEffect(() => {
        getPreCaptura()
    }, [])

    const getPreCaptura = () => {
        let datos
        datos = { "code": 207, usu_id: user.usu_id, texto: '', fecha: isoString }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            setList(res.data.addenda)
            setLoadingList(false)
        }).catch(error => {
            console.log(error)
        })
    }

    const statusBodyTemplate = (tramite: any) => {
        const statusType = getStatusType(tramite.trm_status)
        return <Tag value={statusType} severity={getSeverity(tramite.trm_status)}></Tag>;
    };

    const getStatusType = (trm_status: any) => {
        switch (trm_status) {
            case 0:
                return 'Borrador';
            case 1:
                return 'Capturado';
            case 2:
                return 'Asignado';
            case 3:
                return 'Analizado';
            case 4:
                return 'Rechazado';
            case 5:
                return 'Por Subsanar';
            case 6:
                return 'Subsanado';
            case 7:
                return 'Por Firmar Aceptado';
            case 8:
                return 'Por Firmar Rechazado';
            case 9:
                return 'Firmado Aceptado';
            case 10:
                return 'Firmado Rechazado';
            default:
                return null;
        }
    };

    const getSeverity = (trm_status: any) => {
        switch (trm_status) {
            case 0:
                return 'danger'
            case 1:
                return 'info'
            case 2:
                return 'warning';
            case 3:
                return 'danger';
            case 4:
                return 'warning';
            case 5:
                return 'success';
            case 6:
                return 'info'
            case 7:
                return 'warning';
            case 8:
                return 'danger';
            case 9:
                return 'success';
            case 10:
                return 'success';
            default:
                return null;
        }
    };



    const [globalFilter, setGlobalFilter] = useState<any>(null);

    const header = (
        <div className='flex justify-content-end'>
            <div className='col-12 md:col-4'>
                <span className="p-float-label p-input-icon-left w-100">
                    <i className="pi pi-search" />
                    <InputText className=' p-inputtext-lg' type="search" onInput={(e) => setGlobalFilter(e.currentTarget.value)} />
                    <label htmlFor="username">Buscador general</label>
                </span>
            </div>
        </div>
    );

    /* OLD */
    const navigate = useNavigate();
    const toast = useRef<Toast>(null);


    const downloadPDF = async (id: any) => {
        let datos
        datos = { "code": 213, "trm_id": id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.valido == 0) {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error', life: 3000 });
                } else {
                    window.open(`${configuration.url}${res.data.addenda}`)
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }


    const actionBodyTemplate = (rowData: any) => {
        const menu = useRef(null);

        const navigateOptions = {
            state: {
                tramite: rowData
            }
        };
        return (
            <div className="actions flex">
                <Button
                    icon="pi pi-eye"
                    rounded
                    text
                    severity="secondary"
                    onClick={() => { navigate('/ventanilla/vt-tramite-view', navigateOptions) }}
                />
                <TieredMenu model={[
                    {
                        label: 'Descargar Boleta de Ingreso',
                        icon: 'pi pi-file-export',
                        command: () => {
                            downloadPDF(rowData.trm_id)
                        }
                    }
                ]} popup ref={menu} breakpoint="767px" />
                <Button icon="pi pi-ellipsis-h" rounded text severity="secondary" onClick={(e) => menu.current.toggle(e)} />
            </div>
        );
    }

    const actionDateTemplate = (rowData: any) => {
        const fechaUTC = new Date(rowData.trm_fecha);
        const horaUTC = new Date(rowData.trm_hora);

        const day = fechaUTC.getUTCDate().toString().padStart(2, '0');
        const month = (fechaUTC.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = fechaUTC.getUTCFullYear();
        const hours = horaUTC.getUTCHours().toString().padStart(2, '0');
        const minutes = horaUTC.getUTCMinutes().toString().padStart(2, '0');
        const seconds = horaUTC.getUTCSeconds().toString().padStart(2, '0');


        const fechaFormateada = `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
        return <p>{fechaFormateada}</p>
    }

    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-8'>
                    <h3 className='color-violet'>Ingreso de Trámites</h3>
                    <p>Busca algún control interno para ver el estatus</p>
                </div>
                <div className='col-12'>
                    <DataTable
                        value={list}
                        paginator
                        rows={10}
                        dataKey="trm_id"
                        loading={loadingList}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron tramites."
                        header={header}
                    >
                        <Column sortable field="trm_consecutivo" header="# Control int."></Column>
                        <Column sortable field="trm_update" header="Fecha" body={actionDateTemplate}></Column>
                        <Column sortable field="prop_folio" header="Folio electronico"></Column>
                        <Column sortable field="prop_clave" header="Clave catastral"></Column>
                        <Column field="actos" header="Actos"></Column>
                        <Column sortable field="name" header="Estatus" body={statusBodyTemplate} ></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>

        </section>
    );

}

export default React.memo(VenTrabInicio);
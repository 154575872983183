
import { Toast } from 'primereact/toast';
import { memo, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tab from '../../../Components/Tabs/Tab';
import Tabs from '../../../Components/Tabs/Tabs';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import DatosGenerales from '../PagoResulFolio/Folio/DatosGenerales/DatosGenerales';
import MainFooter from '../PagoResulFolio/Folio/Footer/MainFooter';
import MainHeader from '../PagoResulFolio/Folio/Header/MainHeader';
import Titulares from '../PagoResulFolio/Folio/Titulares/Titulares';

const NuevoPreTramite = (props: any) => {

    const navigate = useNavigate();
    const user = authGuardService.getUser()

    const toast = useRef(null);

    const pagosAnteriores = props.pagosAnteriores

    const [activeIndex, setActiveIndex] = useState(0)
    const [id, setId] = useState<string>('') // ID del PreTramite o Tramite (Solo se usa una vez creado)
    const [tramiteConsecutivo, setTramiteConsecutivo] = useState<string>('0') // Tramite Consecutivo (Solo se usa una vez creado)
    const [fechaTramite, setFechaTramite] = useState<Date>() // Fecha del Tramite (Solo se usa una vez creado)
    const [horaTramite, setHoraTramite] = useState<Date>() // Hora del tramite (Solo se usa una vez creado)
    const [folio, setFolio] = useState('0') // Folio de la propiedad
    const [propiedadId, setPropiedadId] = useState<string>('0') // ID de la propiedad
    const [calidadFolio, setCalidadFolio] = useState(0) // Calidad del Folio
    const [listadoNotarios, setListadoNotarios] = useState<any>([]) // Listado de Notarios
    const [numeroDeEscritura, setNumeroDeEscritura] = useState<string>('') // Numero de Escritura
    const [notariaSeleccionada, setNotariaSeleccionada] = useState<any>(null) // Notaria Seleccionada
    const [nombreSolicitante, setNombreSolicitante] = useState<string>('') // Nombre del Solicitante
    const [titulares, setTitulares] = useState([])
    const [titularesTamaño, setTitularesTamaño] = useState(0)
    const [dataGeneral, setDataGeneral] = useState<any>({
        folio: '',
        claveCatastral: '',
        antecedentes: '',
        domicilioRegistral: '',
        codigoPostal: '',
        localidad: '',
        municipio: '',
        estado: '',
        colonia: '',
        numeroExterior: '',
        numeroInterior: '',
        calle: '',
        medidasColindancias: '',
        construidas: '',
        superificieTotal: ''
    })

    const seleccionarTitular = () => {
        setNombreSolicitante(titulares ? `${titulares[titularesTamaño].ptit_nom} ${titulares[titularesTamaño].ptit_pat} ${titulares[titularesTamaño].ptit_mat}` : nombreSolicitante)
        const lenghtSize = titulares ? titulares.length : 0
        const selectedIndex = (titularesTamaño + 1) % lenghtSize;
        setTitularesTamaño(selectedIndex)
    }

    const seleccionarNotaria = (not_id: number | string) => {
        for (let notario of listadoNotarios) {
            if (notario.not_id == not_id) {
                setNotariaSeleccionada(notario)
            }
        }
    }

    const handleProps = () => {
        if (props.detallePropiedad) {
            setFolio(props.detallePropiedad.prop_folio)
            setPropiedadId(props.detallePropiedad.prop_id)
            setCalidadFolio(props.detallePropiedad.prop_calidad)
            setDataGeneral({
                folio: props.detallePropiedad.prop_folio || '',
                claveCatastral: props.detallePropiedad.prop_clave || '',
                antecedentes: props.detallePropiedad.prop_antecedente || '',
                domicilioRegistral: props.detallePropiedad.prop_direccion || '',
                codigoPostal: props.detallePropiedad.cp_cp || '',
                localidad: props.detallePropiedad.cp_loc || '',
                municipio: props.detallePropiedad.cp_mun || '',
                estado: props.detallePropiedad.cp_edo || '',
                colonia: props.detallePropiedad.cp_col || '',
                numeroExterior: props.detallePropiedad.prop_ext || '',
                numeroInterior: props.detallePropiedad.prop_int || '',
                calle: props.detallePropiedad.prop_calle || '',
                medidasColindancias: props.detallePropiedad.prop_medidas || '',
                construidas: props.detallePropiedad.prop_constr || '',
                superificieTotal: props.detallePropiedad.prop_suptotal || ''
            })
        }
        if (props.titulares) setTitulares(props.titulares)
        if (props.listadoNotarios) setListadoNotarios(props.listadoNotarios)
    }

    const mostrarActosNoDisponible = () => {
        toast.current.show({ severity: 'info', summary: 'Actos', detail: 'Para agregar actos primero debes de guardar tu Pre Tramite' });
    };

    const obtenerIdsPagos = () => {
        if (pagosAnteriores && pagosAnteriores.length > 0) {
            const ids = pagosAnteriores.map((element: any) => {
                return element.pago_id
            })
            return ids
        }
    }

    const obtenerActosDePagos = () => {
        if (pagosAnteriores && pagosAnteriores.length > 0) {
            if (pagosAnteriores && pagosAnteriores.length > 0) {
                const actIds = pagosAnteriores.flatMap((pago) => {
                    if (pago && pago.actos && pago.actos.length > 0) {
                        return pago.actos.map((acto) => acto.act_id);
                    } else {
                        return [];
                    }
                });
                return actIds;
            }
            return [];
        }
    }

    const handleSavePreTramite = () => {
        const actos = obtenerActosDePagos()
        const pagos = obtenerIdsPagos()

        let datos: any = {
            code: 302,
            usu_id: user.usu_id,
            prop_id: propiedadId,
            ptrm_solicitante: nombreSolicitante,
            not_id: notariaSeleccionada ? notariaSeleccionada.not_id : user.not_id,
            ptrm_escritura: numeroDeEscritura,
            actos: actos || [],
            pagos: pagos || [],
        }

        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                const newData = res.data.addenda
                newData.prop_folio = folio
                const navigateOptions = {
                    state: {
                        preTramite: newData
                    }
                };
                navigate('/notarios/pago-folio', navigateOptions)
            } else {
                console.log('No valido')
            }
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (props)
            handleProps()
    }, [props])

    useEffect(() => {
        if (listadoNotarios && listadoNotarios.length > 0) {
            seleccionarNotaria(user.not_id)
        }
    }, [listadoNotarios])

    return (
        <section>
            <Toast ref={toast} />
            <div className='container-fluid'>
                <MainHeader
                    data={{
                        folio: folio,
                        consecutivo: tramiteConsecutivo,
                        fecha: fechaTramite,
                        hora: horaTramite,
                        calidadFolio: calidadFolio,
                        lineaDelTiempo: null
                    }}
                />

                <div className=''>
                    <Tabs activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <Tab label="Datos generales">
                            <DatosGenerales
                                data={{
                                    id: id,
                                    view: false,
                                    catalogoNotarias: listadoNotarios,
                                    numeroEscritura: numeroDeEscritura,
                                    notariaSeleccionada: notariaSeleccionada,
                                    solicitador: nombreSolicitante,
                                    documentos: [],
                                    general: dataGeneral,
                                    titulares: titulares && titulares.length > 0
                                }}
                                refreshInformation={seleccionarTitular}
                                setSolicitador={setNombreSolicitante}
                                setNotariaSeleccionada={setNotariaSeleccionada}
                                setNumeroEscritura={setNumeroDeEscritura}
                                seleccionarTitular={seleccionarTitular}
                                docsIDs={["trmd_id", "trmd_desc"]}
                            />
                        </Tab>
                        <Tab label="Actos" onClick={mostrarActosNoDisponible} disabled>
                            <p>Actos</p>
                        </Tab>

                        <Tab label="Titulares">
                            <Titulares data={{ titulares: titulares }} />
                        </Tab>
                    </Tabs>
                </div>
            </div >

            <MainFooter
                data={{
                    buttons: [
                        {
                            label: "Guardar Borrador",
                            outlined: true,
                            function: (handleSavePreTramite)
                        }
                    ]
                }}
            />
        </section >
    );
}

export default memo(NuevoPreTramite);



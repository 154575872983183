import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { memo, useRef, useState } from 'react';
import ApiServices from '../../../../../service/apiServices';
import Datos from './SubComponentes/Datos';
import Documentos from './SubComponentes/Documentos';
import Solicitador from './SubComponentes/Solicitador';

interface DatosJson {
    id: string | null;
    documentos: Array<File>;
    solicitador: string;
    notariaSeleccionada: string;
    catalogoNotarias: Array<any>;
    numeroEscritura: string;
    general: DatosFolio,
    titulares: boolean,
    folioDocumento?: File | null;
    folioDocumentoNombre?: string;
    view: boolean;
}

interface DatosFolio {
    folio: string;
    claveCatastral: string;
    antecedentes: string;
    domicilioRegistral: string;
    calle: string;
    numeroExterior: string;
    numeroInterior: string;
    colonia: string;
    municipio: string;
    estado: string;
    localidad: string;
    codigoPostal: string;
    indiviso: string;
    usoSuelo: string;
    superificieTotal: string;
    restante: string;
    construidas: string;
    medidasColindancias: string;
}

interface DatosProps {
    data: DatosJson;
    docsIDs: Array<any>;
    refreshInformation: () => void;
    setSolicitador: (value: string) => void;
    seleccionarTitular: () => void;
    setNotariaSeleccionada: (value: string) => void;
    setNumeroEscritura: (value: string) => void;
    setFolioDocumento?: (file: File) => void;
    setFolioDocumentoNombre?: (value: string) => void;
    uploadDocumento?: () => void;
    deleteDocumento?: (documento: any) => void;
}

const DatosGenerales = (props: DatosProps) => {
    const toast = useRef(null);
    return (
        <section className=''>
            <Divider />

            <Toast ref={toast} />

            <Datos data={props.data.general} />

            <Solicitador
                data={{
                    view: props.data.view,
                    solicitador: props.data.solicitador,
                    notariaSeleccionada: props.data.notariaSeleccionada,
                    catalogoNotarias: props.data.catalogoNotarias,
                    numeroEscritura: props.data.numeroEscritura,
                    titulares: props.data.titulares
                }}
                setSolicitador={props.setSolicitador}
                setNotariaSeleccionada={props.setNotariaSeleccionada}
                setNumeroEscritura={props.setNumeroEscritura}
                seleccionarTitular={props.seleccionarTitular}
            />

            {props.data.id &&
                <Documentos
                    data={{
                        view: props.data.view,
                        folioDocumento: props.data.folioDocumento,
                        folioDocumentoNombre: props.data.folioDocumentoNombre,
                        documentos: props.data.documentos
                    }}
                    docsIDs={props.docsIDs}
                    setFolioDocumento={props.setFolioDocumento}
                    setFolioDocumentoNombre={props.setFolioDocumentoNombre}
                    uploadDocumento={props.uploadDocumento}
                    deleteDocumento={props.deleteDocumento}
                />
            }
        </section>
    )
};

export default memo(DatosGenerales);
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EncryptService from '../../../service/encrypt.service';
import ApiServices from '../../../service/apiServices';
import { Toast } from 'primereact/toast';
import authGuardService from '../../../service/authGuardService';
import Tabs from '../../../Components/Tabs/Tabs';
import Tab from '../../../Components/Tabs/Tab';

const VenBuscador = () => {
    const location = useLocation();

    const navigate = useNavigate();
    const toast = useRef<any>(null);

    const user = authGuardService.getUser()

    const onlyView = location.state && location.state.onlyView ? true : false
    const propState = location.state && location.state.folio ? true : false

    useEffect(() => {
        if (location && location.state && location.state.folio) {
            setValues('folioReal', location.state.folio)
        }
    }, [location])

    const [data, setData]: any = useState({
        folioReal: { value: '', valid: true },
        claveCatastral: { value: '', valid: true },
        nombre: { value: '', valid: true },
        apellidoP: { value: '', valid: true },
        apellidoM: { value: '', valid: true },
        domicilio: { value: '', valid: true },
        zona: { value: '', valid: true },
        calle: { value: '', valid: true },
        estado: { value: '', valid: true },
        municipio: { value: '', valid: true },
        cp: { value: '', valid: true },
        suelo: { value: '', valid: true },
        antecedentesRegistrales: { value: '', valid: true },
    })

    const [buttonDisabled, setButtonDisabled] = useState(true)

    const setValues = (jsonValue: any, value: any) => {
        const cloneJson = JSON.parse(JSON.stringify(data))
        cloneJson[jsonValue].value = value
        setData(cloneJson)

        setButtonDisabled(false)
    }

    const [notFound, setNotFound] = useState(false)

    const valueExist = () => {
        return (
            (data.folioReal.value && data.folioReal.value.length > 0) ||
            (data.claveCatastral.value && data.claveCatastral.value.length > 0) ||
            (data.nombre.value && data.nombre.value.length > 0) ||
            (data.apellidoP.value && data.apellidoP.value.length > 0) ||
            (data.apellidoM.value && data.apellidoM.value.length > 0) ||
            (data.domicilio.value && data.domicilio.value.length > 0) ||
            (data.zona.value && data.zona.value.length > 0) ||
            (data.calle.value && data.calle.value.length > 0) ||
            (data.estado.value && data.estado.value.length > 0) ||
            (data.municipio.value && data.municipio.value.length > 0) ||
            (data.cp.value && data.cp.value.length > 0) ||
            (data.suelo.value && data.suelo.value.length > 0) ||
            (data.antecedentesRegistrales.value && data.antecedentesRegistrales.value.length > 0) ||
            false
        )
    }

    const searchFolio = () => {
        if (valueExist()) {
            let datos
            datos = {
                code: 208,
                prop_clave: data.claveCatastral.value,
                prop_folio: data.folioReal.value,
                ptit_nom: data.nombre.value,
                ptit_pat: data.apellidoP.value,
                ptit_mat: data.apellidoM.value,
                prop_direccion: data.domicilio.value,
                prop_calle: data.calle.value,
                prop_ext: '',
                prop_int: '',
                prop_zona: data.zona.value,
                prop_usosuelo: data.suelo.value,
                cp_cp: data.cp.value,
                cp_municipio: data.municipio.value,
                prop_antecedente: data.antecedentesRegistrales.value,
                cp_estado: data.estado.value,
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    let url: any
                    let exist = true
                    const navigateOptions = {
                        state: {
                            pagos: (location.state && location.state.pagos) ? location.state.pagos : [],
                            folios: res.data.addenda,
                            preLoadActos: (location.state && location.state.actos) ? location.state.actos : [],
                            onlyView
                        }
                    };
                    if (res.data.addenda && res.data.addenda.length > 1) {
                        url = '/ventanilla/dup-folio'
                    } else if (onlyView) {
                        url = '/ventanilla/view-folio'
                    } else if (res.data.addenda && res.data.addenda.length === 1) {
                        url = '/ventanilla/vt-tramite'
                    }

                    if (exist)
                        navigate(url, navigateOptions)
                    else
                        setNotFound(true)
                } else {
                    setNotFound(true)
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            setButtonDisabled(true)
        }
    }

    const searchFolioNull = () => {
        let datos
        datos = {
            code: 208,
            prop_clave: data.claveCatastral.value,
            prop_folio: 0,
            ptit_nom: data.nombre.value,
            ptit_pat: data.apellidoP.value,
            ptit_mat: data.apellidoM.value,
            prop_direccion: data.domicilio.value,
            prop_calle: data.calle.value,
            prop_ext: '',
            prop_int: '',
            prop_zona: data.zona.value,
            prop_usosuelo: data.suelo.value,
            cp_cp: data.cp.value,
            cp_municipio: data.municipio.value,
            prop_antecedente: data.antecedentesRegistrales.value,
            cp_estado: data.estado.value,
        }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            let url: any
            let exist = true
            const navigateOptions = {
                state: {
                    pagos: (location.state && location.state.pagos) ? location.state.pagos : [],
                    folios: res.data.addenda,
                    preLoadActos: (location.state && location.state.actos) ? location.state.actos : []
                }
            };
            url = '/ventanilla/vt-tramite'

            navigate(url, navigateOptions)

        }).catch(error => {
            console.log(error)
        })
    }

    const [buttonDisabledMorales, setButtonDisabledMorales] = useState(true)

    const [dataMorales, setDataMorales]: any = useState({
        rfc: { value: '', valid: true },
        fepm: { value: '', valid: true },
        denominacionSocial: { value: '', valid: true },
        direccion: { value: '', valid: true },
        antecedentes: { value: '', valid: true }
    })

    const setValuesMorales = (jsonValue: any, value: any) => {
        const cloneJson = JSON.parse(JSON.stringify(dataMorales))
        cloneJson[jsonValue].value = value
        setDataMorales(cloneJson)

        setButtonDisabledMorales(false)
    }

    const valueExistMorales = () => {
        return (
            (dataMorales.rfc.value && dataMorales.rfc.value.length > 0) ||
            (dataMorales.fepm.value && dataMorales.fepm.value.length > 0) ||
            (dataMorales.denominacionSocial.value && dataMorales.denominacionSocial.value.length > 0) ||
            (dataMorales.direccion.value && dataMorales.direccion.value.length > 0) ||
            (dataMorales.antecedentes.value && dataMorales.antecedentes.value.length > 0) ||
            false
        )
    }

    const searchFolioMorales = () => {
        if (valueExistMorales()) {
            let datos
            datos = {
                code: 801,
                per_rfc: dataMorales.rfc.value,
                per_folio: dataMorales.fepm.value,
                per_razonsocial: dataMorales.denominacionSocial.value,
                per_direccion: dataMorales.direccion.value,
                per_antecedente: dataMorales.antecedentes.value
            }
            datos = EncryptService.encryptData(JSON.stringify(datos));
            ApiServices.processRequestAdd(datos).then(async res => {
                if (res.data.valido === 1) {
                    let url: any
                    let exist = true
                    const navigateOptions = {
                        state: {
                            pagos: (location.state && location.state.pagos) ? location.state.pagos : [],
                            folios: res.data.addenda,
                            preLoadActos: (location.state && location.state.actos) ? location.state.actos : [],
                            onlyView
                        }
                    };
                    if (res.data.addenda && res.data.addenda.length > 1) {
                        url = '/ventanilla/dup-folio-morales'
                    } else if (onlyView) {
                        url = '/ventanilla/view-folio-morales'
                    } else if (res.data.addenda && res.data.addenda.length === 1) {
                        url = '/ventanilla/vt-tramite-morales'
                    }
                    if (exist)
                        navigate(url, navigateOptions)
                    else
                        setNotFound(true)
                } else {
                    setNotFound(true)
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            setButtonDisabledMorales(true)
        }
    }

    return (
        <section className='container-fluid'>
            <Toast ref={toast} />

            <div className='col-12 md:col-12 pb-5'>
                <h3 className='color-violet'>Buscar folio</h3>
                <p>Ingresa los datos en los campos necesarios para realizar la búsqueda</p>
            </div>

            {notFound &&
                <div className='col-12 md:col-12 pb-5'>
                    <div className="grid grid-nogutter" style={{ background: '#F4CBB4', borderRadius: 10 }}>
                        <div className="col">
                        </div>
                        <div className="col-10" style={{ display: 'flex' }}>
                            <div style={{ width: '1%', height: '100%', background: '#D34C00', borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }} />
                            <div style={{ width: '85%', height: '8rem', marginLeft: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <span style={{ color: '#D34C00', fontSize: 24, fontFamily: 'Cabin', fontWeight: '700', wordWrap: 'break-word' }}>
                                    Datos no encontrados
                                </span>
                                <span style={{ color: '#5B5B5F', fontSize: 16, fontFamily: 'Cabin', fontWeight: '400', wordWrap: 'break-word' }}>
                                    No se encontraron coincidencias en el sistema. rectifica si existe en otro lugar dentro del instituto y genera un nuevo folio para darlo de alta en el sistema.
                                </span>
                            </div>
                        </div>
                        <div className="col-2">
                            <div style={{ width: '90%', height: '8rem', justifyContent: 'flex-end', alignItems: 'center', display: 'flex', marginRight: '1rem' }}>
                                {(user && user.usu_nuevofolio) ?
                                    <Button label="Generar Nuevo Folio" style={{
                                        padding: 16, color: 'white',
                                        fontSize: 15,
                                        fontFamily: 'Cabin',
                                        fontWeight: '400',
                                        wordWrap: 'break-word',
                                    }} onClick={() => {
                                        navigate('/ventanilla/new-folio', {
                                            state: {
                                                folio: data.folioReal.value
                                            }
                                        })
                                    }} />
                                    : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Tabs>
                <Tab label="Folios">
                    <div className='flex justify-content-center align-items-center'>
                        <div className='grid'>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        value={data.folioReal.value}
                                        onChange={(e) => setValues('folioReal', e.target.value)}
                                        id="folioReal"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="folioReal">Folio real</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        value={data.claveCatastral.value}
                                        onChange={(e) => setValues('claveCatastral', e.target.value)}
                                        id="claveCatastral"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="claveCatastral">Clave Catastral</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        value={data.nombre.value}
                                        onChange={(e) => setValues('nombre', e.target.value)}
                                        id="nombre"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="nombre">Nombre</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        value={data.apellidoP.value}
                                        onChange={(e) => setValues('apellidoP', e.target.value)}
                                        id="apellidoP"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="apellidoP">Primer Apellido</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        value={data.apellidoM.value}
                                        onChange={(e) => setValues('apellidoM', e.target.value)}
                                        id="apellidoM"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="apellidoM">Segundo Apellido</label>
                                </span>
                            </div>

                            <div className='col-12 md:col-12 pb-3 pt-6'>
                                <h5>Buscar por ubicación</h5>
                            </div>

                            <div className='col-12 md:col-12'>
                                <span className="p-float-label">
                                    <InputText
                                        value={data.domicilio.value}
                                        onChange={(e) => setValues('domicilio', e.target.value)}
                                        id="domicilio"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="domicilio">Domicilio</label>
                                </span>
                            </div>

                            <div className='col-12 md:col-3'>
                                <span className="p-float-label">
                                    <InputText
                                        value={data.zona.value}
                                        onChange={(e) => setValues('zona', e.target.value)}
                                        id="zona"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="zona">Zona</label>
                                </span>
                            </div>

                            <div className='col-12 md:col-3'>
                                <span className="p-float-label">
                                    <InputText
                                        value={data.calle.value}
                                        onChange={(e) => setValues('calle', e.target.value)}
                                        id="calle"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="calle">Calle</label>
                                </span>
                            </div>

                            <div className='col-12 md:col-3'>
                                <span className="p-float-label">
                                    <InputText
                                        value={data.estado.value}
                                        onChange={(e) => setValues('estado', e.target.value)}
                                        id="municipio"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="municipio">Estado</label>
                                </span>
                            </div>

                            <div className='col-12 md:col-3'>
                                <span className="p-float-label">
                                    <InputText
                                        value={data.municipio.value}
                                        onChange={(e) => setValues('municipio', e.target.value)}
                                        id="municipio"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="municipio">Municipio</label>
                                </span>
                            </div>

                            <div className='col-12 md:col-3'>
                                <span className="p-float-label">
                                    <InputText
                                        value={data.cp.value}
                                        onChange={(e) => setValues('cp', e.target.value)}
                                        id="cp"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="cp">CP</label>
                                </span>
                            </div>

                            <div className='col-12 md:col-3'>
                                <span className="p-float-label">
                                    <InputText
                                        value={data.suelo.value}
                                        onChange={(e) => setValues('suelo', e.target.value)}
                                        id="suelo"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="suelo">Uso de Suelo</label>
                                </span>
                            </div>

                            <div className='col-12 md:col-3'>
                                <span className="p-float-label">
                                    <InputText
                                        value={data.antecedentesRegistrales.value}
                                        onChange={(e) => setValues('antecedentesRegistrales', e.target.value)}
                                        id="suelo"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="suelo">Antecedentes Registrales</label>
                                </span>
                            </div>

                            <div className='col-12 md:col-3'>
                            </div>

                            <div className='col-12 md:col-3'>
                            </div>

                            <div className='col-12 md:col-5'>
                            </div>

                            <div className='col-12 md:col-2 pt-8'>
                                <Button
                                    label="Buscar Sin Propiedad"
                                    onClick={() => {
                                        searchFolioNull()
                                    }}
                                />
                            </div>

                            <div className='col-12 md:col-2 pt-8'>
                                <Button
                                    label="Buscar"
                                    disabled={buttonDisabled}
                                    onClick={() => {
                                        searchFolio()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab label="Personas Morales">
                    <div className='flex justify-content-center align-items-center'>
                        <div className='grid'>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        value={dataMorales.rfc.value}
                                        onChange={(e) => setValuesMorales('rfc', e.target.value)}
                                        id="rfc"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="rfc">RFC</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        value={dataMorales.fepm.value}
                                        onChange={(e) => setValuesMorales('fepm', e.target.value)}
                                        id="fepm"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="fepm">Folio Electronico Personas Morales</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        value={dataMorales.denominacionSocial.value}
                                        onChange={(e) => setValuesMorales('denominacionSocial', e.target.value)}
                                        id="denominacionSocial"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="denominacionSocial">Denominacion Social</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        value={dataMorales.direccion.value}
                                        onChange={(e) => setValuesMorales('direccion', e.target.value)}
                                        id="direccion"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="direccion">Direccion</label>
                                </span>
                            </div>
                            <div className='col-12 md:col-4'>
                                <span className="p-float-label">
                                    <InputText
                                        value={dataMorales.antecedentes.value}
                                        onChange={(e) => setValuesMorales('antecedentes', e.target.value)}
                                        id="antecedentes"
                                        className="p-inputtext-lg"
                                    />
                                    <label htmlFor="antecedentes">Antecedentes</label>
                                </span>
                            </div>

                            <div className='col-12 md:col-2 pt-8'>
                                <Button
                                    label="Buscar"
                                    disabled={buttonDisabledMorales}
                                    onClick={() => {
                                        searchFolioMorales()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Tab>
            </Tabs>


        </section >

    );

}

export default React.memo(VenBuscador);